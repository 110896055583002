import { Box, Typography } from '@material-ui/core'
import React from 'react'
import './CSS/ReferFriend.css'


const ReferalStep = (props) => {
    const {num,heading,detail} = props
  return (
    <Box className='referal-step-contianer'>
        <Box className='step-heading'>
            <Box className='num-container'>
                <Typography style={{fontWeight:'bold'}} variant='p'>{num}</Typography>
            </Box>
            <Typography style={{fontSize:'1rem',fontWeight:'bold'}} variant='p'>
                {heading}
            </Typography>
        </Box>
        <Box className='step-detail'>
            <Typography  variant='p'>
                {detail}
            </Typography>
        </Box>
        


    </Box>
  )
}

export default ReferalStep