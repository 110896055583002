import React, { useState, useLayoutEffect } from "react"
import { Container, makeStyles } from "@material-ui/core"
import { Filter } from "./Filter"
import { SMFilter } from "./SMFilter"
import { filterInitialValues } from "./script"

export const SearchFilters = ({setSearchFilters, searchFilters, appliedFilters, clearFilterData, removeFilter, filteredGenres}) => {
  const [size, setSize] = useState([0, 0])
  const { filterBox } = useStyles()
  const [updateFilters, setUpdateFilters] = useState("")
  const [filters, setFilters] = useState(filterInitialValues)
  const [filteredTracks, setFilteredTracks] = useState([])
  const [preFilteredTracks, setPreFilteredTrack] = useState([])


  const refreshFilters = () => {
    setFilteredTracks(preFilteredTracks)
    setFilters({
      isPhrases: "",
      isOneshot: "",
      isWet: "",
      isDry: "",
      genre: "",
      key: "",
      bpm: "",
      language: "",
      trending: "",
      searchKey: "",
      isVocalchops: "",
      isStutter: ""
    })
  }

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return (
      <Container>
        <div className={filterBox} pt={2} width="10%">
          {size[0] > 1300 ? (
            <Filter
              filters={searchFilters}
              setFilters={setSearchFilters}
              refreshFilters={refreshFilters}
              updateFilters={updateFilters}
              setUpdateFilters={setUpdateFilters}
              appliedFilters={appliedFilters}
              clearFilterData={clearFilterData}
              removeFilter={removeFilter}
              filteredGenres={filteredGenres}
            />
          ) : (
            <SMFilter
              filters={searchFilters}
              setFilters={setSearchFilters}
              refreshFilters={refreshFilters}
              updateFilters={updateFilters}
              setUpdateFilters={setUpdateFilters}
              appliedFilters={appliedFilters}
              clearFilterData={clearFilterData}
              removeFilter={removeFilter}
              filteredGenres={filteredGenres}
            />
          )}
        </div>
      </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  vocalWrapper: {
    margin: "0 50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width:1000px)": {
      margin: "0 0px"
    }
  },

  filterBox: {
    width: "100%",

    "@media screen and (max-width: 1300px)": {
      marginTop: "-80px"
    },

    position: "relative",
    marginBottom: 60
  },
  overflow: {
    width: "inherit",
    overflow: "hidden",
    paddingRight: 5

  },
  rootBox: {
    padding: "0px 32px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px  0px 0px"
    },
    "@media (min-width: 1280px)": {
      maxWidth: "auto"
    }
  }
}))
