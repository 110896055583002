import { makeStyles, Typography } from "@material-ui/core"
import * as React from "react"

export const Table = ({ tracks }) => {
  const {table_head, tabelRoot, textWhite, nothingTextWrap, trackNameText } = useStyles()

  return tracks.length > 0 ? (
    <table className={tabelRoot}>
      <thead>
        <tr >
          <th align="left"><Typography style={{fontWeight:'bold'}} variant="p">Name</Typography></th>
          <th align="left"><Typography className={table_head} variant="p">Plays</Typography></th>
          <th align="left"><Typography className={table_head} variant="p">Likes</Typography></th>
          <th align="left"><Typography className={table_head} variant="p">Downloads</Typography></th>
          <th align="left"><Typography className={table_head} variant="p">Earnings</Typography></th>
        </tr>
      </thead>
      <tbody>
        {tracks.map((row, index) => (
          <tr className={textWhite} key={index}>
            <td>
              <Typography className={[textWhite, trackNameText].join(" ")}>
                {row.track_name}
              </Typography>
            </td>
            <td style={{textAlign:'center'}}>
              <Typography className={textWhite}>{row.plays}</Typography>
            </td>
            <td style={{textAlign:'center'}}>
              <Typography className={textWhite}>{row.likes}</Typography>
            </td>
            <td style={{textAlign:'center'}}>
              <Typography className={textWhite}>{row.downloads}</Typography>
            </td>
            <td style={{textAlign:'center'}}>
              <Typography className={textWhite}>{row.earnings}$</Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <span className={nothingTextWrap}>You dont have any Vocals</span>
  )
}

const useStyles = makeStyles(() => ({
  table_head:{
    padding:'10px',
    fontWeight:'bold',
    // fontSize:'1rem',
    // paddingLeft:'1rem'
  },
  textWhite: {
    color: "#ffffff !important"
  },
  trackNameText: {
    width: "400px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  nothingTextWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginTop: "50px"
  },
  tabelRoot: {
    width: "100%",
    paddingTop: 20,
    color: "#fff",
    "& thead tr th": {
      fontSize: 14,
      fontWeight: 400
    },
    "& tbody tr td": {
      color: "#fff",
      paddingTop: "10px",
      paddingBottom: "10px"
    }
  }
}))
