import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function MessageForm() {
  const {field} = styling


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    
    data.set('message','hello')
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" style={
        {
          width:'30rem',
          marginRight:0,
          margin:0,
        }
      }>
        {/* <CssBaseline /> */}
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          
          <Typography component="h1" variant="h3" style={{fontWeight:800,color:'#57A4FF'}}>
            Send a message
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={0.7}>
              <Grid item xs={12} sm={6}
                
              >
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="firstName"
                  // label="First Name"
                  // autoFocus
                  style={field}
                  placeholder='Name or Artist name'
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{}}>
                <TextField
                  required
                  fullWidth
                  id="Subject"
                  placeholder="Subject"
                  name="subject"
                  autoComplete="family-name"
                  style={field}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  autoComplete="email"
                  style={field}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="message"
                  placeholder="message"
                  // type="password"
                  multiline
                  rows={4}
                  id="password"
                  autoComplete="new-password"
                  style={field}
                />
              </Grid>
              
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              style={{
                maxWidth:'10rem',
                backgroundColor: '#57A4FF',
                cursor:'pointer'
              }}
            >
              Send
            </Button>
            
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}


const styling = {
  field:{
    backgroundColor:"#fff",
    borderRadius:'5px'
  }
}