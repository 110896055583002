import * as React from "react"
import { Header } from "../components/Header"
import { Box, makeStyles } from "@material-ui/core"

export const NonAuthLayout = ({ children }) => {
  const { header } = useStyles()
  return (
    <>
      <Box className={header}>
        <Header open={true} />
      </Box>
      {children}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main
  }
}))

export default NonAuthLayout
