import React from "react"
import "../../../_warbls/components/subscription/CSS/SelectPlan.css"
import Card from "../../../_warbls/components/subscription/Card"
import { useEffect, useState, useCallback } from "react"
import { useDispatch } from "react-redux"
import { actions } from "../../../redux/subscription/actions"
import axios from "axios"
import { useHistory } from "react-router-dom"
import { APIs } from "../../../_helpers/apis"
import { useSelector } from "react-redux"
import { ContentContainer } from "../../../_warbls/components/Container"

import { CircularProgress } from "@material-ui/core"

let monthly_info = {}
let yearly_info = {}

const Plans = (props) => {
  //insert your fatching data code here
  const [fatchData, setFatchData] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [selectedCard, setSelectedCard] = useState(null)
  const { auth,userInfo } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  const [card_info, setcard_info] = useState({})
  const [cancelUserSubscription, setCancelUserSubscription] = useState({})

  const [info, setinfo] = useState(true)

  function filterData(res, propertyName, value) {
    return res?.results?.filter((obj) => obj[propertyName] === value)
  }
  const getCancelUserSubscription = useCallback(async () => {
    

    try {
      const res = await axios.get(APIs.userSubscriptionPlanFilters,{
        params:{user_id: userInfo.user_id,filters: JSON.stringify({status: 'CANCEL'})}
      });
      setCancelUserSubscription(res.data)
    } catch (error) {
      // setError(error)
    } finally {
      // setLoading(false)
    }
    // };
    // await fetchData()
  }, [])
 
  const fetch = useCallback(async () => {
    if (card_info && card_info.length > 0) {
      return
    }

    try {
      const res = await axios.get(APIs.plans)

      monthly_info = filterData(res.data, "duration", "720:00:00")
      yearly_info = filterData(res.data, "duration", "8760:00:00")
      setcard_info(monthly_info)

    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
    // };
    // await fetchData()
  }, [loading, card_info])
  useEffect(() => {
    fetch();
    getCancelUserSubscription();
  }, [fetch])

  

  const monthly_button = () => {
    setinfo(true)
    setcard_info(monthly_info)
  }
  const yearly_button = () => {
    setinfo(false)
    setcard_info(yearly_info)
  }

  const next = () => {
    if (selectedCard != null) {
      dispatch(actions.userSubscriptionPlan(selectedCard))
      history.push("/checkout")
    }
  }

  if (loading) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress size={40} color="inherit" />
      </div>
    )
  }

  if (error) {
    return <p>Error: {error.message}</p>
  }
  const cardClick = (v) => {
    setSelectedCard(v)
  }

  return (
    <>
      <div className="sp-info-sideinfo">
        <div
          className="sp-info-duration"
          style={{
            backgroundColor: info ? "#57A4FF" : "#212121",
            color: info ? "#000" : "#fff",
            fontWeight: "bolder",
            padding: "5px 10px"
          }}
          onClick={monthly_button}
        >
          Monthly
        </div>
        <div
          className="sp-info-duration"
          style={{
            backgroundColor: !info ? "#57A4FF" : "#212121",
            color: !info ? "#000" : "#fff",
            fontWeight: "bolder",
            padding: "5px 10px"
          }}
          onClick={yearly_button}
        >
          Yearly
        </div>
      </div>

      {/* mounthly button */}
      <div className="sp-card">
        {card_info &&
          card_info?.map((c, index) => {
            return (
              <Card
                yearclick={yearly_button}
                monthclick={monthly_button}
                key={index}
                id={c.subscription_plan_id}
                plan={c.name}
                coin={c.coins}
                price={c.price}
                duration={info}
                price_duration="month"
                active={props.activePlanId === c.subscription_plan_id}
                cancelUserSubscription = {cancelUserSubscription}
              />
            )
          })}
      </div>
    </>

    // </div>
  )
}

export default Plans
