import { Box, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import './CSS/menuTap.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const MenuTabs = (props) => {

    const {icon,heading,ID,logoutHandler,path,activeTabID} = props
    const history = useHistory()

    // const clickHandler = (e) => { 
    //     console.log(e.target.id);
    //     setTab(e.target.id);
    //    const activeElement = document.getElementById(e.target.id);
    //    const nonactiveElement = document.querySelectorAll('.active-tab');

    //    nonactiveElement.forEach(elem => {
    //         elem.classList.remove('active-tab');
    //    })
    //    activeElement.classList.add('active-tab')
    //  }

    
    

  return (
    <div id={ID} className='tab-container' 
    onClick={heading === 'Logout'?(() => { logoutHandler() }):(() => { 
        history.push(path)
        // clickHandler(e)
    })}
    
    >   
    <Box style={{
        display:"flex",
        gap:'10px'

    }}   
    >

            <Box style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center'
            }}>
                <img src={icon} alt="icon" />
            </Box>
            <Typography variant='p' style={{
                fontSize:'1.05rem',
                color: heading === 'Logout'&& '#FF5757'
            }}>
                {heading}
            </Typography>
        </Box>
            <Box style={{
            
            }}>
                {
                    heading !== 'Logout'?(<img src="media/rightArrow.png" alt=">" />):(
                        <Box style={{width:'5px',height:'5px'}}></Box>
                    )
                }
                
            </Box>

    </div>
  )
}

export default MenuTabs