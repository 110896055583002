import React from 'react'
import { Typography } from '@material-ui/core';
import styles from "./Css/dialogue.module.css"

const MoreCoinRow = (props) => {

    const {coins,price,setSelectRow,selectRow,isHover} = props;
  return (
    <div onClick={() => { setSelectRow({coin:coins,price:price}) }} 
        className={isHover ? styles.dialogueRow:styles.dialogueRowBackground}
        style={{textAlign:'center',backgroundColor:selectRow.coin === coins && '#57A4FF',borderRadius:'5px',padding:'5px 0',cursor:'pointer'}}>
        <Typography style={{fontWeight:'bolder'}} variant='h6'>
            {coins} Coins - $ {price}
        </Typography>
        
    </div>
  )
}

export default MoreCoinRow