import * as Yup from "yup"
import valid from "card-validator"
export const formScript = [
  {
    name: "email",
    type: "email",
    placeholder: "Email",
    label: "Email",
    xs: 12,
    md: 12,
    style: {
      borderRadius: 4,
      textAlign: 'right',
      backgroundColor:'#DBDADA',
      color:'#393939',
    }
  },
  {
    name: "name",
    type: "text",
    placeholder: "Full name",
    label: "",
    xs: 12,
    md: 12,
    style: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      marginTop: 20
    }
  },
  {
    name: "country",
    type: "text",
    placeholder: "Country",
    label: "",
    xs: 12,
    md: 6,
    style: {
      borderBottomLeftRadius: 4
    }
  },
  {
    name: "postalCode",
    type: "text",
    placeholder: "ZIP",
    label: "",
    xs: 12,
    md: 6,
    style: {
      borderBottomRightRadius: 4
    }
  },
  {
    name: "creditCard",
    type: "text",
    placeholder: "1234 1234 1234 1234",
    label: "Card information",
    xs: 12,
    md: 12,
    inputImage: true,
    style: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    }
  },
  // {
  //   name: "month",
  //   type: "text",
  //   placeholder: "MM",
  //   label: "Expiration",
  //   xs: 12,
  //   md: 4
  // },
  {
    name: "year",
    type: "text",
    placeholder: "MM / YY",
    label: "",
    xs: 12,
    md: 6,
    style: {
      borderBottomLeftRadius: 4
    }
  },
  {

    name: "cvc",
    type: "text",
    placeholder: "CVC",
    label: "",
    xs: 12,
    md: 6,
    inputCvcImage: true,
    style: {
      borderBottomRightRadius: 4
    }
  },
  {
    name: "last4",
    type: "text",
    label: "Card Number",
    xs: 12,
    md: 12
  },
  {
    name: "cardName",
    type: "text",
    label: "Name on Card",
    xs: 12,
    md: 12
  }
]

export const initValues = {
  firstName: "",
  lastName: "",
  creditCard: "",
  month: "",
  year: "",
  cvc: "",
  country: "",
  postalCode: ""
}
export const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  creditCard: Yup.string()
    .required("Card number is required")
    .test(
      "test-number", // this is used internally by yup
      "Card number is invalid", //validation message
      (value) => valid.number(value).isValid
    ),
  year: Yup.string().required("Expiration date is required").matches(/^(0[1-9]|1[0-2])[ ]?\/[ ]?([0-9]{2})$/, "Wrong format for expiration date. Possible formats are MM / YY or MM/YY"),
  cvc: Yup.string().required("CVC/CVV is required")
    .test(
      "test-number", // this is used internally by yup
      "Invalid input! CVC/CVV number contains 3 digits only.", //validation message
      (value) => valid.cvv(value).isValid
    ),
  country: Yup.string().required("Country is required"),
  postalCode: Yup.string().required("ZIP is required")
})
export const conformationList = [
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: true
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: true
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: true
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: true
  }
]

export const checkoutList = [
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: ""
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: ""
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: ""
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    download: ""
  }
]
