import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from "react"
import WaveSurfer from "wavesurfer.js"
import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core"
import { useSelector } from "react-redux"
import { SongContext } from "../SideBarInfo"

const Waveform =forwardRef((props,ref) => {

  const { id, setDuration, generateDuration, current, song, container, setContainer, setCurrent,listTrack,setLoad , setWaveform,size} = props;

  const { waveContainer, wave } = useStyles()
  const { volume } = useSelector((state) => state.track)
  const waveform = useRef(null)

  const [loader, setLoader] = useState(false)
  const [currentProgress, setCurrentProgress] = useState(0)

  const mysongcontext=useContext(SongContext);

    
  
  
  useEffect(() => {
    setLoader(true)
    const track = document.querySelector(`#track${id}`)
    if (!waveform.current) {
      waveform.current = WaveSurfer.create({
        barWidth: 3,
        cursorWidth: 1,
        container: `#waveform${id}`,
        backend: "WebAudio",
        height: 100,
        waveColor: "#9D9D9D",
        progressColor: "#86DB78",
        responsive: true,
        cursorColor: "transparent"
      })
    }

    waveform.current.load(track)
    waveform.current.on("ready", function () {
      setLoader(false)
      const fixed = waveform.current.getDuration().toFixed(0)
      const val = fixed.split(".")[0]
      const mins = val > 60 ? (val / 60).toFixed(0) : 0
      const scnd = val > 60 ? val % 60 : val
      const duration = `0${mins}:${scnd.toString().length === 2 ? scnd : "0" + scnd}`
      setDuration(duration)
    })
  }, [id, setDuration])

  const handleMouseEnter = () => {
    setCurrentProgress(waveform.current.getCurrentTime())
  }

  useEffect(handleMouseEnter, [currentProgress])

  useEffect(() => {
    if (id === current) {
      waveform.current.setVolume(volume)
    }
  }, [current, id, volume])

  useEffect(() => {
    
    setLoad(loader)
    
  
    
  }, [loader])
  
   const handlePlay=()=> {
      if (container) {
        container.current.stop()
      }
      waveform.current.play()
      setContainer(waveform)
      setCurrent(id)
      mysongcontext.setListTrack(listTrack)
      mysongcontext.setCurrentSong(waveform.current)
      mysongcontext.setContainerSong(container.current)
      mysongcontext.setPlaying(true)
      mysongcontext.setCurrentId(id)
  
    }

   const handleStop=()=> {
      
      setCurrent()
      waveform?.current?.stop()
      // console.log(waveform.current);
      mysongcontext.setPlaying(false) 
    }

 
    useImperativeHandle(ref, () => ({
  
      getAlert() {
        // alert("getAlert from Child");
        handlePlay();
      }
  
    }));

    useEffect(() => {
      if(id == mysongcontext.currentId){
        handlePlay();
      }
    
      
    },[mysongcontext.currentId])
    



  return (
    <div className={waveContainer}>
      
      {loader ? (
        <CircularProgress size={20} color="inherit" style={{ visibility: (size > 1280) && 'hidden'}}/>
        ) : (

        size < 1280 && (         
          <>
          {id === current && mysongcontext.playing ? (
            <div onClick={handleStop}>
              <img
                src="media/stopSong.png"
                //   color="#86DB78"
                className="pointer"
                alt="stop img"
                />
            </div>
          ) : (
            <div onClick={handlePlay}>
              <img
                src="media/playSong.png"
                //   color="#86DB78"
                className="pointer"
                alt="play img"
                />
            </div>
          )}
        </>
          )
      )}

      {/* {typeof generateDuration == 'function' && <Tooltip title={generateDuration(currentProgress)} style={{ visibility: (size < 1280) && 'hidden'}}>
         <div id={`waveform${id}`} onMouseEnter={handleMouseEnter} className={`pointer ${wave}`} />
      </Tooltip>} */}

      <Tooltip title={generateDuration(currentProgress)} style={{ visibility: (size < 1280) && 'hidden'}}>
        <div id={`waveform${id}`} onMouseEnter={handleMouseEnter} className={`pointer ${wave}`} />
      </Tooltip>
      <audio id={`track${id}`} src={`${song}`} className="pointer" />
      
    </div>
  )
}
);

export default Waveform

const useStyles = makeStyles(() => ({
  waveContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 50,
    width: "90%",
    background: "transparent",
    overflow: "hidden",
    color: "#9D9D9D",

    "@media (max-width:1280px)": {
      margin: "-2px 0px 0 -10px !important",
      width: "100px"
    }
  },
  wave: {
    width: "90%",
    height: "70px",
    marginBottom: "30px",
    marginLeft: "20px"
  }
}))
