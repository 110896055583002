import { FaAngleDown } from "react-icons/fa"
import './CSS/style.css'
import { Box, Typography, Button, makeStyles } from "@material-ui/core"
import * as React from "react"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { Header } from "../../../_warbls/components/Header"
import { useDispatch } from "react-redux"
import CoverImg from "../../../_warbls/assets/images/cover_image-min.png"
import HomePageImage1 from "../../../_warbls/assets/images/homepage-image2.png"
import HomePageImage2 from "../../../_warbls/assets/images/homepage-image1.png"
import HomePageImage3 from "../../../_warbls/assets/images/homepage-image3.png"
import payments from "../../../_warbls/assets/images/payment.png"
import music from "../../../_warbls/assets/images/music.png"
import music1 from "../../../_warbls/assets/images/music1.png"
import music2 from "../../../_warbls/assets/images/music2.png"
import play from "../../../_warbls/assets/images/play.png"
import laptop from "../../../_warbls/assets/images/laptop.png"
import { AppContext } from "../../../_warbls/providers/AppProvider"
import clsx from "clsx"
import { Column } from "../../../_warbls/components/Flex/Column"
import { Row } from "../../../_warbls/components/Flex/Row"
import { ImFacebook, ImYoutube } from "react-icons/im"
import { FaInstagram, FaTiktok } from "react-icons/fa"
import { useHistory } from "react-router"
import { actions as authActions } from "../../../redux/auth/actions"
import { borderColor } from "@material-ui/system"
import Infocards from "./Infocards"

import CustomerSay from "./CustomerSay"
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';


import image1 from './CSS/image1.jpg';
import image2 from './CSS/image2.jpg';
import wave_image from './CSS/wave1.svg'
import { Popup } from "../../../_warbls/components/Popup"



const { useContext, useRef, useEffect } = React

export const Main = () => {
  const {
    footerWrapper,
    signupWrapperInner,
    wrapperPayment,
    wrapperInnerPayment,
    featureContainer,
    featureCard,
    uniqueContent,
    uniqueHeading,
    btn,
    header,
    mainImgBox,
    innerBox,
    typo,
    wrapper,
    wrapperSubscription,
    advantageWrapper,
    signupWrapper,
    bottomBox,
    learnMore,
    wrapperComposition,
    social_icons_wrapper,
    paymentImg,
    iconsCustomStyle
  } = useStyles()
  const anchorRef = useRef()
  const dispatch = useDispatch()
  const { handleSignup } = useContext(AppContext)
  const history = useHistory()

  useEffect(() => {
    dispatch(authActions.userInfoRequest())
  }, [dispatch])






  return (
    <Box className="root_container">
      <Box className={header}>
        <Header open={true} indexPage={true} />
      </Box>
      <Box className={mainImgBox} width="100%">
          <Box className="upperBox">
            {/* <Box className="left_image">
                <img src={`${process.env.PUBLIC_URL}/images/left_image.png`} />
            </Box> */}
          
            <Box className={`${innerBox} mid_container`}>
                  <Typography variant="h3" className={typo} id="heading">
                    Become the <span style={{color:'#57A4FF'}}>
                       music producer <br />
                      </span>
                    that get <span style={{color:"#FC57FF"}}>
                     millions of streams
                      </span> 
                  </Typography>
                  <Box>

                  <Typography variant="h6"  style={{color:'#fff'}} >
                    Real <span style={{fontWeight:'bolder'}}>Artist.</span> Real <span style={{fontWeight:'bolder'}}>Vocals.</span> <br />
                    For Real <span style={{fontWeight:'bolder'}} >producers.</span>
                  </Typography>
                  
                  
                  <Button className={btn} id="btn1" style={{ display:'flex',justifyContent:'space-between',alignItems:'center',paddingLeft:"15px",paddingRight:'15px',marginTop:'10px',background: "linear-gradient(90deg, #57A4FF 0%, #FC57FF 100%)",color:'#fff'
}} onClick={()=>{
                    history.push("/vocals")
                    
                  }}> <span>
                      Browse vocals
                  </span>
                       <ArrowForwardIosRoundedIcon style={{height:'1rem' , fontWeight:'bolder'}}/>
                  </Button>
                </Box>
                 

                  
                  {/* <Button className={btn} id="btn2" style={{marginTop:'10px',background: "linear-gradient(90deg, #57A4FF 0%, #FC57FF 100%)",color:'#fff'}} onClick={() => { 
                    history.push('/vocals')
                   }}>
                      <span>
                      Browse vocals 
                        </span> <ArrowForwardIosRoundedIcon  style={{height:'1rem' , fontWeight:'bolder'}}/>
                  </Button> */}
            </Box>

            <Box className="right_image">
                <img src={`${process.env.PUBLIC_URL}/images/right_image.png`} />
            </Box>

          </Box>
          {/* lower box */}
        <Box className={bottomBox}>
          <Box className="info_container">
            <Infocards
              heading="100% Royality-Free"
              containt="Vocals brought from Werbls are all cleared for commerical use"
              color='#57A4FF'
              />
            <Infocards
              heading="One-Time perchase"
              containt="When you buy vocals from us the vocals are yours forever,"
              color='#FC57FF'
              />
            <Infocards
              heading="Unique Vocals"
              containt="All Vocals exclusively sold on Werbls.com"
              color='#57FFC3'
            />
            

          </Box>
            
          {/* <Typography
            onClick={() => {
              window.scroll({
                top: (window.innerHeight * 80) / 100
              })
            }}
            variant="h6"
            className={learnMore}
          >
            Learn more
            <FaAngleDown />
          </Typography> */}
        </Box>
        
      </Box>


      <StyledContainer style={{ maxWidth: "unset",minHeight:'100vh' }} className={wrapper}>
          <Typography variant="h4" style={{ fontWeight: "600" }}>
            What our cutomers say
          </Typography>
          <Typography variant="p" className={uniqueContent} style={{ fontSize: "16px", textAlign:'center' }}>
          Discover the voices of our satisfied customers as they share <br/> their experiences and successes with our vocal samples.
          </Typography>
          <Box style={{
            display:'flex',
            flexDirection:'column',
            gap:'10px'
          }}>

          <CustomerSay
            image_url={image1}
            name='Blage'
            email='@blagemusic'
            outer_message="Using Warbls's vocals has been a game-changer for my music production. The quality and variety of the vocals have taken my tracks to a new level. I've saved hours of recording time, and the results speak for themselves."
            inner_message='The Future House song Blage made with the sample: Emailjray_Feel_your_touch'
            wave_img={wave_image}
            />
          <CustomerSay
            image_url={image2}
            name='Venko'
            email='@venko.music'
            outer_message='I really like that all the vocals are exclusively sold on Warbls. That makes all the songs I make with the vocals much more unique and special since I know its a very small chance someone uses the vocal the same way I do.'
            inner_message='Check out the hardstyle song Venko made with the sample: Mtian_Paradise'
            wave_img={wave_image}
            />

            </Box>
      </StyledContainer>


      <StyledContainer style={{ maxWidth: "unset",minHeight:'95vh' }} className={advantageWrapper}>
          <Typography variant="h4" style={{ fontWeight: "600",textAlign:'center' }}>
            Start Creating with Us Today
          </Typography>
          <Typography variant="p" className={uniqueContent} style={{ fontSize: "16px", textAlign:'center' }}>
            Ready to take your music to new heights? Your journey starts here. Join our community of passionate music creators and unlock a world of unparalleled vocals. Today is the day to turn your musical vision into reality. Begin your creative journey with us, and let your sound take flight.
          </Typography>
          <Button className={clsx(btn)} onClick={handleSignup}>
            Sign up for free
          </Button>

          <Typography variant="h4" style={{ fontWeight: "600" ,paddingTop:"2rem"}}>
            About Us
          </Typography>
          <Typography variant="p" className={uniqueContent} style={{ fontSize: "16px", textAlign:'center' }}>
            At Warbls, we're passionate about the art of music. Our journey began with a simple belief - that every musician, producer, and creator deserves the finest tools to express their unique sound. That's why we've dedicated ourselves to curating vocals of unparalleled quality and versatility.
          </Typography>
          <Typography variant="p" className={uniqueContent} style={{ fontSize: "16px", textAlign:'center' }}>
            We're not just another company; we're a community of music enthusiasts. Our mission is to empower your creativity, offering you a rich tapestry of vocals that transcend genres and spark limitless inspiration. With our commitment to excellence and unwavering dedication to your craft, we invite you to join us on a musical journey where the possibilities are endless. Welcome to Warbls, where your music finds its voice. 
          </Typography>

          <Typography variant="h5" style={{ fontWeight: "600" ,paddingTop:"2rem" }}>
            Do you have any question ?
          </Typography>

          <Typography variant="p" className={uniqueContent} id='faq' style={{ fontSize: "16px", textAlign:'center',width: "30%" }}>
            Check out our FAQ (Frequently Asked Auestions) page
             <br id="faq_brack"/> or contact us via social media & email - social@warbls.com
          </Typography>
        
      </StyledContainer>
     


      <StyledContainer style={{ maxWidth: "unset" }} className={footerWrapper}>
        <Typography>© {new Date().getFullYear()} Warbls.com All Rights Reserved</Typography>
        <p style={{ cursor: "pointer" }} onClick={() => history.push("/termsOfUse")}>
          Terms of use
        </p>
        <p>Privacy Policy</p>
        <p style={{ cursor: "pointer" }} onClick={(e) => history.push("/q&a")}>
          Q&A
        </p>
        <p style={{ cursor: "pointer" }} onClick={(e) => history.push("/contact-us")}>
          Contact
        </p>
        <div className={social_icons_wrapper}>
          <a href="https://www.instagram.com/warbls" target="_blank" rel="noreferrer" className={iconsCustomStyle} >
          <FaInstagram size={15} />
          </a>
          <a href="https://www.facebook.com/warbls" target="_blank"  rel="noreferrer" className={iconsCustomStyle}>
          <ImFacebook size={15} />
          </a>
          <a href="https://www.tiktok.com/@warbls" target="_blank" rel="noreferrer" className={iconsCustomStyle}>
          <ImYoutube size={15} />
          </a>
          <a href="https://www.tiktok.com/@warbls" target="_blank" rel="noreferrer" className={iconsCustomStyle}>
          <FaTiktok size={15} />
          </a>
        </div>
      </StyledContainer>
      <a ref={anchorRef} style={{ visibility: "hidden" }}></a>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  footerWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    display: "flex",
    padding: "28px 0",
    alignItems: "center",
    rowGap: "10px",
    columnGap: "15px",
    justifyContent: "center",
    height: "auto",
    "& > *": {
      color: theme.palette.primary.contrastText
    },
    "& a": {
      marginLeft: "15px",
      textDecoration: "none"
    },
    "@media (max-width:800px)": {
      flexDirection: "column"
    }
  },
  btn: {
    background: "#fff",
    color: '#000',
    borderRadius: '5px',
    minWidth: 200,
    border:"2px solid #000",
    // padding: "8px 20px 8px 20px",
    textTransform: "none",
    fontWeight: 600,
    fontSize: 18,
    marginTop: 30,
    "@media (max-width:1220px)": {
      fontWeight: 600,
      fontSize: 12,
      width:150,
      
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderColor:'#fff'
    }
  },
  header: {
    backgroundColor: theme.palette.primary.main
  },
  mainImgBox: {
    backgroundColor: "lightgray",
    height: "calc(100vh - 64px)",
    position: "relative",
    // background: `linear-gradient(102.13deg, #341c41eb 29.76%, #161d32ed 99.22%),
    background: `linear-gradient(102.13deg, #341c41f1 29.76%, #161d32ee 99.22%),
    url(${CoverImg})`,
    backgroundSize: "cover",
    overflow: "hidden"
  },
  innerBox: {

    // height:"90%",
    // width: "100%",
    // position: "absolute",
    // top: "67%",
    // left: "50%",
    // padding: "0rem 10rem",
    // transform: "translate(-50%, -50%)",
    // textAlign: "center"
    "@media (max-width:1200px)": {
      // height:'100%'
      
      justifyContent:'end',
      // paddingLeft:'12vw',
      alignItems:'start',
      // paddingRight:"15vw",
      paddingBottom:'19vh',

    },
    "@media (max-width:560px)": {
      // padding: "0 4rem"
    }
  },
  bottomBox: {
    width: "100%",
    height: "35%",
    backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)`,
    "@media (max-width:1200px)": {
      // height:"50%",
      display:'none'
    }
    // position: "absolute",
    // bottom: "0",
    // left: "0"
  },
  learnMore: {
    color: theme.palette.primary.contrastText,
    cursor: "pointer",
    position: "absolute",
    bottom: "10%",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width:550px)": {
      flexDirection: "row",
      columnGap: ".5rem",
      bottom: "2%"
    }
  },

  typo: {
    // fontFamily:'Eina01-Bold',
    fontWeight: 800,
    color: "black",
    "@media (max-width:780px)": {
      fontSize: "2rem"
    },
    "@media (max-width:580px)": {
      fontSize: "1.5rem"
    }
  },
  wrapper: {
    display:"flex",
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    padding: "20px 0px 80px 0px",
    backgroundImage: `linear-gradient(to right,   rgba(79, 142, 214, 0.627) ,  rgba(141, 82, 181, 0.721) );`
  },
  wrapperComposition: {
    height: "70vh",
    padding: "5rem 10rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width:1250px)": {
      height: "40vh"
    },
    "@media (max-width:800px)": {
      textAlign: "center",
      padding: "0 1rem"
    }
  },

  uniqueHeading: {
    lineHeight: "40px",
    width: "30%",
    fontWeight: 600,
    fontSize: "29px",
    "@media (max-width:1000px)": {
      width: "100%"
    }
  },

  uniqueContent: {
    lineHeight: "20px",
    display: "block",
    width: "60%",
    marginTop: "20px",
    "@media (max-width:1250px)": {
      width: "100%"
    }
  },

  wrapperSubscription: {
    height: "auto",
    width: "70%",
    margin: "15px auto",
    padding: "0rem 5rem 6rem 5rem",
    display: "flex",
    justifyContent: "center",
    "@media (max-width:1100px)": {
      width: "85%"
    },
    "@media (max-width:800px)": {
      margin: "15px auto 0 auto",
      textAlign: "center",
      padding: "0rem 0"
    },
    "@media (max-width:400px)": {
      margin: "35px auto 0 auto",
      textAlign: "center",
      padding: "0rem 0"
    }
  },
  wrapperPayment: {
    display: "flex",
    justifyContent: "center",
    padding: "0 10rem",
    "@media (max-width:800px)": {
      textAlign: "center",
      padding: "0"
    }
  },
  wrapperInnerPayment: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width:1200px)": {
      rowGap: "3rem",
      flexDirection: "column",
      justifyContent: "center"
    }
  },

  advantageWrapper: {
    height: "auto",
    // padding: "90px 10rem",
    display:'flex',
    flexDirection:"column",
    alignItems:"center",
    justifyContent:'space-around',
    background:"#000",
    padding:'25px 0',
    "@media (max-width:600px)": {
      padding: "1rem 3rem 2rem 3rem"
    }
  },

  signupWrapper: {
    // padding: "20px 0px 40px 0px",
    padding: "80px 0 80px 0",
    backgroundImage: `linear-gradient(to right,   rgba(79,141,214, .8) ,  rgba(142, 82, 181, 1) )`,
    "@media (max-width:400px)": {
      padding: "90px 90px 20px 90px"
    }
  },
  signupWrapperInner: {
    position: "relative",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "0",
      right: "0",
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      background: `url(${music1})`
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "0",
      left: "100%",
      width: "100%",
      height: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      background: `url(${music2})`,
      transform: "translateX(-25%)"
    }
  },
  featureContainer: {
    display: "grid",
    gridGap: "3rem",
    margin: "4rem 0 1rem 0",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
    "@media (max-width:700px)": {
      margin: "3rem 0 1rem 0"
    }
  },
  table: {
    width: "100%",
    borderCollapse: "collapse"
  },
  featureCard: {
    display: "flex",
    alignItems: "center"
  },
  social_icons_wrapper: {
    marginRight: "15px",
    display: "flex",
    columnGap: "1rem"
  },
  paymentImg: {
    width: "40%",
    diplay: "flex",
    "@media (max-width:650px)": {
      width: "70%"
    }
  },
  iconsCustomStyle: {
    marginLeft: '0px !important',
    color: '#FFF'
  }
}))

