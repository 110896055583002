import * as React from "react"
import { Box, makeStyles, InputBase, Typography } from "@material-ui/core"
import { FaSearch } from "react-icons/fa"
import clsx from "clsx"
import { bpm, key, genres, popular } from "./script"
import { StyledGrid } from "../Container/StyledGrid"
import RangeSlider from "../RangeSlider/index"

const { useState} = React

export const SMFilter = ({ filters, setFilters, refreshFilters, updateFilters, setUpdateFilters,  appliedFilters, clearFilterData, removeFilter, filteredGenres }) => {
  const { search, searchIcon, inputRoot, inputInput, langs, typo, phraseBox1, phrase, active, refreshBox, filterDesc } = useStyles()

  return (
    <Box>
      <StyledGrid container spacing={1}>
        <StyledGrid item xs={6}>
          <Box
            className={clsx(phraseBox1, phrase, "pointer", {
              [active]: filters?.isPhrases === "1" && filters?.isOneshot === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isPhrases: filters?.isPhrases === "1" && filters?.isOneshot === "0" ? "" : "1",
                isOneshot: filters?.isPhrases === "1" && filters?.isOneshot === "0" ? "" : "0"
              })
            }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Phrases</Typography>
            </Box>
          </Box>
        </StyledGrid>
        <StyledGrid item xs={6}>
          <Box
            className={clsx(phraseBox1, phrase, "pointer", {
              [active]: filters?.isOneshot === "1" && filters?.isPhrases === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isPhrases: filters?.isOneshot === "1" && filters?.isPhrases === "0" ? "" : "0",
                isOneshot: filters?.isOneshot === "1" && filters?.isPhrases === "0" ? "" : "1"
              })
            }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>One-Shots</Typography>
            </Box>
          </Box>
        </StyledGrid>
      </StyledGrid>

      <StyledGrid container spacing={1}>
        <StyledGrid item xs={3}>
          <Select
            options={filteredGenres}
            classes={langs}
            value={filters?.genre}
            setHandle={(value) => {
              setFilters({
                ...filters,
                genre: value
              })
            }}
          />
        </StyledGrid>

        <StyledGrid item xs={3}>
          <Select
            options={bpm}
            classes={langs}
            value={filters?.bpm}
            setHandle={(value) => {
              setFilters({
                ...filters,
                bpm: value
              })
            }}
          />
        </StyledGrid>

        <StyledGrid item xs={3}>
          <Select
            options={key}
            classes={langs}
            value={filters?.key}
            setHandle={(value) => {
              setFilters({
                ...filters,
                key: value
              })
            }}
          />
        </StyledGrid>

        <StyledGrid item xs={3}>
          <Select
            options={popular}
            classes={langs}
            value={filters?.trending}
            setHandle={(value) => {
              setFilters({
                ...filters,
                trending: value
              })
            }}
          />
        </StyledGrid>
      </StyledGrid>

      <StyledGrid item xs={12} display="flex" alignItems="center">
        <div className={search}>
          <InputBase
            onChange={({ target }) => {
              setFilters({
                // ...filters,
                searchKey: target.value
              })
            }}
            placeholder="Search for vocals"
            value={filters?.searchKey}
            classes={{
              root: inputRoot,
              input: inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          />
          <div className={searchIcon}>
            <FaSearch />
          </div>
        </div>
      </StyledGrid>

      <Box display="flex" justifyContent="flex-start" width="100%" style={{ columnGap: "15px", flexWrap: "wrap" }}>
        {appliedFilters?.map((filter) => (
          <Box key={filter}>
            <Typography className={filterDesc}>
              <span style={{ maxWidth: "180px", overflow: "hidden", whiteSpace: "nowrap" }}>{filter} </span>&nbsp;
              <img src="/media/close_2.png" alt="close img" onClick={() => removeFilter(filter)} />
            </Typography>
          </Box>
        ))}

        {appliedFilters?.length > 1 && (
          <Box onClick={clearFilterData}>
            <Typography className={refreshBox}>
              <img src="/media/close.png" style={{ backgroundColor: "#D9D9D9", padding: "3px 2px", borderRadius: "5px " }} alt="delete img" />
              &nbsp; &nbsp;Clear filters
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  langs: {
    width: "100%",
    backgroundColor: "transparent",
    color: "white",
    paddingBlock: 12
  },
  root: {
    width: "61%",
    position: "fixed",
    [theme.breakpoints.down("lg")]: {
      width: "71%"
    },
    "@media screen and (max-width: 1500px)": {
      width: "75%"
    },
    [theme.breakpoints.down("md")]: {
      width: "70%"
    }
    // top: 0,
    // left: 0
    // justifyContent: "center"
  },
  filterBox: {
    backgroundColor: "#EBEBEB",
    borderRadius: 5,
    width: "80%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      //   flexDirection: "column"
      width: "100%"
    }
  },
  search: {
    position: "relative",
    borderRadius: 4,
    border: `1px solid white`,
    backgroundColor: "transparent",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: 0,
    // marginLeft: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      //   marginLeft: theme.spacing(3),
      width: "auto"
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "5px 0px"
    }
  },
  searchIcon: {
    padding: "0px 10px",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: `transparent`,
    color: "white",
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    [theme.breakpoints.down("md")]: {
      height: "40.63px"
    }
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    padding: "8px 8px",
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "12px 8px"
    }
  },
  phrase: {
    borderRadius: 5,
    // display: "flex",
    // alignItems: "center",
    backgroundColor: "#EBEBEB",
    padding: 10,
    boxSizing: "border-box"
  },
  phraseBox: {
    // width: "100%"
    backgroundColor: "transparent",
    borderRadius: 10,
    border: "1px solid white"
  },
  phraseBox1: {
    width: "100%",
    backgroundColor: "transparent",
    borderRadius: 5,
    border: "1px solid white"
  },
  typo: {
    "&.MuiTypography-body1": {
      fontSize: 12,
      fontWeight: 500,
      color: "white",
      wordSpacing: 2
    }
  },
  active: {
    boxSizing: "border-box",
    border: "2px solid #86DB78"
  },
  refreshBox: {
    backgroundColor: "transparent",
    color: "white",
    padding: "2px 3px",
    textAlign: "center",
    borderRadius: "5px",
    margin: "18px 0 0 0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    columnGap: "4px"
  },
  filterDesc: {
    backgroundColor: "transparent",
    color: "white",
    padding: "1px 10px",
    textAlign: "center",
    borderRadius: "5px",
    border: "1px solid white",
    margin: "18px 5px 0 2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    columnGap: "4px"
  },
  select: {
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    border: "1px solid white",
    borderRadius: 5,
    margin: "0 3px",
    // border: `1px solid ${theme.palette.secondary.main}`,
    padding: "6px 7px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "5px 0px",
      padding: "12px 7px"
    }
  },
  selectStyle: {
    width: "100%",
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 5
  }
}))

export const Select = ({ options, value, haddleQueryParams, setHandle, type }) => {
  const { select, selectStyle } = useStyles()
  const [bpmValue, setBpmValue] = useState([0, 100])

  return (
    <>
      {type === "bpm" ? (
        <div style={{ width: "100px" }}>
          <RangeSlider value={bpmValue} setValue={setBpmValue} />
        </div>
      ) : (
        <select value={value} onChange={({ target }) => setHandle(target.value)} className={clsx(select, selectStyle)}>
          {options.map((o, index) => (
            <option key={index} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      )}
    </>
  )
}
