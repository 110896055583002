import * as React from "react"
import { Box, makeStyles } from "@material-ui/core"
import { StyledGrid } from "../../../_warbls/components/Container/StyledGrid"
import { AdminFilter } from "../../../_warbls/components/DashboardFilter"
import { Table } from "./Table"
import { useDispatch, useSelector } from "react-redux"
import { filleFilterInitialValues, filterInitialValues } from "../../../_warbls/components/Vocals/script"
import { actions } from "../../../redux/track/actions"
import { SideBar } from "./sidebar"
import { ContentContainer } from "../../../_warbls/components/Container"
import AdminSideBar from "./adminSideBar"
import { APIs } from "../../../_helpers/apis"
import axios from "axios"
import cogoToast from "cogo-toast"
import swal from "sweetalert"
import { FilesFilter } from "../../../_warbls/components/Vocals/FilesFilter"

const { useEffect, useState } = React

export const Files = () => {


  const { wrapper, sideBar, table, adminSideBar, newVocalButton } = useStyles()
  const { allTracks } = useSelector((state) => state.track)
  const { userInfo } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [container, setContainer] = useState("")
  const [filesFilters, setFilesFilters] = useState(filleFilterInitialValues)

  const [current, setCurrent] = useState("")
  const [selected, setSelected] = useState("")
  const [sideBarActivation, setSideBarActivation] = useState(false)
  const [currentVocal, setCurrentVocal] = useState(null)
  const [filters, setFilters] = useState(filterInitialValues)
  const [openAdmin, setOpenAdmin] = useState(false)
  const [tracks, setTracks] = useState(allTracks)
  const [resortedTracks, setResortedTracks] = useState(true)
  const [sortedTracks, setSortedTracks] = useState(tracks)

  useEffect(() => {
    if (filesFilters.isOldest === "1") {
      setSortedTracks(sortedTracks.sort((a, b) => a.track_id.toString().localeCompare(b.track_id.toString())))
    } else if (filesFilters.isNewest === "1") {
      setSortedTracks(sortedTracks.sort((a, b) => b.track_id.toString().localeCompare(a.track_id.toString())))
    }
    if (filesFilters.isLeastDownloaded === "1") {
      setSortedTracks(sortedTracks.sort((a, b) => a.download.toString().localeCompare(b.download.toString())))
    } else if (filesFilters.isMostDownloaded === "1") {
      setSortedTracks(sortedTracks.sort((a, b) => b.download.toString().localeCompare(a.download.toString())))
    }
    if (filesFilters?.searchKey !== "") {
    let filteredSongs = allTracks?.filter((track) => {
      return track.track_name.toString().replaceAll('_'," ").toLowerCase().includes(filesFilters.searchKey.toLowerCase())
    })
    setSortedTracks(filteredSongs)
    } else setSortedTracks(allTracks)

    setResortedTracks(!resortedTracks)
  }, [filesFilters])

  const handleStop = () => {
    setSelected("")
  }
  const handlePlay = (i) => {
    setCurrent(i)
    setSelected(i)
  }

  useEffect(() => {
    dispatch(actions.getAllPendingTracksRequest())
  }, [])

  const updateTrackStatus = (track, type, toast) => {
    const payload = {
      status: type ? type : track.status === "active" ? "pending" : "active",
      is_oneshot: track.is_oneshot,
      is_phrases: track.is_phrases,
      log: JSON.stringify({ log_added_by: userInfo.user_id })
    }
    const id = track.track_id
    dispatch(actions.updateTracksRequest(payload, id, toast))
  }

  const handlePublish = (row, tracks, type) => {
    swal({
      title: "Are you sure?",
      text: "Once action performed, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Poof! Action has been perfomed succesfully!", {
          icon: "success"
        })
        if (tracks) {
          setTimeout(() => {
            tracks.map((track) => {
              updateTrackStatus(track, type, true)
            })
          }, 2000)
        } else {
          updateTrackStatus(row)
        }
      } else {
        swal("Cancelled! Your data is safe")
      }
    })
  }

  const handleDelete = (track_id) => {
    track_id.forEach((track, index) => {
      track_id[index] = parseInt(track.track_id)
    })

    swal({
      title: "Are you sure?",
      text: "Once action performed, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(async (willDelete) => {
      if (willDelete) {
        swal("Poof! Action has been perfomed succesfully!", {
          icon: "success"
        })

        axios
          .delete(APIs.deleteTrack, {
            headers: {
              tracks: JSON.stringify(track_id)
            }
          })
          .then(() => {
            cogoToast.success("Track/s deleted successfully")
          })
          .catch(() => cogoToast.error("An unexpected error occured."))
        // disable-next-es-lint
      } else {
        swal("Cancelled! Your data is safe")
      }
    })
  }

  return (


    <Box className={wrapper}>
      <ContentContainer
        titleLeft="60px"
        title="Vocalfiles"
        titleBackground={"linear-gradient(186.71deg, #468241 12.77%, #333333 126.7%)"}
        adminGradient={true}
      >




        <div className={sideBar} 
          // style={{ display: !sideBarActivation ? "none" : "unset" }}
          
          >
          {/* <SideBar
            setSideBarCheck={setSideBarActivation}
            sideBarCheck={sideBarActivation}
            vocal={currentVocal}
            filters={filters}
            setFilters={setFilters}
          /> */}
        </div>

        <Box
          style={{
            width: "100%",
            padding: "30px 50px 0 50px"
          }}
        >
          {/* <FilesFilter filters={filesFilters} setFilters={setFilesFilters} /> */}
        </Box>

        <div className={adminSideBar} 
           style={{ display: !openAdmin ? "none" : "unset" }}
          >
           <AdminSideBar setSideBarCheck={setOpenAdmin} sideBarCheck={openAdmin} /> 
        </div>

        

        <Box width="90%" mx={"auto"} className={table}>
          <StyledGrid>
            <StyledGrid item>
              <AdminFilter title="Total Files" length="2309" placeholder="Search Vocalfiles" filters={filesFilters} setFilters={setFilesFilters} />
              <Box display="flex" justifyContent="center" width="70vw" style={{ margin: "0 auto" }}>
                <div className={newVocalButton}>
                  <button onClick={(e) => setOpenAdmin(true)}>Add new Vocals</button>
                </div>
                <Box display="flex" justifyContent="space-between">
                  <div className={newVocalButton}>
                    <button onClick={() => handlePublish(allTracks, allTracks, "active")}>Publish All</button>
                  </div>
                  <div className={newVocalButton}>
                    <button onClick={() => handlePublish(allTracks, allTracks, "pending")}>Unpublish All</button>
                  </div>
                </Box>
              </Box>


              {/* the problematic area is the Table */}

              <Table
                tracks={sortedTracks}
                setContainer={setContainer}
                setCurrent={setCurrent}
                container={container}
                current={current}
                handlePlay={handlePlay}
                handleStop={handleStop}
                selected={selected}
                sideBarActivation={sideBarActivation}
                setSideBarActivation={setSideBarActivation}
                currentVocal={currentVocal}
                setCurrentVocal={setCurrentVocal}
                handlePublish={handlePublish}
                handleDelete={handleDelete}
                resortedTracks={resortedTracks}
              />
            </StyledGrid>

          </StyledGrid>
        </Box>
            {/* <StyledGrid item xs={3} px={2}>
            <PaymentBox />
          </StyledGrid> */}
      </ContentContainer>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "relative"
  },
  adminSideBar: {
    position: "absolute",
    width: "90%",
    height: "auto",
    zIndex: 10,
    top: "0%",
    right: "0%",
    background: "#282828"
  },
  sideBar: {
    position: "absolute",
    width: "50%",
    height: "auto",
    zIndex: 10,
    top: "0%",
    right: "0%",
    background: "#282828",
    "@media(max-Width: 1050px)": {
      width: "75% !important"
    },
    "@media(max-Width: 599px)": {
      top: ".9rem !important"
    },
    "@media(max-Width: 500px)": {
      width: "100% !important"
    },
    "@media(max-Width: 480px)": {
      top: ".4rem !important"
    }
  },
  table: {
    "@media (max-width:969px)": {
      marginTop: "16rem"
    }
  },
  typo: {
    fontSize: 20,
    color: theme.palette.primary.main,
    fontStyle: "normal",
    fontWeight: "normal",
    marginBottom: 5
  },
  newVocalButton: {
    display: "flex",
    width: "150px",
    justifyContent: "center",
    margin: "10px auto 30px auto",
    "& button": {
      padding: "15px 25px",
      backgroundColor: "transparent",
      color: "white",
      outline: "none",
      border: "1px solid white",
      cursor: "pointer"
    }
  }
}))
