import { Button, makeStyles, Typography } from "@material-ui/core"
import * as React from "react"
import { StyledGrid } from "../../../_warbls/components/Container/StyledGrid"
import { AiOutlineClose } from "react-icons/ai"
import { Row } from "../../../_warbls/components/Flex/Row"
import { Column } from "../../../_warbls/components/Flex/Column"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/track/actions"

export const Cart = (props) => {
  const { handleNext } = props
  const { fontBold, trackImg, desc, proceedBtn } = useStyles()
  const cart = useSelector((state) => state.track.cart || [])
  const dispatch = useDispatch()

  const handleRemoveCart = (id) => {
    dispatch(actions.removeCartRequest(id))
  }
  const cart_sum = cart
    .reduce(function (r, a) {
      return r + parseFloat(a["price"])
    }, 0)
    .toFixed(2)
  return (
    <>
      <StyledGrid container spacing={0} width="90%" style={{ margin: "0 auto", rowGap: "30px", fontWeight: "bold" }}>
        <StyledGrid item xs={12} md={8}>
          <Typography style={{ marginBottom: "1rem", marginLeft: "5px", fontWeight: "bold" }} variant="h5">{`Your cart (${cart.length} items)`}</Typography>
          {cart.map((cart, index) => (
            <>
              <StyledGrid container>
                <StyledGrid item xs={12} md={10} key={index} my={1}>
                  <Row
                    jc="space-between"
                    style={{
                      overflowWrap: "anywhere",
                      backgroundColor: "#202020",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "4px 0",
                      alignItems: "center"
                    }}
                    mx={1}
                  >
                    <div style={{display: "flex", marginRight: "auto"}}>
                      <Column px={1}>
                        <img src={cart.image_url} alt="" className={trackImg} />
                      </Column>

                      <Column px={1}>
                        <Typography className={fontBold}>&nbsp;{cart.track_name}</Typography>
                      </Column>
                    </div>
                    <Column p={1} style={{ overflowWrap: "anywhere", cursor: "pointer", marginLeft: "auto" }} mx={2} onClick={() => handleRemoveCart(cart.track_id)}>
                      <Row>
                        <Typography className={desc} align="center">
                          ${cart.price}
                        </Typography>

                        <Typography className={desc} fontSize={30} style={{ marginRight: "2rem", fontSize: "40px", lineHeight: "0" }}></Typography>
                        <AiOutlineClose fontSize={25} style={{ margin: "0 .5rem 0 0" }} color={"gray"} />
                      </Row>
                    </Column>
                  </Row>
                </StyledGrid>
              </StyledGrid>
            </>
          ))}
        </StyledGrid>
        <StyledGrid item xs={12} md={4}>
          <Typography style={{ marginBottom: "1.5rem", fontWeight: "bold" }} variant="h5">
            Order Summary
          </Typography>
          <Column jc="space-between" p={2} my={2} style={{ backgroundColor: "#202020", padding: "30px" }}>
            <Typography className={desc} align="center" style={{ fontSize: "20px", fontWeight: "bold", margin: "0.5rem 0" }}>
              {cart.length}, Royalty-Free Vocals
            </Typography>

            <Typography className={desc} align="center" style={{ fontSize: "30px", fontWeight: "bold", margin: "0.5rem 0" }}>
              $ {cart_sum}
            </Typography>
            <Column ai="center">
              <Button className={proceedBtn} onClick={handleNext}>
                Proceed to checkout
              </Button>
            </Column>
          </Column>
        </StyledGrid>
      </StyledGrid>
    </>
  )
}

// const languages = [
//   { label: "Standard $49", value: "", disabled: true },
//   { label: "Standard $49", value: "english" },
//   { label: "Standard $49", value: "french" }
// ]

const useStyles = makeStyles((theme) => ({
  imgClass: {
    width: 150,
    height: 150
  },
  trackImg: {
    width: '35px',
    borderRadius: '5px'
  },
  selectClass: {
    border: "0px",
    color: "#000000",
    width: "100%"
  },
  fontBold: {
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
    margin: "7px 0 0 0",
    fontSize: "17px"
  },
  desc: {
    fontWeight: "bold",
    fontSize: "17px",
    color: theme.palette.primary.contrastText
  },
  link: {
    color: "#4099F3"
  },
  wan: {
    fontWeight: "bold",
    fontSize: "17px"
  },

  proceedBtn: {
    backgroundColor: theme.palette.btns.pay,
    color: "#ffffff",
    borderRadius: 5,
    textTransform: "none",
    width: "100%",
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 10,
    marginBottom: 5,
    fontWeight: "bold",
    fontSize: "20px",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: "#ffffff"
    }
  },
  continues: {
    textDecoration: "none"
  }
}))
