import { useState, useEffect } from "react"
import { makeStyles, Stepper, Step, StepLabel, Box } from "@material-ui/core"
import clsx from "clsx"
import axios from "axios"
import { Cart } from "./ViewCart"
import { Checkout } from "./Checkout"
import { Confirmation } from "./Confirmation"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { ContentContainer } from "../../../_warbls/components/Container"
import { useSelector } from "react-redux"
import { APIs } from "../../../_helpers/apis"

export const ViewCart = (props) => {
  const classes = useStyles()
  const { auth } = useSelector((state) => state.auth)
  const [ prevCardInfo, setPrevCardInfo ] = useState()
  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()
  const cart = useSelector((state) => state.track.cart || [])
  const handleNext = () => {
    if (cart.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  useEffect(()=>{
    if(!auth) return;

    axios.get(APIs.getCardInfo, {
      headers: {
        "Auhtorization" : auth
      }
    }).then(resp => {
      if ( !resp.data || !resp.data?.data || resp.data === '' || Object.keys(resp.data).length === 0 || resp.data === undefined)
        return
      else
        setPrevCardInfo(resp.data.data)
    })
  }, [auth])

  return (
    <Box p={0} width="100%">
      {activeStep === 2 ? (
        <ContentContainer titleLeft="38%" titleBackground={"#48E12F"} title="Thank You!" pageType="payment" thankYou={true}>
          <Confirmation handleReset={handleReset} handleBack={handleBack} />
        </ContentContainer>
      ) : (
        <ContentContainer
          titleBackground={activeStep === 0 ? "linear-gradient(to right, #5F5F5F , #C2EDBB)" : "linear-gradient(to right,#757E73 ,#31ae1b )"}
          title={activeStep === 0 ? "Cart" : "Checkout"}
          pageType="payment"
        >
          <StyledContainer>
            <div className={classes.root} style={{ width: "80%" }}>
              <Box className={classes.stepperConatainer}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={index} onClick={() => activeStep === 1 && index === 0 && setActiveStep(0)}>
                      <StepLabel style={{ cursor: "pointer", fontWeight: "bold" }} StepIconComponent={() => StepIcon({ step: index, activeStep })}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={clsx(activeStep === 1 && classes.hideOverflow)}>
                {activeStep === 0 ? (
                  <Cart handleNext={handleNext} handleBack={handleBack} />
                ) : activeStep === 1 ? (
                  <Checkout handleNext={handleNext} handleBack={handleBack} prevCardInfo={prevCardInfo} />
                ) : (
                  <Confirmation handleReset={handleReset} handleBack={handleBack} />
                )}
              </div>
            </div>
          </StyledContainer>
        </ContentContainer>
      )}
    </Box>
  )
}

const StepIcon = (props) => {
  const { step, activeStep } = props
  return (
    <div>
      <p
        style={{
          padding: "1px 7px",
          color: "black",
          backgroundColor: step === activeStep ? "white" : "gray",
          borderRadius: "2px"
        }}
      >
        {step + 1}
      </p>
    </div>
  )
}

function getSteps() {
  return ["View cart", "Checkout", "Confirmation"]
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "0 auto",

    "& .MuiPaper-root": {
      backgroundColor: "#fff0",
      padding: "3rem 0",
      position: "relative",
      left: "0px"
    },
    "@media(max-width:1280px)": {
      marginTop: 50
    },
    "@media(max-width:550px)": {
      width: "100% !important",
      maxWidth: "100vw"
    }
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepperConatainer: {
    width: "35%",
    marginLeft: "5%",

    "@media (max-width:1000px) ": {
      width: "100%"
    },
    "@media (max-width:420px) ": {}
  },
  hideOverflow: {
    "@media (max-width:500px)": {
      maxWidth: "450px !important",
      display: "flex",
      justifyContent: "center",
      margin: "0 auto"
    },
    "@media (max-width:400px)": {
      maxWidth: "330px !important"
    }
  }
}))
