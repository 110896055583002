import { useState } from "react"
import { Box, Button, CircularProgress, makeStyles } from "@material-ui/core"
import clsx from "clsx"
import * as React from "react"
import { FaPlus } from "react-icons/fa"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/track/actions"
import { initValues } from "./script"
import { Vocal } from "./Vocal"
import { store } from "../../../redux/store"
import { APIs } from "../../../_helpers/apis"
import { POST } from "../../../_helpers/fetcher"

export const AddVocal = ({}) => {
  const { btn, addDelBtn, publishBtnStyle, btnWrapper } = useStyles()
  const [track, setTrack] = useState();
  const [image, setImage] = useState()
  const [loading,setLoading] = useState(false)

  const [vocals, setVocals] = useState([
    {
      ...initValues,
      id: new Date().getTime(),
      added_by: store.getState().auth.userInfo.user_id,
      formik: null
    }
  ])

  const dispatch = useDispatch()
  // const { loading } = useSelector((state) => state.track)

  // const formik = useFormik({
  //   initialValues: initValues,
  //   validationSchema: schema,
  //   onSubmit: async (values, { resetForm }) => {

  //     dispatch(actions.uploadTrackRequest(vocals))
  //   }
  // })

  const handleTrack = async (e) => {
    let file = e.target.files[0];
    if (file) {
        // Create a unique file name
        const uniqueFileName = `${Date.now()}-${file.name}`; // or use `uuidv4()` for UUID
        
        // Rename the file
        const renamedFile = new File([file], uniqueFileName, { type: file.type });

        let fd = new FormData();
        fd.append("files", renamedFile);
        // vocal["track"] = fd;

        setTrack({ name: "Please wait, file is uploading...", isUploading: true });

        const imageResponse = await POST(APIs.uploadTrack, fd);
        setTrack(renamedFile);
        return imageResponse?.data.location;
        // vocal["track_url"] = imageResponse?.data.location;

    }
  }

  const handleImage = async (e) => {
    let file = e.target.files[0];
    if (file) {
        // Create a unique file name
        const uniqueFileName = `${Date.now()}-${file.name}`; // or use `uuidv4()` for UUID
        
        // Rename the file
        const renamedFile = new File([file], uniqueFileName, { type: file.type });

        let fd = new FormData();
        fd.append("files", renamedFile);
        // vocal["image"] = fd;

        setImage({ name: "Please wait, file is uploading...", isUploading: true });

        const trackResponse = await POST(APIs.uploadTrack, fd);
        setImage(renamedFile);
        return trackResponse?.data.location;
        // vocal["image_url"] = trackResponse?.data.location;

    }
};
  const handlePublish = async () => {
    let isValid = true;
    setLoading(true)
    for (const vocal of vocals) {
        if (vocal.is_phrases === 1) vocal.price = 19.90;
        if (vocal.is_oneshot === 1) vocal.price = 6.90;
        
        console.log("aaa",vocal.formik)
        vocal.formik.handleSubmit();
        if(vocal.formik.isValid){
          vocal["track_url"] = await handleTrack(vocal['track']);
          vocal["image_url"] = await handleImage(vocal['image']);
          delete vocal['track'];
          delete vocal['image'];
        }
        isValid = isValid && vocal.formik.isValid && !!vocal.track_url && !!vocal.image_url;
    }
    console.log({ vocals });
    if (isValid) {
      console.log({isValid})
      dispatch(actions.uploadTrackRequest(vocals));
      setLoading(false)
    }
  }
  const handleAddVocal = () => {
    let vcls = [...vocals, { ...initValues, id: new Date().getTime() }]
    setVocals(vcls)
  }
  const handleRemoveVocal = ({ id }) => {
    let vcls = vocals.filter((v) => v.id !== id)
    setVocals(vcls)
  }

  return (
    <>
      {vocals.map((vocal, index) => (
        <Vocal key={vocal.id} vocal={vocal} removeVocalCallback={handleRemoveVocal} track={track} image={image}/>
      ))}
      <Box my={2} className={btnWrapper} display="flex">
        <Box justifyContent="center" display="flex" className={addDelBtn} onClick={handleAddVocal}>
          <FaPlus fontSize={20}></FaPlus>
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={handlePublish}
          // onClick={formik.handleSubmit}
          className={clsx(btn, publishBtnStyle)}
          disableElevation
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : "Publish"}
        </Button>
      </Box>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  btnWrapper: {
    width: "60%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "64px",
    paddingRight: "64px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  },

  addDelBtn: {
    width: "15%",
    borderRadius: 5,
    padding: 10,
    marginRight: 7,
    cursor: "pointer",
    //paddingBottom: 10,
    border: `1px solid`,
    //backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      //backgroundColor: theme.palette.primary.main
    }
  },
  btn: {
    width: "100%",
    borderRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  publishBtnStyle: {
    width: "85%",
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  }
}))
