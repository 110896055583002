import * as React from "react"
import clsx from "clsx"
import { Box, Drawer, AppBar, CssBaseline, useMediaQuery, useTheme, Button } from "@material-ui/core"
import { DrawerList } from "./List"
import { list1, list3, list4, list6, list7, list8 } from "./script"
import { Header } from "../Header"
import { AdminHeader } from "../Header/AdminHeader"
import { useDispatch, useSelector } from "react-redux"
import { useStyles } from "./script"
import { actions } from "../../../redux/track/actions"
import { actions as TrackActions } from "../../../redux/track/actions"
import { AppContext } from "../../providers/AppProvider"
import { FaRegHeart, FaShoppingCart } from "react-icons/fa"
import { ImHeart } from "react-icons/im"
import { useContext } from "react"
import { sidebarDrawerContext } from "../../../app/App"
import { actions as authActions } from "../../../redux/auth/actions"
import { useHistory } from "react-router"

import SideBarInfo from "../SideBarInfo"
import SideSongInfo from "../SideSongInfo"


const { useState, useEffect } = React

export const AppDrawer = ({ children }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch()
  const history = useHistory()

  const { userInfo, auth } = useSelector((state) => state.auth)
  const is_admin = useSelector((state) => state.auth.userInfo.is_admin)
  const { handleLogin, handleCart } = useContext(AppContext)
  const theme = useTheme()
  const media = useMediaQuery(theme.breakpoints.down("sm"))
  const { volume } = useSelector((state) => state.track)
  const [mute, setMute] = useState(false)
  const { allTracks } = useSelector((state) => state.track)
  const currentPlayingTrack = useSelector((state) => state.track.currentPlayingTrack)
  const [heartFlag, setheartFlag] = useState(currentPlayingTrack?.id && allTracks[currentPlayingTrack?.id]?.liked === 1)
  const payload = {
    object_type: "user",
    object_id: userInfo.user_id,
    added_by: userInfo.user_id
  }

  var contextData = React.useContext(sidebarDrawerContext)

  useEffect(() => {
    dispatch(TrackActions.getAllPendingTracksRequest())
  }, [])

  useEffect(() => {
    if (volume === 0) {
      setMute(true)
    } else {
      setMute(false)
    }
  }, [volume])


  const handleLike = (trackID) => {
    if (auth) {
      const data = {
        action: "like",
        action_against_id: trackID,
        ...payload
      }
      dispatch(actions.trackWiseLikeRequest(data))
    } else {
      handleLogin()
    }
  }

  const handleUnlike = (trackID) => {
    if (auth) {
      const data = {
        action: "like",
        object_id: userInfo.user_id,
        log: JSON.stringify({ log_added_by: userInfo.user_id })
      }
      dispatch(actions.trackWiseUnLikeRequest(data, trackID))
    } else {
      handleLogin()
    }
  }

  list1[0].label = userInfo.full_name
  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          elevation={0}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open
          })}
        >
          {is_admin ? <AdminHeader open={open} /> : <Header open={open} />}
        </AppBar>

        {!media && (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerClose]: true
            })}
            classes={{
              paper: clsx({
                [classes.drawerClose]: true
              })
            }}
          >
            <div className={classes.toolbar}></div>
            {/* <Divider className={classes.topDivider} /> */}
            <div className={classes.drawerContainer}>
              
              <Box style={{padding: '0px 6px',borderRadius: '5px'}}>
                {userInfo.is_admin === 1 ? (
                  <>
                    <DrawerList list={list4} open={open} hover={classes.hoverLink} active={classes.activeLink} />
                    <div className={classes.divider} />
                    <DrawerList list={list6} open={open} hover={classes.hoverLink} active={classes.activeLink} />
                    <div
                      style={{ position: "absolute", bottom: "0", maxWidth: "53px" }}
                      onClick={() => {
                        dispatch(authActions.logoutRequest())
                        history.push("/")
                      }}
                    >
                      <DrawerList list={list3} open={open} hover={classes.hoverLink} active={classes.activeLink} />
                    </div>
                  </>
                ) : (
                  <>
                    <DrawerList list={list7} open={open} hover={classes.hoverLink} active={classes.activeLink} />
                    <div
                      onClick={() => contextData?.isCollapsed(false)}
                      style={{
                        margin: "-15px -5px 0px -5px",
                        display: "flex",
                        padding: 16,
                        justifyContent: "space-between",
                        textAlign: "center"
                      }}
                    ></div>
                  </>
                )}
              </Box>
              <Box style={{padding: '0px 6px',borderRadius: '5px'}}>
                {userInfo.is_admin === 0 ? <DrawerList list={[list8[0]]} open={open} hover={classes.hoverLink} active={classes.activeLink} /> : null}
                {/* <DrawerList list={list8} open={open} hover={classes.hoverLink} active={classes.activeLink} /> */}
                {/* <div className={classes.divider} /> */}
              </Box>
              
              <Box style={{padding: '0px 6px',borderRadius: '5px'}}>
                {userInfo.is_admin === 0 ? <DrawerList list={[list8[1]]} open={open} hover={classes.hoverLink} active={classes.activeLink} /> : null}
                {/* <DrawerList list={list8} open={open} hover={classes.hoverLink} active={classes.activeLink} /> */}
                {/* <div className={classes.divider} /> */}
              </Box>

              <Box style={{padding: '0px 6px',borderRadius: '5px'}}>
                <Box style={{height:'10px',width:'100%',backgroundColor:'#333333',borderRadius:'5px'}}></Box>
              </Box>

              
              {currentPlayingTrack && currentPlayingTrack.id && (
                <div className={classes.activeVocal}>
                  <div className={classes.activeVocalFlex}>
                    <h6 className={classes.activeVocalName}>{allTracks[currentPlayingTrack.id - 1]?.track_name || "N/A"}</h6>
                    <>
                      {heartFlag ? (
                        <ImHeart
                          className={classes.heartStyle}
                          fontSize="16px"
                          onClick={() => {
                            handleUnlike(currentPlayingTrack?.id - 1)
                            allTracks[currentPlayingTrack?.id - 1].liked = 0
                            setheartFlag(!!!heartFlag)
                          }}
                        />
                      ) : (
                        <FaRegHeart
                          className={classes.heartStyle}
                          fontSize="16px"
                          onClick={() => {
                            handleLike(currentPlayingTrack?.id - 1)
                            allTracks[currentPlayingTrack?.id - 1].liked = 1
                            setheartFlag(!!!heartFlag)
                          }}
                        />
                      )}
                    </>
                    <Button className={classes.btn} onClick={() => handleCart(allTracks[currentPlayingTrack?.id - 1])}>
                      <span style={{ color: "white", fontSize: "20px", marginTop: "10px" }}>
                        <FaShoppingCart />
                      </span>
                    </Button>
                    
                  </div>

                  <p
                    style={{
                      margin: "0 !important",
                      padding: "0",
                      fontSize: "10px",
                      display: "block"
                    }}
                  >
                    {allTracks[currentPlayingTrack?.id - 1]?.user_details?.full_name || "N/A"}
                  </p>
                  <img src={allTracks[currentPlayingTrack.id - 1]?.image_url} alt="currently playing track pic" />
                  
                </div>
              )}
              {/* <SideBarInfo/> */}
              {/*  */}

              <SideSongInfo/>
            </div>
          </Drawer>
        )}

        <main style={{ padding: "0px !important" }} className={classes.content}>
          <div className={classes.toolbar} style={{border:"2px solid blue"}}/>
          {children}
          
        </main>
      </div>

      {media && <div style={{ bottom: 0, width: "100%" }}>{/* <SimpleBottomNavigation /> */}</div>}
    </>
  )
}
