import React, { createContext } from 'react'

export const SongContext = createContext({
    currentSong:'',
    setCurrentSong:(song) => {  },
    containerSong:'',
    setContainerSong:(song) => {  },
    playing:false,
    setPlaying:(song) => {  },
    listTrack:'',
    setListTrack:(songPlay) => {  },
    currentId:'',
    setCurrentId:(songplap) => {  },
    songIds:'',
    setSongIds: (songId) => {  }
})





const SideBarInfo = () => {
   
  return (
    <>
    
    </>
  )
}

export default SideBarInfo