export const formScript = [
  { name: "username", type: "text", placeholder: "Email address" },
  { name: "password", type: "password", placeholder: "Password" }
]

export const initValues = {
  password: "",
  username: "",
  email: ""
}
