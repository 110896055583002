import React, { useContext, useEffect, useState } from 'react'
import './CSS/style.css'
import { SongContext } from '../SideBarInfo'
import SongPopup from './SongPopup'
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';

const SideSongInfo = () => {
    const [popup, setPopup] = useState(false)
    const mysongcontext = useContext(SongContext);
    const mytracklist = mysongcontext.listTrack

    let Ids=mysongcontext.songIds;

    useEffect(() => {
      
      Ids=mysongcontext.songIds;
      
      
    }, [mysongcontext.songIds])
  

    const songPlay=() => { 
      // setplay(!play)
      if(!mysongcontext.playing && mysongcontext.currentSong !== 'Nosong'){
        mysongcontext.currentSong.play()
        mysongcontext.setPlaying(true)
        
      }
      
     }
    const songStop=() => { 
      // console.log('This tells the length of currnt song',mysongcontext.currentSong.length);
      if(mysongcontext.playing){
        mysongcontext.currentSong.stop();
        mysongcontext.setPlaying(false)
      }

     }
    
    const handleNext = () => {
      if(mysongcontext.currentId !== null){
        const index= Ids.indexOf(mysongcontext.currentId)
        let nextSongId;
        if(index < Ids.length - 1){
          
          nextSongId = Ids[index + 1]
        }else if(index == Ids.length - 1){
          nextSongId = Ids[0]
        }
        mysongcontext.setCurrentId(nextSongId)
      }
      

    }
    const handlePrevious = () => { 
      if(mysongcontext.currentId !== null){
        const index= Ids.indexOf(mysongcontext.currentId)
        let preSongId;
        if(index > 0){

          preSongId = Ids[index - 1]
        }else if(index == 0){
          preSongId = Ids[Ids.length - 1]
        }
        mysongcontext.setCurrentId(preSongId)
    }

     }
    
    useEffect(() => {
      
    
      
    }, [])
    

  return (
    <>
      <div style={{
        display:'flex'
      }}>
        <div className='ssi-image-container' onClick={() => { setPopup(!popup) }}>
          <img className='ssi-dots' src={`${process.env.PUBLIC_URL}/images/dots.svg`} />    
          <img className='ssi-song-image' src={mytracklist?.image_url} />    
        </div>
        {
          (popup && mytracklist?.image_url) && (
            <SongPopup/>
            )
        }
      </div>
      <div className='ssi-container'>
        <div className='ssi-next ssi-icons'>
          <img onClick={handleNext} src={`${process.env.PUBLIC_URL}/images/icon-skip-next.svg`} />
        </div>
        <div className='ssi-play ssi-icons' >
          {mysongcontext.playing ?
            <StopRoundedIcon
                  onClick={songStop}
                  style={{width:'50px',height:'50px',color:'#57A4FF'}}
              />
                :
            <PlayArrowRoundedIcon                              
                onClick={songPlay}  
                style={{width:'50px',height:'50px',color:'#B1B1B1'}}
            />
            }
          
        </div>
        <div className='ssi-prev ssi-icons' >
          <img onClick={handlePrevious} src={`${process.env.PUBLIC_URL}/images/icon-skip-previous.svg`} />
        </div>

      </div>
    </>
  )
}

export default SideSongInfo