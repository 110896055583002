/** CREATE ARTIST TYPES */
export const CREATE_ARTIST_REQUEST = "CREATE_ARTIST_REQUEST"
export const CREATE_ARTIST_SUCCESS = "CREATE_ARTIST_SUCCESS"
export const CREATE_ARTIST_FAILED = "CREATE_ARTIST_FAILED"

/** UDDATE ARTIST TYPES */
export const UPDATE_ARTIST_REQUEST = "UPDATE_ARTIST_REQUEST"
export const UPDATE_ARTIST_SUCCESS = "UPDATE_ARTIST_SUCCESS"
export const UPDATE_ARTIST_FAILED = "UPDATE_ARTIST_FAILED"

/** GET ALL ARTIST TYPES */
export const GET_ARTIST_REQUEST = "GET_ARTIST_REQUEST"
export const GET_ARTIST_SUCCESS = "GET_ARTIST_SUCCESS"
export const GET_ARTIST_FAILED = "GET_ARTIST_FAILED"

/** GET SINGLE ARTIST TYPES */
export const GET_SINGLE_ARTIST_REQUEST = "GET_SINGLE_ARTIST_REQUEST"
export const GET_SINGLE_ARTIST_SUCCESS = "GET_SINGLE_ARTIST_SUCCESS"
export const GET_SINGLE_ARTIST_FAILED = "GET_SINGLE_ARTIST_FAILED"
