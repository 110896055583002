import * as React from "react"
import {
  InputBase,
  makeStyles,
  TextareaAutosize,
  useTheme,
  InputAdornment
} from "@material-ui/core"
import clsx from "clsx"
import { Column } from "../../Flex/Column"

export const InputField = (props) => {
  const { root, errMsg, inputLabel, textareaClass, hideInputLabel } = useStyles()
  const {
    classes,
    placeholder,
    name,
    type,
    formik,
    label,
    labelClass,
    style,
    disabled,
    isLightTheme,
    rootStyle,
    hideLabel,
    inputIcon,
    autoComplete
  } = props
  const theme = useTheme()

  const handleChange = (e) => {
    if (e.target.name === "number") {
      e.target.value = e.target.value.replace(/\W/gi, "").replace(/(.{4})(?!$)/g, "$1 ")
    }

    if (
      e.target.placeholder === "MM / YY" &&
      e.target.value.length > 2 &&
      !e.target.value.includes("/")
    ) {
      e.target.value = e.target.value.slice(0, 2) + "/" + e.target.value.slice(2)
    }
  }

  return (
    <Column
      width="100%"
      my={2}
      style={{
        ...rootStyle
      }}
    >
      {(label || label === "") && (
        <label
          className={clsx(
            inputLabel,
            labelClass,
            hideLabel && hideLabel === true && hideInputLabel
          )}
        >
          {label}
        </label>
      )}

      {type === "textarea" ? (
        <TextareaAutosize
          aria-label="empty textarea"
          placeholder={placeholder}
          style={{
            ...style,
            backgroundColor: !!isLightTheme ? theme.palette.primary.contrastText : "",
            color: !!isLightTheme ? theme.palette.primary.main : theme.palette.primary.contrastText
          }}
          className={clsx(root, textareaClass, classes)}
          name={name}
          disabled={!!disabled}
          onChangeCapture={props.onChange}
          {...formik.getFieldProps(name)}
        />
      ) : (
        <InputBase
          className={clsx(root, classes)}
          style={{
            textAlign:'center',
            ...style,
            // backgroundColor: !!isLightTheme ? theme.palette.primary.contrastText : "",
            // color: !!isLightTheme ? theme.palette.primary.main : theme.palette.primary.contrastText
          }}
          //style={style}
          placeholder={placeholder}
          type={type}
          name={name}
          disabled={props?.value ? true : !!disabled}
          autoComplete={autoComplete}
          onChangeCapture={(e) => handleChange(e)}
          {...formik.getFieldProps(name)}
          value={props?.value || formik.getFieldProps(name).value}
          endAdornment={
            props?.inputImage ? (
              <InputAdornment position="end">
                {inputIcon}
                <img
                  src="media/inputElement.png"
                  width="180px"
                  height="38px"
                  style={{ backgroundColor: "transparent" }}
                  alt={""}
                />
              </InputAdornment>
            ) : props?.inputCvcImage ? (
              <InputAdornment position="end">
                {inputIcon}
                <img
                  src="media/cvc.png"
                  width="50px"
                  height="auto"
                  style={{ backgroundColor: "transparent" }}
                  alt={""}
                />
              </InputAdornment>
            ) : null
          }
        />
      )}

      {formik.touched[name] && formik.errors[name] && (
        <small className={clsx("error", errMsg)}>{formik.errors[name]}</small>
      )}
    </Column>
  )
}
const useStyles = makeStyles((theme) => ({
  root: {
    //background: "#EBEBEB000",
    border: "1px solid " + theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
    // textAlign:"right",
    // borderRadius: 5,
    width: "100%",
    padding: "5px 10px"
  },
  textareaClass: {
    minHeight: "5rem"
  },
  errMsg: {
    margin: "5px 0px"
  },
  inputLabel: {
    fontWeight: 'bold',
    fontStyle: "normal",
    size: 12,
    // margin: "5px 0px",
    marginLeft: 0
  },
  hideInputLabel: {
    display: "none"
  }
}))
