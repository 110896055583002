import * as React from "react"
import { useState, useCallback, useEffect } from "react"
import { useSelector } from "react-redux"

import { Box } from "@material-ui/core"
import { VocalsTable } from "../../../_warbls/components/Vocals"
import { ContentContainer } from "../../../_warbls/components/Container"
import { heading } from "./script"
import { APIs } from "../../../_helpers/apis"
import axios from "axios"
import { filterInitialValues } from "../../../_warbls/components/Vocals/script"

export const Downloads = (props) => {
  const { userInfo } = useSelector((state) => state.auth)

  const [downloads, setDownloads] = useState([])
  const [firstLoad, setFirstLoad] = useState(true)
  const [tracksTotal, setTracksTotal] = useState(0)
  const [searchFilters, setSearchFilters] = useState(filterInitialValues)
  const [appliedFilters, setAppliedFilters] = useState([])

  const sendRequest =useCallback(async (length) => {

    const shuffleVocals = (array) => {
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
   }

    let newTracks = []
    if (searchFilters.trending === 'Random') searchFilters.trending = ''
    axios.get(`${APIs.tracks}?load=user_details&offset=${length || downloads.length}&limit=5&object_type=user&action=download&object_id=${userInfo.user_id}&searchFilters=${encodeURIComponent(JSON.stringify(searchFilters))}`).then((res) => {
      setTracksTotal(res?.data?.total)
      if (searchFilters.trending === '') newTracks = res.data?.results ? shuffleVocals(res.data.results) : []
      else{
        newTracks = res.data?.results ? res.data?.results?.filter((track) =>
        track?.status === "active"
        ) : []
      }

    if(length === 0)
      setDownloads(newTracks)

    else
      setDownloads([...downloads, ...newTracks])

    })
  }, [downloads, searchFilters, userInfo.user_id] )

  const fetchVocals = useCallback(async (length) => {
    const updatedFilters = []

      for (const [key, val] of Object.entries(searchFilters)) {
        if (!key.includes("is")  && val !== "" && val !== "Random") updatedFilters.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${val}`)
        setAppliedFilters(updatedFilters)
      }
    sendRequest(length)
    }, [downloads, searchFilters])

  useEffect(()=> {
    if(firstLoad)
      setFirstLoad(false)
    else
      fetchVocals(0)
  }, [firstLoad, searchFilters])

  const clearFilterData = useCallback(async () => {
    setSearchFilters(filterInitialValues)
  }, [downloads, searchFilters])

  const removeFilter = useCallback(async(key, length) => {

    for (const [k, v] of Object.entries(searchFilters)) {
      if (k.toLowerCase() === key.split(':')[0].toLowerCase() && key.split(':')[1] !== ''){
        searchFilters[k] = ''
         setAppliedFilters(appliedFilters.filter(prevFilter => prevFilter !== key))
      }
    }
    sendRequest(length)
   }, [appliedFilters, searchFilters])

  return (
      <Box p={0} width="100%">
        <ContentContainer titleLeft="25px" {...props} title="Downloads" titleBackground="linear-gradient(#A64FCF,#333333)" gradient={"larger"}>
          <VocalsTable
            heading={heading}
            download={true}
            heart={false}
            list={downloads}
            downloadCheck={true}
            fetchVocals={fetchVocals}
            setSearchFilters={setSearchFilters}
            searchFilters={searchFilters}
            tracksTotal={tracksTotal}
            appliedFilters={appliedFilters}
            clearFilterData={clearFilterData}
            removeFilter={removeFilter}
          />
        </ContentContainer>
      </Box>
  )
}
