import React, { useEffect } from 'react'
import { StyledContainer } from '../../../_warbls/components/Container/StyledContainer'
import { Box,Typography } from '@material-ui/core'
import MenuTabs from './MenuTabs'
import './CSS/profileMenu.css'
import { tabInfo } from './script'


import { ContentContainer } from "../../../_warbls/components/Container"
import { makeStyles,Button } from "@material-ui/core"
import { StyledGrid } from "../../../_warbls/components/Container/StyledGrid"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/auth/actions"
import { useFormik } from "formik"
import clsx from "clsx"
import { initValues } from "./script"
import cogoToast from "cogo-toast"
import { useHistory } from "react-router"

const { logoutRequest } = actions

const ProfileMenu = (props) => {

    const {activeTabID} = props

    const { userInfo } = useSelector((state) => state.auth);
    const history = useHistory()
    const dispatch = useDispatch()


    const handleLogout = () => {
        dispatch(logoutRequest())
        history.push("/")
    }

    useEffect(() => {
      const tab = document.getElementById(activeTabID);
      tab.classList.add('active-tab')
    }, [])



    

    


  return (
    
    <div className='profile-menu-container' >
        

            <Typography variant='p' 
                        style={{
                            fontWeight:'bold',
                            fontSize:'1.05rem',
                        }}
                        >
                Welcome to your Profile
            </Typography>

            <Box className='image_info'>
                <Typography variant='p' 
                        style={{
                            fontWeight:'bold',
                            fontSize:'1.05rem',
                        }}
                        >
                    {userInfo.full_name}
                </Typography>
                <Box className="profile-image">
                    <img src="/media/tempProfileImage.png" alt="profile image" />

                </Box>
                
            </Box>
            <Box className='Menu-tabs'>
                {
                    tabInfo.map((tab,key) =>(  
                        <MenuTabs
                            // setTab={setTab}
                            ID={tab.id}
                            heading={tab.tabName}
                            icon={tab.icon}
                            path={tab.path}
                        />
                    ))
                }
                <MenuTabs
                            ID='tab5'
                            heading='Logout'
                            icon='media/logout.png'
                            logoutHandler={handleLogout}
                />
            </Box>

        

    </div>


  )
}

export default ProfileMenu




