export const steps_detail =[
    {
        heading:'Invite friends',
        detail:'Refer friends to Warbls through email or your personal referral link.'
    },
    {
        heading:'They get a discount',
        detail:`When your referrals subscribe to Warbls, they'll get 20% off their first month.`
    },
    {
        heading:'You get the coins',
        detail:`Once they complete their order, you'll get 25 coins.`
    }
]