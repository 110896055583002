import React, { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import PlanCard from "./PlanCard"
import { plan_card_info } from "./script"
import './CSS/index.css'

const CardPrompt = (props) => {
    const {setPrompt,cardID} = props
    const info = plan_card_info;
    const open_card = info.find(item => item.id === cardID);
    
    

  return (
    <div className="card-prompt-stickey">
        <div className="card-prompt">
          <div className="card-prompt-cross">
              <CloseIcon
                onClick={() => { setPrompt(false) }}
                style={{
                  cursor:'pointer'
                }}
              />
          </div>
          <div className="card-prompt-card">
            <PlanCard
                        ID={open_card.id}
                        coins={open_card.coins}
                        price={open_card.price}
                    />
          </div>
          <div className="card-prompt-btn-container">
            <button style={{color:'#FF5757'}} className="card-prompt-btn">
              Decline
            </button>
            <button style={{
              color:'#86DB78'
            }} className="card-prompt-btn">
              Accept
            </button>
          </div>

        </div>

      </div>
  )
}

export default CardPrompt