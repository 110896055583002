import React, { useState, useContext, useEffect, useCallback, useLayoutEffect, useMemo } from "react"
import { Box, makeStyles, Typography, Button } from "@material-ui/core"
import axios from "axios"
import InfiniteScroll from "react-infinite-scroll-component";
import PerfectScrollbar from "react-perfect-scrollbar"
import { useSelector, useDispatch } from "react-redux"
import fileDownload from "js-file-download"
import { AiOutlineClockCircle } from "react-icons/ai"
import {  Oval } from 'react-loader-spinner'
import { genres } from "./script"
import { APIs } from "../../../_helpers/apis"

import { VocalRow } from "../../components/VocalRow"
import { actions } from "../../../redux/track/actions"
import { AppContext } from "../../providers/AppProvider"
import { SearchFilters } from "./searchFilters";
import cogoToast from "cogo-toast"
import { SongContext } from "../SideBarInfo";
import { Popup } from "../Popup";
import SubDialogue from "./SubDialogue";


export const VocalsTable = ({
  heading,
  download,
  heart,
  list,
  downloadCheck,
  fetchVocals,
  setSearchFilters,
  searchFilters,
  tracksTotal,
  appliedFilters,
  clearFilterData,
  removeFilter,
  trackLoading = false
}) => {
  const [size, setSize] = useState([0, 0])
  const { table, overflow, rootBox, vocalWrapper, tracksBtn } = useStyles()
  var { likedTracks, dowloadedTracks, loading ,allTracks} = useSelector((state) => state.track)
  const { handleLogin, handleCart,updateSubscriptionCoins } = useContext(AppContext)
  const { auth, userInfo } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [container, setContainer] = useState("")
  const [current, setCurrent] = useState("")
  const [hasMore, setHasMore] = useState(true)
  const [filters, setFilters] = useState([])
  const [filteredTracks, setFilteredTracks] = useState([])
  const [preFilteredTracks, setPreFilteredTrack] = useState([])
  const [filteredGenres, setFilteredGenres] = useState([])
  const [openDialogue, setOpenDialogue] = useState(false);

  const {userSubscription} = useSelector( state => state.subscriptions)

  const mysongcontext = useContext(SongContext)
  let id_list= [];





  // setting tables headings for mobile screen
  if (size[0] < 1280) {
    heading = ["Play", "", ""]
  }
  const handleDownloadTrackWithCoins = useCallback(async (track_id) => {
    axios
      .post(APIs.trackOrder, {
        user_id: userInfo.user_id,
        track_id
      })
      .then((res) => {
        if(res.data){
          updateSubscriptionCoins()
        cogoToast.info("Successfully Added For Download")

        }
      }) .catch(error => {
        // Handle errors
        cogoToast.error(error)
        console.error('Error with subs:', error);
      })
  }, [])
  useEffect(() => {
    dispatch(actions.activeVocal(container))
    
  },[container])
  useEffect(() => {
    let track_res = list
    if (heart)
      track_res = likedTracks
    else if (download)
      track_res = dowloadedTracks

    if (filters.trending === "Standard") track_res = shuffleVocals(track_res)
    setFilteredTracks(track_res)
    setPreFilteredTrack(track_res)
  }, [list, likedTracks, dowloadedTracks, heart, download, filters.trending])

  const shuffleVocals = (array) => {
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
   }


  const handleDownload = useCallback(async (url, track_name) => {
    axios
      .get(url, {
        responseType: "blob"
      })
      .then((res) => {
        fileDownload(res.data, track_name + ".wav")
      })
  }, [])

  const handleLike = useCallback(
    (trackID) => {
      const payload = {
        object_type: "user",
        object_id: userInfo.user_id,
        added_by: userInfo.user_id
      }

      if (auth) {
        const data = {
          action: "like",
          action_against_id: trackID,
          ...payload
        }
        dispatch(actions.trackWiseLikeRequest(data))
      } else {
        handleLogin()
      }
    },
    [auth, dispatch, handleLogin, userInfo.user_id]
  )

  const handleUnlike = (trackID) => {
    if (auth) {
      const data = {
        action: "like",
        object_id: userInfo.user_id,
        log: JSON.stringify({ log_added_by: userInfo.user_id })
      }

      dispatch(actions.trackWiseUnLikeRequest(data, trackID))
    } else {
      handleLogin()
    }
  }

  // listning for width to switch filters fot mobile screen and laptop
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

   let tracksData = useMemo(() => {
    if (auth)
      return list?.length > 0 ?
       list : []
    else
      return list?.length > 0 ?
        list.slice(0, 20) : []
  }, [auth, list])

  useEffect( () => {
    if ( !tracksTotal  || (auth && tracksData.length > 0 && list.length === tracksTotal) || (!auth && tracksData.length === 20) ){
      setHasMore(false)
    }else setHasMore(true)
  }, [auth, hasMore, list.length, tracksData, tracksTotal])

  useEffect(() => {
    const updateFilteredGenres = (filter_genres) => {
      const filteredGenres = genres
        .filter((genre) => filter_genres.includes(genre.value))
        .slice();

      const updatedGenres = [
        genres.find((genre) => genre.label === 'Genres'),
        ...filteredGenres
      ];

      setFilteredGenres(updatedGenres);
    };

    if(!auth) {
       axios.get(APIs.getTracksGenre)
      .then((res) => {
        updateFilteredGenres(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      const distinctGenresWithActiveTrack = [...new Set(allTracks.filter(track => track.status === "active").map(track => track.genre))]
      updateFilteredGenres(distinctGenresWithActiveTrack);
    }
  }, [allTracks, auth, list])

  //getting track id of the songs
  

  
  
  
  useEffect(() => {

      if(auth){

        
        id_list = tracksData.map((track) => { return(track.track_id) })
        mysongcontext.setSongIds(id_list) 
       
        dispatch(actions.getAllPendingTracksSuccess(tracksData)) 
      }    

    
    
    
    // mysongcontext.setTrackId(id_list);
    // console.log("set the id",mysongcontext.trackIds);
  
  }, [tracksData])

  const handleSubDialogue = () => { setOpenDialogue(!openDialogue) }
  


  return (
    <div className={vocalWrapper}>

      <SearchFilters
      setSearchFilters={setSearchFilters}
      searchFilters={searchFilters}
      appliedFilters={appliedFilters}
      clearFilterData={clearFilterData}
      removeFilter={removeFilter}
      filteredGenres={filteredGenres}
      />

      <Box className={rootBox} width="100%">
        <Box className={overflow} style={{ marginTop: size[0] > 1280 ? "-40px" : "-30px"}}>
          <PerfectScrollbar style={{ minHeight: '100vh'}}>
            <br/>
            <InfiniteScroll style={{overflowX: "hidden"}}
              dataLength={tracksData.length}
              next={()=>{
                fetchVocals(tracksData.length)
              }}
              hasMore={hasMore}
              loader={''
              //   <Oval
              //   height={30}
              //   width={30}
              //   color="#ffff"
              //   wrapperStyle={{marginLeft: 500}}
              //   wrapperClass=""
              //   visible={true}
              //   ariaLabel='oval-loading'
              //   secondaryColor="#ffff"
              //   strokeWidth={5}
              //   strokeWidthSecondary={5}
              // />

              }
              >
                 
              <table className={table}>
                <thead>
                  <tr>
                    {heading.map((head, index) =>
                      index === 4 ? (
                        <th>
                          <Typography
                            variant="body2"
                            key={index}
                            style={{
                              width: index === 0 && 50,
                              display: "block",
                              margin: "5px 0px 0px 0px",
                              fontSize: "20px",
                            }}
                          >
                            <AiOutlineClockCircle />
                          </Typography>
                        </th>
                      ) : (
                        <th>
                          <Typography variant="body2" key={index} style={{ width: index === 0 && 50 , textAlign: index===0 ? 'left' : "center", paddingLeft: index ===0 ? '10px' : '0', marginLeft:index === 0 && '10px'} }>
                            {head}
                          </Typography>
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  <br />
                    {
                      tracksData.map((track, index) => 
                          { 
                            // id_list.push(track.track_id)
                            return(
                              <VocalRow
                              key={index}
                              index={index}
                              setContainer={setContainer}
                              setCurrent={setCurrent}
                              container={container}
                              current={current}
                              downloadCheck={downloadCheck}
                              rowList={track}
                              download={download}
                              heart={heart}
                              handleDownload={handleDownload}
                              handleLike={handleLike}
                              handleUnlike={handleUnlike}
                              handleCart={handleCart}
                              handleDownloadTrackWithCoins={handleDownloadTrackWithCoins}
                              userSubscription={userSubscription}
                              handleDialogue={handleSubDialogue}
                              />
                              )
                            }
                      )
                    }
                </tbody>
              </table>
              {(tracksData.length < 1 && !trackLoading) && <div style={{textAlign:'center'}}>No record found</div>}
            </InfiniteScroll>
            {hasMore && (<section>
              <ul id="downArrow">
                  <li style={{i:1}}></li>
                  <li style={{i:2}}></li>
                  <li style={{i:3}}></li>
              </ul>
            </section>)}
            
            { !auth && tracksData.length === 20 && (
            <Button
            className={tracksBtn}
            onClick={() => handleLogin()}
            >
            { <Typography>Load More </Typography>}
            </Button> )}
          </PerfectScrollbar>
        </Box>
        <Popup open={openDialogue} handleClose={handleSubDialogue}>
              <SubDialogue handleClose={handleSubDialogue}/>
        </Popup>
        
      </Box>
    </div>
  )}

const useStyles = makeStyles((theme) => ({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    overflow: "hidden",
    "& thead tr th": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      width: 50
    },
    "@media (max-width:800px)": {
      width: "97%",
      oveflow: "scroll",
      maxWidth: "730px",
      margin: "0 auto"
    },
    "@media (max-width:600px)": {
      oveflow: "scroll",
      maxWidth: "530px"
    },
    "@media (max-width:400px)": {
      oveflow: "scroll",
      maxWidth: "330px"
    }
  },
  vocalWrapper: {
    margin: "0 50px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "@media (max-width:1000px)": {
      margin: "0 0px"
    }
  },

  filterBox: {
    width: "100%",

    "@media screen and (max-width: 1300px)": {
      marginTop: "-80px"
    },

    position: "relative",
    marginBottom: 60
  },
  overflow: {
    width: "inherit",
    overflow: "hidden",
    paddingRight: 5
  },
  rootBox: {
    padding: "0px 32px 32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px  0px 0px"
    },
    "@media (min-width: 1280px)": {
      maxWidth: "auto"
    }
  },
  tracksBtn: {
    "&:hover": {
      backgroundColor: "white"
    },
    marginLeft: 500,
    marginTop: 35,
    padding: 12,
    "@media (max-width: 540px)" : {
      marginLeft: 110
    },
    "@media (min-width: 700px) and (max-width: 850px)" : {
      marginLeft: 290
    }
  },
  btnColor: {
    color: "white !important"
  }
}))
