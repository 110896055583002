export const moreCoinsInfo=[
    {
        coins:'50',
        price:'6.00'
    },
    {
        coins:'100',
        price:'12.00'
    },
    {
        coins:'200',
        price:'20.00'
    },
    {
        coins:'400',
        price:'35.00'
    },
]