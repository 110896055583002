import React from 'react'
import './CSS/customerSay.css'
import { CircularProgress } from '@material-ui/core'

const CustomerSay = (props) => {
   const {image_url,name,email,outer_message,inner_message,wave_img} = props

  return (
    <div className='say_container'>
        <div className='image'>
            <img src={image_url} alt="this is image" />
        </div>
        <div className='containt'>
            <h1>{name}</h1>
            <div className='mail'>
                {email}
            </div>
            <div className="comment">
                <i>
                  "{outer_message}"
                </i>
            </div>
           

        </div>
    </div>
  )
}

export default CustomerSay