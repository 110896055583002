import { useState, useCallback, useMemo } from 'react'
import { Typography, Button, makeStyles, Box } from "@material-ui/core"
import { useFormik } from "formik"
import * as React from "react"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { StyledGrid } from "../../../_warbls/components/Container/StyledGrid"
import { Row } from "../../../_warbls/components/Flex/Row"
import { Column } from "../../../_warbls/components/Flex/Column"
import { InputField } from "../../../_warbls/components/Form/Input"
import { formScript, initValues, schema } from "./script"
import { ThanksVocalRow } from "../../../_warbls/components/VocalRow/ThanksVocalRow"
import { useSelector } from "react-redux"
import { POST } from "../../../_helpers/fetcher"
import { APIs } from "../../../_helpers/apis"
import cogoToast from "cogo-toast"
import axios from 'axios'
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import CardData from "./CardData"
// import * as Yup from "yup"
import PayPalButton from "./PayPalButton";

export const Checkout = (props) => {
  const { handleNext, prevCardInfo } = props
  const { labelClass, inputClass, labelCardClass, labelEmailClass, head, submitBtn, InputRoot, vocalRowsWrapper, priceWrapper, paymentForm } = useStyles()
  const { cart } = useSelector((state) => state.track)
  const { userInfo } = useSelector((state) => state.auth)
  const [IsSubmitting, setIsSubmitting] = useState(false)
  // const [radioCard, setRadioCard] = useState(prevCardInfo !== undefined ? "Existing" : "New");
  const cart_sum = useMemo(() => {
    return cart.reduce( (r, a) => {
        return r + parseFloat(a["price"])
      }, 0)
    .toFixed(2)
  }, [cart])

  const submitPayment = useCallback(async (values) => {
    let body = {
      ...values,
      // useExisitingCard: prevCardInfo?.id !== undefined && radioCard === "Existing",
      month: parseInt(values?.year.split("/")[0]),
      year: parseInt(values?.year.split("/")[1] ),
      name: values?.name,
      amount: cart_sum * 100,
      user_id: userInfo.user_id,
      track_ids: cart.map((obj) => obj.track_id)
    }

    setIsSubmitting(true)
    let res = await POST(APIs.checkout, body)

    if (!res?.error !== false) {
      handleNext()
    }else {
      console.log(res?.error?.message)
      cogoToast.error("Card expiration date is invalid")
    }
    setIsSubmitting(false)
  } ,[cart, cart_sum, handleNext, prevCardInfo?.id, userInfo.user_id])

    // const  handleRadioInput = (event) => {
    //   if (event.target.value === radioCard) {
    //     setRadioCard("");
    //   } else {
    //     setRadioCard(event.target.value);
    //   }
    // }

  const formik = useFormik({
    initialValues: initValues,
    // validationSchema: radioCard === "Existing" ? Yup.object().shape({}) : schema,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      if (IsSubmitting) return

      const updatedValues = {
        ...values,
        number: values.creditCard,
      };

      delete updatedValues.creditCard;

      submitPayment(updatedValues);

      //dispatch(actions.signupRequest(values, handleSignup, resetForm))
      // if (!loading) {
      //   handleSignup()
      //   resetForm()
      // }
    }
  })

  const renderNewCard = () => {
    return formScript.slice(1, 7).map((field, index) => (
      <StyledGrid item xs={field.xs} md={field.md} key={index} className={InputRoot} style={{padding: '0px'}}>
        <InputField
          placeholder={field.placeholder}
          name={field.name}
          type={field.type}
          formik={formik}
          label={field.label}
          style={{ ...field.style }}
          classes={inputClass}
          labelClass={field.label === "Card information" ? labelCardClass : labelClass}
          isLightTheme={true}
          inputImage={field.inputImage}
          inputCvcImage={field.inputCvcImage}
          value={field.type === "email" && userInfo.email}
          autoComplete={field.name === "creditCard" && "cc-number"}
        />
      </StyledGrid>
    ))
  }

  const handleCreateOrder = async (data, actions) => {
    try {
      const response = await axios.post(APIs.paypalCreateOrder, {
        user_id: userInfo.user_id,
        track_ids: cart.map((obj) => obj.track_id)
      });

      return response.data.orderID;
    } catch (error) {
      console.error("Error creating PayPal order:", error);
    }
  }

  const handleApprove = async (data, actions) => {
    try {
      await axios.post(APIs.paypalCaptureOrder, {
        orderID: data.orderID,
        user_id: userInfo.user_id,
        track_ids: cart.map((obj) => obj.track_id)
      });
      handleNext()
    } catch (error) {
      console.error("Error capturing PayPal order:", error);
    }
  };

  return (
    <>
      <StyledContainer p={0} height="70vh" style={{ overflowX: "hidden !important", maxWidth: "100vw" }} id="doc-element">
        <StyledGrid container spacing={3}>
          <StyledGrid item xs={12} md={6} className={paymentForm}>
            {/* <div style={{paddingRight: "5px"}}>
              <PayPalButton
                amount={cart_sum}
                currency="USD"
                createOrder={handleCreateOrder}
                onApprove={handleApprove}
              />
            </div>
            <div style={{margin: '25px 0 25px 0'}}>
              <div className="hr-text-container">
                <hr className="hr-text" />
                <span className="hr-text-span">Or pay with card</span>
                <hr className="hr-text" />
              </div>
            </div> */}
            <Box display="flex" width="100%" justifyContent="space-between" flexWrap="wrap">
              <Typography variant="h5" className={head}>
                Billing Information
              </Typography>
              <div style={{width: '135px'}}><img src="media/poweredStripe.svg" height="35px" width="160px" alt="stripe" /></div>
            </Box>
            {/* <Row mb={3}>
              <Row>
                <span style={{ marginTop: "20%" }}>
                  <input type="radio" name="paymentMethod" checked={true} />
                </span>
                <img src="media/card.png" alt="" className={cardStyle} />
              </Row>
            </Row> */}

            <StyledGrid container width="100%">

              {formScript.slice(0, 1).map((field, index) => (
                <StyledGrid item xs={field.xs} md={field.md} key={index} className={InputRoot}>
                  <InputField
                    placeholder={field.placeholder}
                    name={field.name}
                    type={field.type}
                    formik={formik}
                    label={field.label}
                    style={{ ...field.style }}
                    classes={`${inputClass} inputEmailCheckout`}
                    labelClass={field.type === "email" ? labelEmailClass: labelClass}
                    isLightTheme={true}
                    inputImage={field.inputImage}
                    value={field.type === "email" && userInfo.email}
                  />
                </StyledGrid>
              ))}
              {/*
                prevCardInfo &&
                  <div>
                    <FormControl id="selectCard">
                      <RadioGroup
                        aria-labelledby="selectCard"
                        name="selectCard"
                        value={radioCard}
                        onChange={handleRadioInput}
                        row
                      >
                        <FormControlLabel
                          control={<Radio onChange={handleRadioInput} />}
                          label="Choose Existing Card"
                          value="Existing"
                        />
                        <FormControlLabel
                          control={<Radio onChange={handleRadioInput} />}
                          label="Add new Card"
                          value="New"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
              */}
              {/*
                radioCard === "New" ?
                  renderNewCard() :
                  <CardData cardInfo={prevCardInfo} submitPayment={submitPayment} />
              */}
              {renderNewCard()}

              <StyledGrid container mx={0} my={1}>
                <Button className={submitBtn} onClick={formik.handleSubmit} >
                  {!IsSubmitting ? "Pay" : "Please wait ..."}
                </Button>
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>

          <StyledGrid item xs={12} md={6} className={priceWrapper}>
            <Row jc="center">
              <Typography variant="h6">Total Price</Typography>
            </Row>
            <Row jc="center">
              <Typography variant="h3">${cart_sum}</Typography>
            </Row>
            <Column
              // border={1}
              //borderColor="rgba(0, 0, 0, 0.1)"

              py={0}
              px={3}
              ai="center"
            >
              <Row my={2} className={vocalRowsWrapper} width="30%">
                <ThanksVocalRow tracks={cart} />
                {/* <table>
                  <tbody>
                    {cart.map((l, index) => (
                      <ThanksVocalRow rowList={cart} key={index} width="500px" />
                    ))}
                  </tbody>
                </table> */}
              </Row>
            </Column>
          </StyledGrid>
        </StyledGrid>
      </StyledContainer>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    width: 180,
    height: 100,
    margin: "0px 2px",
    borderRadius: 3
  },
  paymentForm: {
    padding: "12px 98px!important",
    "@media (max-width:800px)": {
      padding: '12px!important'
    }
  },
  inputClass: {
    backgroundColor: "transparent",
    border: "1px solid lightgray",
    // borderRadius: 4,
    width: "100%",
    "@media(max-width:500px)": {}
  },
  labelClass: {
    fontWeight: "bold",
    marginLeft: 0,
  },
  labelCardClass: {
    fontWeight: "bold",
    marginLeft: 0,
    marginTop: 20,
    marginBottom: 5,
  },
  labelEmailClass: {
    position: 'relative',
    top: 31,
    color: 'rgb(0, 0, 0)',
    zIndex: 1,
    paddingLeft: 10,
  },
  head: {
    fontWeight: "bold",
    // marginBottom: "1.5rem"
  },
  subHead: {
    fontWeight: "bold",
    margin: "15px 0px 10px 0px"
  },
  submitBtn: {
    backgroundColor: theme.palette.btns.pay,
    color: theme.palette.primary.contrastText,
    padding: "10px 25px",
    borderRadius: 5,
    marginTop: 20,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
    textTransform: 'Capitalize',
    fontSize: '20px',
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.primary.contrastText
    },
  },
  total: {
    textDecoration: "underline"
  },
  mul: {
    fontSize: 16
  },
  InputRoot: {
    "& .MuiBox-root": {
      margin: "0px 0px"
    }
  },
  vocalRowsWrapper: {
    minWidth: "450px",
    "@media (max-width:450px)": {
      minWidth: "350px"
    }
  },
  priceWrapper: {
    marginTop: 14,
    "@media (max-width:800px)": {
      minWidth: 6
    }
  },
  headerWrapper: {
    margin: '25px 0 25px 0'
  }
}))
