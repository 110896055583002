/** CREATE PROMO CODES TYPES */
export const CREATE_PROMO_CODE_REQUEST = "CREATE_PROMO_CODE_REQUEST"
export const CREATE_PROMO_CODE_SUCCESS = "CREATE_PROMO_CODE_SUCCESS"
export const CREATE_PROMO_CODE_FAILED = "CREATE_PROMO_CODE_FAILED"

/** GET PROMO CODES TYPES */
export const GET_PROMO_CODE_REQUEST = "GET_PROMO_CODE_REQUEST"
export const GET_PROMO_CODE_SUCCESS = "GET_PROMO_CODE_SUCCESS"
export const GET_PROMO_CODE_FAILED = "GET_PROMO_CODE_FAILED"

/** UPDATE PROMO CODES TYPES */
export const UPDATE_PROMO_CODE_REQUEST = "UPDATE_PROMO_CODE_REQUEST"
export const UPDATE_PROMO_CODE_SUCCESS = "UPDATE_PROMO_CODE_SUCCESS"
export const UPDATE_PROMO_CODE_FAILED = "UPDATE_PROMO_CODE_FAILED"
