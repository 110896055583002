import React, { useState } from 'react'
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Typography } from '@material-ui/core';



const UpgradePlan = (props) => {
    
    const {handleClose,date} = props
    const [immediateTimeframe, setImmediateTimeframe] = useState(true)
    
   
  return (
    <div style={{backgroundColor:'#202B38',borderRadius:'5px',padding:'20px',display:'flex',flexDirection:'column',gap:'10px'}}>
        <div>
        <CloseRoundedIcon style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={handleClose}/>
      </div>
      <div >
          <Typography style={{fontWeight:'bolder'}} variant='h5'>Upgrade Plan</Typography>
      </div>
      <div>
              <Typography style={{fontWeight:'bolder',color:'#57A4FF'}} variant='h6'>Choose your timeframe</Typography>
    </div>
    <div onClick={() => { setImmediateTimeframe(true) }}
         style={{ display:"flex",alignItems:'center',gap:'10px',backgroundColor:'#3D4756',border: immediateTimeframe && "2px solid #57A4FF",borderRadius:'5px' , padding:'10px' ,cursor:'pointer'}}>
        <div 
             style={{display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'50%',backgroundColor: !immediateTimeframe && "#fff", width:'20px',height:'20px'}} >
            {
                immediateTimeframe && (<CheckCircleRoundedIcon style={{color:'#57A4FF',}}/>)
            }

        </div>
        <div>
            <Typography variant='p'>Immediately with proration</Typography>
        </div>
    </div>
    <div onClick={() => { setImmediateTimeframe(false) }}
         style={{display:'flex',alignItems:'center',gap:'10px',backgroundColor:'#3D4756',border: !immediateTimeframe && "2px solid #57A4FF",borderRadius:'5px', padding:'10px',cursor:"pointer"}}>
    <div  
        style={{display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'50%',backgroundColor: immediateTimeframe && "#fff", width:'20px',height:'20px'}} >
            {
                !immediateTimeframe && (<CheckCircleRoundedIcon style={{color:'#57A4FF'}}/>)
            }

        </div>
        <div>
            <Typography variant='p'>At the end of current billing period, {date}</Typography>

        </div>
    </div>
    <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',gap:'20px'}}>
        <div onClick={handleClose}
           style={{display:'flex',cursor:'pointer'}}>
          <Typography variant='p'>Back</Typography>
          
        </div>
        <div onClick={() => { 
    
        }}
           style={{cursor:'pointer',backgroundColor:'#57A4FF',borderRadius:'5px',padding:'10px 20px'}}>

          <Typography variant='p' style={{fontWeight:'bolder'}}>Continue</Typography>
        </div>

      </div>
        
    </div>
  )
}

export default UpgradePlan