import React from "react";
import { useHistory } from "react-router";
import axios from "axios"
import { APIs } from "../../../_helpers/apis"
import { Signup } from "../Signup/index"
import { useFormik } from "formik"
import { initValues } from "../Signup/script";
import { schema } from "../Signup/schema";
import { actions } from "../../../redux/auth/actions"
import { useDispatch, useSelector } from "react-redux"
import { AppContext } from "../../../_warbls/providers/AppProvider"
const { useContext } = React

export const DiscordRedirect = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    // const [errors, setErrors] = React.useState(null);
    // const { openLogin, handleLogin, openSignup, handleSignup, toolbar } = useContext(AppContext)
    // const formik = useFormik({
    //   initialValues: initValues,
    //   validationSchema: schema,
    //   onSubmit: (values, { resetForm }) => {
    //     console.log({values})
    //     dispatch(actions.signupRequest({ ...values }, handleSignup, resetForm, setErrors))
    //     // if (!loading) {
    //     //   handleSignup()
    //     //   resetForm()
    //     // }
    //   }
    // })
    // const submitDiscord = (discordData) => {
    //   formik.setValues(({
    //     full_name: discordData.global_name,
    //     social_login: true,
    //     social_id: discordData.id,
    //     email: discordData.email,
    //     profile_img: discordData.avatar,
    //     username: discordData.username
    //   }), false);
    //   formik.handleSubmit();
    // }
    React.useEffect(() => {
      const fetchUserData = async (code) => {
        try {
          const response = await axios.get(`${APIs.discordAuth}?code=${code}`);
          const discordData = response.data
          const values = {
            full_name: discordData.global_name,
            social_login: true,
            social_id: discordData.id,
            email: discordData.email,
            profile_img: discordData.avatar,
            username: discordData.username,
            password: discordData.id,
            social_platform: 'DISCORD'
          }
          dispatch(actions.signupRequest({ ...values },()=>{},()=>{},()=>{}))

          // submitDiscord(discordData)
          // You can save the user data in your state management (e.g., Redux) here
          history.push('/vocals'); // Redirect to the homepage or another page after login
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
  
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        fetchUserData(code);
      }
    }, []);
  
    return <div>Loading...</div>;
  };