import * as React from "react"
import { Vocals } from "./modules/Vocals"
import  Plan  from "./modules/Plan"
import { Downloads } from "./modules/Downloads"
import { Likes } from "./modules/Likes"
import { UploadForm } from "./modules/UploadForm"
import { TermsAddStepper } from "./modules/Terms"
import { TrackUploadSuccess } from "./modules/Terms/success"
import { Profile } from "./modules/Profile"
import { Dashboard } from "./modules/Dashboard"
import { Analytics } from "./modules/Analytics"
import { ViewCart } from "./modules/Cart"
import { CheckoutPage } from "./modules/Checkout"
import { UsersArtists } from "./modules/UserAndArtists"
import { Files } from "./modules/Files"
import { Orders } from "./modules/Order/index"
import { MyVocals } from "./modules/MyVocals"
import { ArtistPublicProfile } from "./modules/Artist/PublicProfile"
import { Artist } from "./modules/Artist"
import { Route, Redirect, Switch } from "react-router-dom"
import { ArtistFormTable } from "./modules/ArtistsFormTable"
import { ContactUs } from "./modules/ContactUs"
import { QA } from "./modules/QA"
import { TermsOfUse } from "./modules/Terms/UpdatedTerms"
import { ReferFriend } from "./modules/ReferFriend"
import { Affiliate } from "./modules/Affiliate"
import { TempVocals } from "./modules/TempVocals"
import CopylinkVocal from "./modules/CopylinkVocal"
import ManageSubscription from "./modules/ManageSubscription"


export const BaseRoutes = () => {
  return (
    <Switch>
      <Redirect exact to="/vocals" from="/" />
      <Route path="/vocals" component={Vocals} />
      <Route path="/temp-vocals" component={TempVocals} />
      <Route path="/plans" component={Plan} />
      <Route path="/manage-subscription" component={ManageSubscription} />
      <Route path="/shareVocal/:id" component={CopylinkVocal} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/q&a" component={QA} />
      <Route path="/downloads" component={Downloads} />
      <Route path="/likes" component={Likes} />
      <Route path="/upload-form/:message" exact component={UploadForm} />
      <Route path="/upload-form" component={UploadForm} />
      <Route path="/terms" component={TermsAddStepper} />
      <Route path="/upload-track/success" component={TrackUploadSuccess} />
      <Route path="/upload-track" component={TermsAddStepper} />
      <Route path="/profile" component={Profile} />
      <Route path="/refer-friend" component={ReferFriend} />
      <Route path="/affiliate" component={Affiliate} />
      <Route path="/termsOfUse" component={TermsOfUse} exact />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/analytics" component={Analytics} />
      <Route path="/cart" component={ViewCart} />
      <Route path="/checkout" component={CheckoutPage} />
      <Route path="/users" component={UsersArtists} />
      <Route path="/files" component={Files} />
      <Route path="/orders" component={Orders} />
      <Route path="/my-vocals" component={MyVocals} />
      <Route path="/artist/:id" component={ArtistPublicProfile} />
      <Route path="/artist" component={Artist} />
      <Route path="/artist-requests" component={ArtistFormTable} />
    </Switch>
  )
}
