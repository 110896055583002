import * as React from "react"
import { useState, useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { Box } from "@material-ui/core"
import { VocalsTable } from "../../../_warbls/components/Vocals"
import { ContentContainer } from "../../../_warbls/components/Container"
import { heading } from "./script"
import { APIs } from "../../../_helpers/apis"
import axios from "axios"
import { filterInitialValues } from "../../../_warbls/components/Vocals/script"
import { bgcolor } from "@material-ui/system"

export const Likes = (props) => {
const { userInfo } = useSelector((state) => state.auth)

const [likedTracks, setLikedTracks] = useState([])
const [firstLoad, setFirstLoad] = useState(true)
const [tracksTotal, setTracksTotal] = useState(0)
const [searchFilters, setSearchFilters] = useState(filterInitialValues)
const [appliedFilters, setAppliedFilters] = useState([])


const sendRequest = useCallback(async (length) => {

  const shuffleVocals = (array) => {
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
   }

  let newTracks = []
  if (searchFilters.trending === 'Random') searchFilters.trending = ''
  axios.get(`${APIs.tracks}?load=user_details&offset=${length || likedTracks.length}&limit=5&object_type=user&action=like&object_id=${userInfo.user_id}&searchFilters=${encodeURIComponent(JSON.stringify(searchFilters))}` ).then((res) => {
      setTracksTotal(res?.data?.total)
      if (searchFilters.trending === '') newTracks = res.data?.results ? shuffleVocals(res.data.results) : []
      else{
        newTracks = res.data?.results ? res.data?.results?.filter((track) =>
        track?.status === "active"
        ) : []
      }
    if(length === 0)
      setLikedTracks(newTracks)

    else
      setLikedTracks([...likedTracks, ...newTracks])
  })
},[likedTracks, searchFilters, userInfo.user_id] )

  const fetchVocals = useCallback(async (length) => {
  const updatedFilters = []

    for (const [key, val] of Object.entries(searchFilters)) {
      if (!key.includes("is")  && val !== "" && val !== "Random") updatedFilters.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${val}`)
      setAppliedFilters(updatedFilters)
    }
   sendRequest(length)
 }, [likedTracks, searchFilters])

  useEffect(()=> {
    if(firstLoad)
      setFirstLoad(false)
    else
      fetchVocals(0)
  }, [firstLoad, searchFilters])

  const clearFilterData = useCallback(async () => {
    setSearchFilters(filterInitialValues)
 }, [likedTracks, searchFilters])


  const removeFilter = useCallback(async(key, length) => {

    for (const [k, v] of Object.entries(searchFilters)) {
      if (k.toLowerCase() === key.split(':')[0].toLowerCase() && key.split(':')[1] !== ''){
        searchFilters[k] = ''
         setAppliedFilters(appliedFilters.filter(prevFilter => prevFilter !== key))
      }
    }
    sendRequest(length)
   }, [appliedFilters, searchFilters])

  return (
    <div >
    <Box p={0} width="100%">
      <ContentContainer titleLeft="25px" {...props} title="Liked vocals" titleBackground = "linear-gradient(#4DAAC7,#333333)" gradient={"larger"}>
        <VocalsTable
          heading={heading}
          download={false}
          heart={true}
          list={likedTracks}
          downloadCheck={false}
          fetchVocals={fetchVocals}
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
          tracksTotal={tracksTotal}
          appliedFilters={appliedFilters}
          clearFilterData={clearFilterData}
          removeFilter={removeFilter}
          />
      </ContentContainer>
    </Box>
  </div>
  )
}
