export const heading = ["#", "Name", "Key", "BPM", "Time" , "Waveform", "",""]

export const list = [
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound1.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound2.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound3.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound4.m4a"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound5.m4a"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound6.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound1.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound2.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound3.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: "",
    download: "",
    src: "/tracks/sound4.m4a"
  }
]
