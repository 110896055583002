import React, { useState, useCallback, useEffect } from "react"
import { Box } from "@material-ui/core"
import { VocalsTable } from "../../../_warbls/components/Vocals"
import { ContentContainer } from "../../../_warbls/components/Container"
import { heading } from "./script"
import { APIs } from "../../../_helpers/apis"
import axios from "axios"
import { filterInitialValues } from "../../../_warbls/components/Vocals/script"
import { useSelector, useDispatch } from "react-redux"
import { DialoguePopup } from "../ManageSubscription/Dialoagus/DialoguePopup"
import { actions } from "../../../redux/track/actions"
import CoinSucceed from "../../../_warbls/components/Vocals/CoinSucceed"

export const Vocals = (props) => {

  const [allTracks, setAllTracks] = useState([])
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [tracksTotal, setTracksTotal] = useState(0)
  const [searchFilters, setSearchFilters] = useState(filterInitialValues)
  const [appliedFilters, setAppliedFilters] = useState([])
  const { userInfo } = useSelector((state) => state.auth)
  const [openCoinSuccess, setopenCoinSuccess] = useState(false)
  const dispatch = useDispatch();
  
  
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const paymentStatus = params.get('status');
    
  
    
  
  
  

  const query = new URLSearchParams(window.location.search);
  const status = query.get('status')


  const handleCancel = () => { setopenCoinSuccess(!openCoinSuccess) }
  useEffect(() => {
    if(paymentStatus){
      setopenCoinSuccess(true)
    }
  }, [])
  

    const sendRequest =useCallback(async (length) => {
      const params = {
      'load': 'user_details',
      'offset': length || 0,
      'limit': 5,
      searchFilters
      }
      if(userInfo){
        params.object_id = userInfo.user_id;
        params.object_type = 'user'
      }
      let newTracks = []
      if (searchFilters.trending === '') searchFilters.trending = 'Random'
      await axios.get(APIs.tracks, { params: params }).then((res) => {
          setTracksTotal(res?.data?.total)
           newTracks = res.data?.results ? res.data?.results?.filter((track) =>
          track?.status === "active"
        ) : []
        if(length === 0){
          setAllTracks(newTracks)

      }
        else{
          setAllTracks([...allTracks, ...newTracks])
        } 
      setLoading(false)
        
        
      }).catch((e)=>{
        console.log("error here",e)
      })
    }, [allTracks, searchFilters])

  const fetchVocals = useCallback(async (length) => {
  const updatedFilters = []

    for (const [key, val] of Object.entries(searchFilters)) {
      if (!key.includes("is")  && val !== "" && val !== "Random") updatedFilters.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${val}`)
      setAppliedFilters(updatedFilters)
    }
    await sendRequest(length)
 }, [allTracks, searchFilters])

  useEffect(()=> {
    if(firstLoad)
      setFirstLoad(false)
    else
      fetchVocals(0)
  }, [firstLoad, searchFilters])

  const clearFilterData = useCallback(async (length) => {
  setSearchFilters(filterInitialValues)
 }, [])

  const removeFilter = useCallback(async(key, length) => {
    for (const [k, v] of Object.entries(searchFilters)) {
      if (searchFilters.trending === '') searchFilters.trending = "Random"
      if (k.toLowerCase() === key.split(':')[0].toLowerCase() && key.split(':')[1] !== ''){
        searchFilters[k] = ''
        setAppliedFilters(appliedFilters.filter(prevFilter => prevFilter !== key))
      }
    }
    sendRequest(length)
   }, [appliedFilters, searchFilters])

   useEffect(() => {
     
   
   }, [allTracks])
   

  return (
    <Box p={0} width="100%">
      <ContentContainer titleLeft="25px" {...props} title="Vocals" tital_color="linear-gradient(#57A4FF, #333333)" gradient={"larger"}>
        <VocalsTable
          heading={heading}
          download={false}
          heart={false}
          list={allTracks}
          downloadCheck={false}
          fetchVocals={fetchVocals}
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
          tracksTotal={tracksTotal}
          appliedFilters={appliedFilters}
          clearFilterData={clearFilterData}
          removeFilter={removeFilter}
          trackLoading={loading}
        />
      </ContentContainer>
      <DialoguePopup open={openCoinSuccess} handleClose={handleCancel}>
              <CoinSucceed
                handleClose={handleCancel}
              />
      </DialoguePopup>
    </Box>
  )
}
