import * as React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import clsx from "clsx"
 import RangeSlider from "../RangeSlider/index"

const { useState } = React

export const FilesFilter = ({ filters, setFilters, refreshFilters }) => {
  const {
    root,
    typo,
    phraseBox1,
    phrase,
    active,
    refreshBox
  } = useStyles()
  
  return (
    <Box>
      <Box display="flex" justifyContent="center" width="100%" overflow="hidden">
        <Box onClick={refreshFilters}>
          <Typography className={refreshBox}>Reset All</Typography>
        </Box>
      </Box>
      <Box className={root}>
        <Box display="flex" flexDirection="column" width="30%" style={{ rowGap: "10px" }}>
          <Box display="flex" flexDirection="row" style={{ columnGap: "10px" }}>
            <Box
              className={clsx(phraseBox1, phrase, "pointer", {
                [active]: filters.isNewest === "1" && filters.isOldest === "0"
              })}
              onClick={() => {
                setFilters({
                  ...filters,
                  isNewest: "1",
                  isOldest: "0"
                })
              }}
            >
              <Box width="100%" justifyContent="center" display="flex">
                <Typography className={typo}>Newest</Typography>
              </Box>
            </Box>
            <Box
              className={clsx(phraseBox1, phrase, "pointer", {
                [active]: filters.isOldest === "1" && filters.isNewest === "0"
              })}
              onClick={() => {
                setFilters({
                  ...filters,
                  isNewest: "0",
                  isOldest: "1"
                })
              }}
            >
              <Box width="100%" justifyContent="center" display="flex">
                <Typography className={typo}>Oldest</Typography>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" style={{ columnGap: "10px" }}>
            <Box
              className={clsx(phraseBox1, phrase, "pointer", {
                [active]: filters.isMostDownloaded === "1" && filters.isLeastDownloaded === "0"
              })}
              onClick={() => {
                setFilters({
                  ...filters,
                  isMostDownloaded: "1",
                  isLeastDownloaded: "0"
                })
              }}
            >
              <Box width="100%" justifyContent="center" display="flex">
                <Typography className={typo}>Most Downloaded</Typography>
              </Box>
            </Box>
            <Box
              className={clsx(phraseBox1, phrase, "pointer", {
                [active]: filters.isMostDownloaded === "0" && filters.isLeastDownloaded === "1"
              })}
              onClick={() => {
                setFilters({
                  ...filters,
                  isMostDownloaded: "0",
                  isLeastDownloaded: "1"
                })
              }}
            >
              <Box width="100%" justifyContent="center" display="flex">
                <Typography className={typo}>Least Downloaded</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  langs: {
    width: "13.5%",
    padding: "0 3rem",
    "@media screen and (max-width: 1500px)": {
      width: "12%",
      minWidth: "12%"
    }
  },
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    "@media screen and (max-width: 1250px)": {
      flexWrap: "wrap"
    }
    // flexWrap: "wrap"
  },
  filterBox: {
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
    margin: 0,
    marginLeft: "3px",
    "@media screen and (max-width: 1250px)": {
      width: "100%",
      border: "1px solid white",
      columnGap: "1rem"
    }
  },
  search: {
    //  position: "relative",
    borderRadius: 5,
    margin: " 0 4px",
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    border: "1px solid " + theme.palette.primary.contrastText,
    // "&:hover": {
    //   backgroundColor: "#ffffff"
    // },
    display: "flex",
    justifyContent: "space-between",
    // marginRight: 0,
    // marginLeft: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      //marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    // /borderLeft: "1px solid",
    //border: "0px solid " + theme.palette.primary.contrastText,
    // borderTopRightRadius: 5,
    //borderBottomRightRadius: 5,
    [theme.breakpoints.down("md")]: {
      height: "40.63px"
    }
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    padding: "8px 8px",
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "12px 8px"
    }
  },
  phrase: {
    borderRadius: 5,
    display: "flex",
    padding: 2,
    border: "1px solid white",
    alignItems: "center",
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    // border: "1px solid " + theme.palette.primary.contrastText,
    boxSizing: "border-box"
  },
  phraseBox: {
    width: "100%"
  },
  phraseBox1: {
    width: "50%",
    margin: "0"
  },
  typo: {
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,

    // border: "1px solid " + theme.palette.primary.contrastText,
    "&.MuiTypography-body1": {
      fontSize: 12,
      fontWeight: 500,

      backgroundColor: "transparent",
      color: theme.palette.primary.contrastText,
      // border: "1px solid " + theme.palette.primary.contrastText,
      wordSpacing: 5,
      "@media screen and (max-width: 1250px)": {}
    }
  },
  active: {
    boxSizing: "border-box",
    border: "2px solid #86DB78"
  },
  selectStyle: {
    width: "24%",
    border: "0px",
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 5
  },
  refreshBox: {
    backgroundColor: "transparent",
    color: "white",
    padding: "5px 38px",
    textAlign: "center",
    border: "1px solid white",
    borderRadius: "5px",
    margin: "-38px 3px 0 0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    columnGap: "4px"
  },
  select: {
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    border: "1px solid " + theme.palette.primary.contrastText,
    borderRadius: 5,
    margin: "0 3px",
    // border: `1px solid ${theme.palette.secondary.main}`,
    padding: "6px 7px",
    width: "12.6%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "5px 0px",
      padding: "12px 7px"
    }
  }
}))

export const Select = ({ options, value, haddleQueryParams, setHandle, type }) => {
  const { select, selectStyle } = useStyles()
  const [bpmValue, setBpmValue] = useState([0, 100])

  return (
    <>
      {type === "bpm" ? (
        <div style={{ width: "100px" }}>
          <RangeSlider value={bpmValue} setValue={setBpmValue} />
        </div>
      ) : (
        <select
          value={value}
          onChange={({ target }) => setHandle(target.value)}
          className={clsx(select, selectStyle)}
        >
          {options.map((o, index) => (
            <option key={index} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      )}
    </>
  )
}
