import React from 'react'
import { toAbsoluteUrl } from '../../../_helpers/toAbsoluteUrl'
import { useHistory } from "react-router"
import { Button, Typography } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useSelector } from 'react-redux';



const SubDialogue = (props) => {
    const {handleClose} = props;
    const history = useHistory()
    const {userSubscription} = useSelector( state => state.subscriptions)



  return (
    <div 
        // style={{display:'flex',flexDirection:'column',alignItems:'center'}}
    >
        <div style={{
            display:'flex',
            justifyContent:'space-between'
        }}>
            <img
                    onClick={(e) => history.push("/")}
                    src={toAbsoluteUrl("/media/logo-sm-white.png")}
                    height="50"
                    className="pointer"
                    width="30"
                    alt="small logo"
                    style={{ }}
            />
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',marginTop:'10px'}}>
                <Typography variant="h6" style={{fontWeight:'bolder'}}>
                    Subscribe               
                </Typography>
                <Typography variant="p" style={{color:'#c2b6b6',fontWeight:'bold'}}>
                    to download vocals               
                </Typography>
            </div>
            <div style={{cursor:'pointer'}}>
                <CloseRoundedIcon onClick={handleClose}/>
            </div>
        </div>
        <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
            
                <Typography variant="p" style={{
                    border:'1px solid rgb(135 133 133)',
                    fontWeight:'bolder',
                    color:'#83afff',
                    borderRadius:'5px',
                    padding:'10px 30px',
                    cursor:'pointer',
                    
                    }}
                    onClick={(e) => (userSubscription?.subscription_plan_id)? history.push("/manage-subscription"): history.push("/plans")}      
                    >
                    Go to plans page
                </Typography>
            
        </div>

    </div>
  )
}

export default SubDialogue