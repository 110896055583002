import * as React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { StyledGrid } from "../../../_warbls/components/Container/StyledGrid"
import { Row } from "../../../_warbls/components/Flex/Row"
import { toAbsoluteUrl } from "../../../_helpers/toAbsoluteUrl"
import { useSelector } from "react-redux"
import { filterInitialValues } from "../../../_warbls/components/Vocals/script"
import { useLocation } from "react-router-dom"
import axios from "axios"
import { APIs } from "../../../_helpers/apis"
import { VocalsTable } from "../../../_warbls/components/Vocals"

const { useState, useEffect, useCallback } = React

export const Artist = ({ props }) => {
  const { tableContainer, topBg, artist, name, filterBg } = useStyles()
  const location = useLocation()
  var { userInfo } = useSelector((state) => state.auth)
  const [size, setSize] = useState([0, 0])

  if (location?.state?.data) {
    userInfo = location?.state?.data
    profileImg = location?.state?.data?.cover_image
  }

  var profileImg = userInfo.cover_image
  let heading = ["#", "Waveform", "Name", "Key", "BPM", "Time" , ""]
  if (size[0] < 1280) {
    heading = ["Play", "", ""]
  }

const [artistTracks, setArtistTracks] = useState([])
const [firstLoad, setFirstLoad] = useState(true)
const [tracksTotal, setTracksTotal] = useState(0)
const [searchFilters, setSearchFilters] = useState(filterInitialValues)
const [appliedFilters, setAppliedFilters] = useState([])


const sendRequest = useCallback(async (length) => {
  let newTracks = []
   if (searchFilters.trending === 'Random') searchFilters.trending = ''
  axios.get(`${APIs.tracks}?load=user_details&offset=${length || artistTracks.length}&limit=5&object_type=user&added_by=${userInfo.user_id}&object_id=${userInfo.user_id}&searchFilters=${encodeURIComponent(JSON.stringify(searchFilters))}` ).then((res) => {
      setTracksTotal(res?.data?.total)
        newTracks = res.data?.results ? res.data?.results?.filter((track) =>
      track?.status === "active"
    ) : []

    if(length === 0)
      setArtistTracks(newTracks)

    else
      setArtistTracks([...artistTracks, ...newTracks])
  })
},[artistTracks, searchFilters, userInfo.user_id] )

  const fetchVocals = useCallback(async (length) => {
  const updatedFilters = []

    for (const [key, val] of Object.entries(searchFilters)) {
      if (!key.includes("is")  && val !== "") updatedFilters.push(`${key.charAt(0).toUpperCase() + key.slice(1)}: ${val}`)
      setAppliedFilters(updatedFilters)
    }
   sendRequest(length)
 }, [artistTracks, searchFilters])

  useEffect(()=> {
    if(firstLoad)
      setFirstLoad(false)
    else
      fetchVocals(0)
  }, [firstLoad, searchFilters])

  const clearFilterData = useCallback(async () => {
    setSearchFilters(filterInitialValues)
 }, [artistTracks, searchFilters])


  const removeFilter = useCallback(async(key, length) => {

    for (const [k, v] of Object.entries(searchFilters)) {
      if (k.toLowerCase() === key.split(':')[0].toLowerCase() && key.split(':')[1] !== ''){
        searchFilters[k] = ''
         setAppliedFilters(appliedFilters.filter(prevFilter => prevFilter !== key))
      }
    }
    sendRequest(length)
   }, [appliedFilters, searchFilters])

  //listning for width to switch filters fot mobile screen and laptop
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  return (
    <Box>
      <Box
        className={topBg}
        style={{
          backgroundImage: `url(${profileImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          padding: "0 50px"
        }}
        display="flex"
        alignItems="center"
      >
        {(userInfo.is_admin || userInfo.is_admin === 0)}
        <StyledContainer display="flex" flexDirection="column" style={{ height: "100%", padding: "110px 0 0 0px" }}>
          <Row ai="center">
            <img src={toAbsoluteUrl("/media/Vector.png")} alt="" width="20px" height="20px" />
            &nbsp; &nbsp;<Typography className={artist}><h2>Verified Artist</h2></Typography>
          </Row>
          <Typography className={name}>{userInfo?.full_name}</Typography>
        </StyledContainer>
      </Box>
      <Box>

         <StyledGrid xs={12} item className={filterBg} >
        </StyledGrid>

        <div className={tableContainer} style={{ marginTop: size[0] > 1280 ? "-20px" : "30px", padding: size[0] > 1280 ? "0 10px" : "75px 0px" }}>
          <VocalsTable
          heading={heading}
          download={false}
          heart={true}
          list={artistTracks}
          downloadCheck={false}
          fetchVocals={fetchVocals}
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
          tracksTotal={tracksTotal}
          appliedFilters={appliedFilters}
          clearFilterData={clearFilterData}
          removeFilter={removeFilter}
        />
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: "100%",
    padding: "0 95px 42px",
    maxWidth: "100%",
    overflow: "auto",

    "@media (max-width:800px)": {
      padding: "0px",
      width: "97%",
      oveflow: "scroll",
      maxWidth: "730px",
      margin: "0 auto"
    },
    "@media (max-width:600px)": {
      oveflow: "scroll",
      maxWidth: "530px"
    },
    "@media (max-width:400px)": {
      oveflow: "scroll",
      maxWidth: "330px"
    }
  },
  sidebar_controls: {
    display: "flex",
    columnGap: "10px",
    margin: "-80px 0px 50px 0px"
  },
  control_image: {
    cursor: "pointer",
    backgroundColor: "rgb(102,102,102)",
    padding: "5px"
  },

  table: {
    width: "100%",
    borderCollapse: "collapse",
    "& thead tr": {
      border: "1px solid white",
      scroll: "auto"
    },
    "& thead tr th": {
      minWidth: 50,
      paddingBlock: "10px",
      scroll: "auto"
    },
    "& tbody tr td": {
      paddingTop: 5,
      paddingBottom: 5,
      scroll: "auto"
    }
  },
  topBg: {
    width: "100%",
    height: "240px"
  },
  topBtn: {
    textTransform: "none",
    marginRight: 15,
    marginTop: 10,
    boxSizing: "border-box",
    padding: "12px 15px",
    color: "white",
    border: "1px solid white",
    borderRadius: "5px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "#C4C4C4"
      //   border: "2px solid #000000"
    }
  },
  editedBtn: {
    color: "white",
    fontWeight: "bolder",
    fontSize: 14,
    marginLeft: "0px",
    marginTop: 10,
    boxSizing: "border-box",
    padding: "0px 15px",
    backgroundColor: "rgba(87, 164, 255, 1)",
    border: "1px solid #FFFFFF",
    textTransform: "capitalize",

    strong: {
      fontWeight: "bolder"
    }
  },
  filterBg: {
    padding: "0px 50px 32px",
    width: "100%",
    height: "50px",
    background: "linear-gradient(180deg, rgba(80, 141, 214, 0.8) 0%, #333333 86.39%)",

    "@media (max-width: 1280px)": {
      padding: "0 25px 32px"
    }
  },
  filterBox: {
    width: "100%",

    position: "relative",
    marginBottom: 30
  },
  select: {
    border: 0,
    marginTop: 20,
    width: 100
  },
  artist: {
    fontSize: 14,
    color: "white",
    padding: 0,
    margin: 0
  },
  name: {
    marginTop: "-20px",
    color: "white",
    fontSize: "xxx-large",
    fontWeight: "bolder",
    width: "100%",
    "@media (max-width:1000px)": {
      fontSize: 40
    }
  }
}))
