import { ContentContainer } from "../../../_warbls/components/Container"
import * as React from "react"
import { Box, makeStyles, Typography, Button } from "@material-ui/core"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { RightSide } from "./info"
import { StyledGrid } from "../../../_warbls/components/Container/StyledGrid"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/auth/actions"
import { useFormik } from "formik"
import clsx from "clsx"
import { initValues } from "./script"
import cogoToast from "cogo-toast"
import { useHistory } from "react-router"
import ProfileMenu from "./ProfileMenu"
import { useState } from "react"
import EditIcon from '@material-ui/icons/Edit';

import './CSS/index.css'
import SubscriptionInfo from "./SubscriptionInfo"
import { UploadForm } from "../UploadForm"
import { MyVocals } from "../MyVocals"
import { ProfileContainer } from "./ProfileContainer"
import CardPrompt from "./CardPrompt"
import Alart from "./Alart"


const { useEffect } = React

const { logoutRequest } = actions

export const Profile = (props) => {
  const { bg, success, danger, profileRoot, info, saveBtnContainer, saveBtn } = useStyles()
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.auth)
  const history = useHistory()
  userInfo.password = ""

  const [formEdit, setFormEdit] = useState(false);
  const [card_prompt, setCard_prompt] = useState(false)
  const [clickedCardID, setClickedCardID] = useState('card1')
  const [remove_account, setRemove_account] = useState(false)  
  const [end_subscription, setEnd_subscription] = useState(false)  



  const formik = useFormik({
    initialValues: Object.assign(initValues, userInfo),
    onSubmit: (values) => {
      dispatch(actions.userUpdateRequest(values, values.user_id))
    }
  })

  useEffect(() => {
    //dispatch(actions.userInfoRequest())
    // dispatch(actions.usersRequest())
    dispatch(actions.userReportsRequest())
    dispatch(actions.userTypesRequest())
    // dispatch(actions.userUpdateRequest({ username: "vickey", log: { log_added_by: "1" } }))
  }, [])

  const handleRemoveAccount = () => {
    const id = userInfo.user_id
    dispatch(actions.removeAccountRequest({ is_suspended: 1 }, id, true))
  }
  const handleChangePassword = () => {
    if (!!formik.values.password) {
      const id = userInfo.user_id
      dispatch(actions.userUpdateRequest({ password: formik.values.password }, id, true))
    } else {
      cogoToast.warn("Please enter password first.")
    }
  }

  




  return (
    <div>
      {card_prompt && (
        <div className="card-prompt-container">
          <CardPrompt 
            setPrompt={setCard_prompt}
            cardID={clickedCardID}
            />
        </div>
      )}

      {remove_account && (
        <div className="card-prompt-container">
          <Alart
            tital='Are you sure wanna delete your account?'
            subTital='Downloaded vocals will be lost'
            acceptClick={handleRemoveAccount}
            declineClick={() => { setRemove_account(false) }}
            btn_tital='delete'
          
          />
        </div>
      )}

      {end_subscription && (
        <div className="card-prompt-container">
          <Alart
            tital='Are you sure you want to cancel your subscription?'
            subTital='All your remaining coins will be lost.'
            acceptClick={() => {  }}
            declineClick={() => { setEnd_subscription(false) }}
            btn_tital='cancel'          
          />
        </div>
      )}

      

      


        <ProfileContainer
          activeTab='tab1'
        >
          <StyledContainer className={bg} m={0} p={0} minHeight="70vh">
          <StyledGrid xs={12} md={12} sm={12} 
            className="right-side-container"
          style={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'center',
              flexWrap:'wrap',
              
              
          }}>
            
            <Box className="form-container" >
              
              <form>
                <div className="button-container">
                  {formEdit?(<span><button className="submit-btn" onClick={formik.handleSubmit} type="submit">Save Changes</button>
                  <button className="cancele-btn" onClick={() => { setFormEdit(false) }}>Cancele</button></span>):(<EditIcon style={{cursor:'pointer'}} onClick={() => { setFormEdit(true); }}/>)}
                  
                </div>

                <div className="input-container">
                  <Typography variant="p">First Name</Typography>
                  { formEdit?(<input
                  
                  id="full_name"
                  name="full_name"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.full_name}                   
                />):(<div className="input-div">{userInfo.full_name}</div>)

                  }
                  
                </div>
                <div className="input-container">
                  <label htmlFor="email">Email Address</label>
                  {formEdit?(<input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />):(<div className="input-div">{userInfo.email}</div>)}

                  
                </div>
                <div className="input-container"
                  style={{
                    marginBottom:'20px'
                  }}
                >
                  <label htmlFor="password">Password</label>
                  {formEdit?(<input
                    id="passord"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    style={{
                      color:"white",
                    }}

                  />):(<div style={{color:'#57A4FF'}} className="input-div">Change Password</div>)}
                  
                </div>
                <div className="button-container delete-btn-container"> 
                    <button className="delete-btn"
                      onClick={(e) => { 
                        e.preventDefault()
                        setRemove_account(true) }}
                    >
                      Delete account
                    </button>

                </div>
              </form>

            </Box>
              {/* <Box className="subscription-Info-main-container" style={{
                  paddingLeft:'20px'
              }}>
               
                <SubscriptionInfo
                  setCardID={setClickedCardID}
                  setPrompt={setCard_prompt}
                  setEndSubscription={setEnd_subscription}
                />

              </Box> */}
          {/* <StyledGrid item xs={12} sm={12} md={3} lg={3} className={saveBtnContainer}>
            <Button
              className={saveBtn}
              variant="contained"
              color="primary"
              onClick={formik.handleSubmit}
            >
              Save Details
            </Button>
          </StyledGrid> */}
          </StyledGrid>
          
          {/* <StyledGrid item xs={12} pl={0}>
            <Box mt={{ sm: 3, md: 7 }}></Box>
            <StyledGrid container>

              <StyledGrid xs={12} sm={12} md={6} lg={6} mt={5}>
                <Box py={1}>
                  <Typography
                    className={clsx(success, "pointer")}
                    onClick={() => {
                      let path = `upload-form`
                      props.history.push(path)
                    }}
                  >
                    Request Upload Access
                  </Typography>
                </Box>
                <Box py={1}>
                  <Typography className={clsx(info, "pointer")} onClick={handleChangePassword}>
                    Change Password
                  </Typography>
                </Box>
                <Box py={1}>
                  <Typography
                    className={clsx(danger, "pointer")}
                    onClick={() => {
                      dispatch(logoutRequest())
                      history.push("/")
                    }}
                  >
                    Log out
                  </Typography>
                </Box>
              </StyledGrid>
              <StyledGrid item xs={12} sm={12} md={3} lg={3}>
                <Box py={1}>
                  <Typography className={clsx(danger, "pointer")} onClick={handleRemoveAccount}>
                    Delete Warbls account
                  </Typography>
                </Box>
              </StyledGrid>
            </StyledGrid>


            <Box py={1}>
                <Typography className={clsx(success, "pointer")} onClick={formik.handleSubmit}>
                  Update Profile
                </Typography>
              </Box>


          </StyledGrid> */}

          </StyledContainer>
        </ProfileContainer>

    </div>
      
        
  )
}

const useStyles = makeStyles((theme) => ({
  bg: {
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  success: {
    color: "#31E921",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center"
  },
  info: {
    color: "#31DAFF",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center"
  },
  danger: {
    color: "#FF0000",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center"
  },
  profileRoot: {
    // width: "100%",
    minHeight: "90vh",
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      marginTop: "0rem"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "17rem",
      marginBottom: 50
    }
  },
  saveBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start"
  },
  saveBtn: {
    background: "#EFEFEF",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    color: "black"
  }
}))
