import React from 'react'
import './CSS/infocard.css'
import { Box } from '@material-ui/core'
const Infocards = (props) => {
    const {heading,containt,color}=props

  return (
    <div className='info_card_container'>
      <Box style={{borderTop:'2px solid',borderColor:color,padding:'0 10px',paddingTop:' 10px'}}>
        <h3 style={{color:color}}>{heading}</h3>
      </Box>
        <p style={{padding:'5px 0'}}>{containt}</p>
    </div>
  )
}

export default Infocards


