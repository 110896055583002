/** SIGNUP TYPES */
export const SIGNUP_USER_REQUEST = "SIGNUP_USER_REQUEST"
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS"
export const SIGNUP_USER_FAILED = "SIGNUP_USER_FAILED"

/** LOGIN TYPES */
export const LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST"
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS"
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED"

/** USER INFO TYPES */
export const USER_INFO_REQUEST = "USER_INFO_REQUEST"
export const USER_INFO_SUCCESS = "USER_INFO_SUCCESS"
export const USER_INFO_FAILED = "USER_INFO_FAILED"

/** LOGOUT TYPES */
export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED"

/** USERS TYPES */
export const USERS_REQUEST = "USERS_REQUEST"
export const USERS_SUCCESS = "USERS_SUCCESS"
export const USERS_FAILED = "USERS_FAILED"

/** USER REPORT */
export const USER_REPORTS_REQUEST = "USER_REPORTS_REQUEST"
export const USER_REPORTS_SUCCESS = "USER_REPORTS_SUCCESS"
export const USER_REPORTS_FAILED = "USER_REPORTS_FAILED"

/** USERS TYPES */
export const USER_TYPES_REQUEST = "USER_TYPES_REQUEST"
export const USER_TYPES_SUCCESS = "USER_TYPES_SUCCESS"
export const USER_TYPES_FAILED = "USER_TYPES_FAILED"

/** USER UPDATE */
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED"

/** */
export const USER_IMAGE_UPDATE_REQUEST = "USER_IMAGE_UPDATE_REQUEST"
export const USER_IMAGE_UPDATE_SUCCESS = "USER_IMAGE_UPDATE_SUCCESS"
export const USER_IMAGE_UPDATE_FAILED = "USER_IMAGE_UPDATE_FAILED"

/**REMOVE ACCOUNT */
export const REMOVE_ACCOUNT_REQUEST = "REMOVE_ACCOUNT_REQUEST"
export const REMOVE_ACCOUNT_SUCCESS = "REMOVE_ACCOUNT_SUCCESS"
export const REMOVE_ACCOUNT_FAILED = "REMOVE_ACCOUNT_FAILED"

/** STOP LOADER SUCCESS */
export const STOP_AUTH_LOADER = "STOP_AUTH_LOADER"
