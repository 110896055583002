import React from 'react'
import Plans from './Plans'
import { ContentContainer } from '../../../_warbls/components/Container'
import '../../../_warbls/components/subscription/CSS/SelectPlan.css'
import { useSelector } from 'react-redux'
const Plan = () => {
  const {userSubscription} = useSelector( state => state.subscriptions)
  const activeID = userSubscription?.subscription_plan_id || 0
  return (
    <ContentContainer
    titleBackground={"linear-gradient(91.97deg, #212121 9.8%, #57A4FF 133.46%)"}
    title={"Select Plan"}>
      <div className="sp-info">
        <Plans
         activePlanId={ activeID }
        />
      </div>
    </ContentContainer>
  )
}

export default Plan