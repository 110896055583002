import React from 'react'
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from '@material-ui/core';


const Alart = (props) => {

    const {tital,subTital,acceptClick,declineClick,btn_tital} = props

  return (
    <div className="card-prompt-stickey">
        <div className="btn-alart-prompt">
          <div className="card-prompt-cross">
              <CloseIcon
                onClick={declineClick}
                style={{
                  cursor:'pointer',
                  color:'#000'
                }}
              />
          </div>
          <div className="alart-info-container">
            <Typography style={{fontWeight:'bolder',fontSize:'1.1rem'}} variant='p'>{tital}</Typography>
            <Typography style={{fontWeight:'bolder'}} variant='p'>{subTital}</Typography>

          </div>
          <div className="alart-btn-container">
            <button 
              onClick={declineClick}
              style={{color:'#0D0D0D'}} className="alart-btn" id='alart-btn-1'>
            No, I want to go back
            </button>
            <button 
              onClick={acceptClick}
              style={{
              
             
            }} 
            className="alart-btn"
            id='alart-btn-2'
            >
              {`Yes, I want to ${btn_tital}`}             
            </button>
          </div>

        </div>

      </div>
  )
}

export default Alart