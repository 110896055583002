import React from "react"
import "./CSS/Card.css"
import { Typography } from "@material-ui/core"
import { useHistory } from 'react-router-dom';
import { actions } from "../../../redux/subscription/actions"
import { useDispatch ,useSelector } from "react-redux"
import { POST } from "../../../_helpers/fetcher"
import { APIs } from "../../../_helpers/apis"


const Card = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { userInfo, auth } = useSelector((state) => state.auth)

  

  const next = async () => {
          // dispatch(actions.userSubscriptionPlan(props));
          if(props.active){
            return;
          }
          if(props.id){
            
          }
          const body = {
            "productName": `${props.plan} (${props.coin} coins)`,
            // "productImg": "https://www.warbls.com/media/warbls-logo.png",
            "recurring": props.duration ? "month" : 'year',
            "subscriptionPlanId":props.id,
            "user_id": userInfo.user_id,
            "price": props.price,
            "paymentMode": "subscription", //"payment",
            "successUrl": "https://www.warbls.com/vocals?status=succeed",
            "cancelUrl": "https://www.warbls.com/vocals?status=failed"
          }
          let res = await POST(APIs.stripeCheckoutSession, body);
          window.location.href = res.data
    
}
  const resubscribeUserPlan = async () => {
         
          const body = {
            
            "userId": userInfo.user_id,
            
          }
          let res = await POST(APIs.resubscibeUserPlan, body);
          if(res){
            window.location.href = '/manage-subscription';
          }
          // window.location.href = res.data
    
}

  return (
    <div className="card" id={props.id}  >
      {(props.plan !== 'Basic Plan') && <div className="card-batch">{(props.plan === 'Pro Plan')? 'Most Popular':'Best value'}</div>}  
      <div onClick={() => {  }} className="plan" >
        <div className="plan-name">{props.plan}</div>
        
      </div>

      <div className="info">
        <div className="coins">
          
          <div className="num">
            <img src={`${process.env.PUBLIC_URL}/images/coin-stack.png`} className="image" />
            <div className="value">{props.coin}</div>
          </div>
          <Typography style={{color:'#686868',fontWeight:'bolder',fontSize:'1.2rem'}} variant="p">/{props.price_duration}</Typography>
        </div>

        <div className="price">
          
          <div className="num">
            <img
              src={`${process.env.PUBLIC_URL}/images/dollar-currency-symbol.png`}
              className="image"
            />
            <div className="value" id="price-value">
              {props.price}
             
            </div>
          </div>
          <Typography style={{color:'#686868',fontWeight:'bolder',fontSize:'1.2rem'}} variant="p">/{props.price_duration}</Typography>
        </div>
  
      </div>
      {props.cancelUserSubscription && props.id == props.cancelUserSubscription.subscription_plan_id ?
      (<div onClick={resubscribeUserPlan} className="sp-choose-btn" style={{backgroundColor:'#C957FF'}}>
        <Typography variant="h6" style={{fontWeight:'bolder'}}>
         Resubscribe
         </Typography>
     </div>)
      :
      (<div onClick={next} className="sp-choose-btn" style={{backgroundColor:props.active? '#C957FF':'#57A4FF',cursor: props.active? 'not-allowed' : 'pointer'}}>
         <Typography variant="h6" style={{fontWeight:'bolder'}}>
          {props.active?"Active":"Choose"}
          </Typography>
      </div>)
      }
      <div style={{textAlign:"center",marginTop:'20px'}}>
        {
          (props.duration)?(
            <Typography style={{fontWeight:'bold',color:"#57A4FF",cursor:'pointer'}} variant="p" onClick={props.yearclick}>Save 20% with yearly billing</Typography>
          ):(<Typography style={{fontWeight:'bold',color:"#57A4FF",cursor:'pointer'}} variant="p" onClick={props.monthclick}>Switch to monthly billing</Typography>)
        }
        
      </div>
    </div>
  )
}

export default Card
