import { Typography } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../../redux/track/actions"
import { actions as TrackActions } from "../../../redux/track/actions"
import { AppContext } from "../../providers/AppProvider"
import { FaRegHeart, FaShoppingCart } from "react-icons/fa"
import { ImHeart } from "react-icons/im"
import { useContext } from "react"
import { sidebarDrawerContext } from "../../../app/App"
import { actions as authActions } from "../../../redux/auth/actions"
import { useHistory } from "react-router"
import { useState } from 'react'
import { SongContext } from '../SideBarInfo'
import { useCallback } from 'react'

const SongPopup = () => {
    const {row} = style;
    const mysongcontext = useContext(SongContext);
    const currentId = mysongcontext.currentId;
    
    const dispatch = useDispatch()
    const { userInfo, auth , users } = useSelector((state) => state.auth)
    const { allTracks } = useSelector((state) => state.track)
    
    const { handleLogin, handleCart } = useContext(AppContext)
    let trackObj = allTracks.find(t => t.track_id === currentId);
    const history = useHistory()

    const [heartFlag, setheartFlag] = useState(currentId && trackObj?.liked === 1)

    
    const navigateToArtist = (artistID) => {
      const artist = users.filter((user) => {
        return user.user_id === artistID
      })
  
      if (auth) {
        history.push({
          pathname: "/artist",
          state: {
            data: artist[0]
          }
        })
      }
    }
    
    
    
    // const handleLike = (trackID) => {
    //     if (auth) {
    //       const data = {
    //         action: "like",
    //         action_against_id: trackID,
    //         ...payload
    //       }
    //       dispatch(actions.trackWiseLikeRequest(data))
    //       console.log("handlelike");
    //     } else {
    //       handleLogin()
    //     }
    //   }
    const handleLike = useCallback(
      (trackID) => {
        const payload = {
          object_type: "user",
          object_id: userInfo.user_id,
          added_by: userInfo.user_id
        }
  
        if (auth) {
          const data = {
            action: "like",
            action_against_id: trackID,
            ...payload
          }
          dispatch(actions.trackWiseLikeRequest(data))
        } else {
          handleLogin()
        }
      },
      [auth, dispatch, handleLogin, userInfo.user_id]
    )

    const handleUnlike = (trackID) => {
      if (auth) {
        const data = {
          action: "like",
          object_id: userInfo.user_id,
          log: JSON.stringify({ log_added_by: userInfo.user_id })
        }
  
        dispatch(actions.trackWiseUnLikeRequest(data, trackID))
      } else {
        handleLogin()
      }
    }

    

      // const handleUnlike = (trackID) => {
      //   if (auth) {
      //     const data = {
      //       action: "like",
      //       object_id: userInfo.user_id,
      //       log: JSON.stringify({ log_added_by: userInfo.user_id })
      //     }
      //     dispatch(actions.trackWiseUnLikeRequest(data, trackID))
      //     console.log("handleUnlike");
      //   } else {
      //     handleLogin()
      //   }
      // }


  return (
    <div style={{
        position:'absolute',
        left:80,
        backgroundColor:'#B1B1B1',
        minHeight:'100px',
        color:'black',
        fontWeight:'bold',
        borderRadius:'10px',
    }}>
        <div style={row}>Add to your library ({!!trackObj.is_oneshot ? 5: 10} coins)</div>
        {heartFlag?(
            <div style={row} 
            
            onClick={() => { 
              handleUnlike(trackObj?.track_id)
              trackObj.liked = 0
              setheartFlag(!!!heartFlag)}}
            >Remove from likes</div>):
            (<div style={row} 
              onClick={() => {
                handleLike(trackObj?.track_id)
                trackObj.liked = 1
                setheartFlag(!!!heartFlag)
                  }}
              >Add to likes</div>)}
        <div style={row}>Copy link</div>
        <div style={{
            margin:'0 10px', 
            padding:'10px',
            cursor:'pointer'
        }}
        // onClick={() => navigateToArtist(trackObj.user_details?.user_id)}
  
        >View artist page</div>
    
    
    
    </div>
  )
}

export default SongPopup

const style = {
    root:{

    },
    row:{
        margin:'0 10px', 
        borderBottom:'2px solid black',
        padding:'10px',
        cursor:'pointer'       
        
    }
}