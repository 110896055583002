import React, { useState, useRef, useCallback, useContext, useEffect } from "react"
import { useHistory } from "react-router"
import {CircularProgress, makeStyles, Button, Typography, useMediaQuery, useTheme, Box } from "@material-ui/core"

import { FaRegHeart } from "react-icons/fa"
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ImHeart } from "react-icons/im"
import { toAbsoluteUrl } from "../../../_helpers/toAbsoluteUrl"
import { useSelector } from "react-redux"

import { FaCoins } from "react-icons/fa";
import { MdDownloadForOffline } from "react-icons/md";
// import { SongContext } from "../SideBarInfo"
import { SongContext } from "../../../_warbls/components/SideBarInfo";

import AddIcon from '@material-ui/icons/Add';

import StopRoundedIcon from '@material-ui/icons/StopRounded';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import WaveSurfer from 'wavesurfer.js';
import { AppContext } from "../../../_warbls/providers/AppProvider";


export const TempRow = ({
  rowList,
  index,
  setContainer,
  setCurrent,
  container,
  current,
  download,
  heart,
  handleDownload,
  handleLike,
  handleUnlike,
  handleCart,
  downloadCheck,
  handleDownloadTrackWithCoins,
  userSubscription,
  handleDialogue,
}) => {
  const history = useHistory()
  const { btn, helperText, btnCell, row, mucisTrack, downloadCell, downIconSvg,downIcon,heartStyle, desc, tracksControl, add_to_cart, btn_download, responsive_layout_vocals } =
    useStyles()
  const theme = useTheme()
  const media = useMediaQuery(theme.breakpoints.down("sm"))
  const { auth, users } = useSelector((state) => state.auth)

  const [duration, setDuration] = useState("0:00")
  const [heartFlag, setheartFlag] = useState(rowList?.liked === 1)
  // const downloadRef = useRef(null)
  const priceRef = useRef(null)
  const waveformRef = useRef(null);
  const [copyVocal, setCopyVocal] = useState(false);


  
const { openLogin, handleLogin, openSignup, handleSignup, toolbar } = useContext(AppContext)
  const mysongcontext=useContext(SongContext);
  const [loader, setLoader] = useState(true)
  const [waveform, setWaveform] = useState('')
  const childRef = useRef();

  const [play, setPlay] = useState(false)

  const [isPerchased, setisPerchased] = useState(false)

  // const {userSubscription} = useSelector( state => state.subscriptions)
  

  

  const generateDuration = useCallback((dur) => {
    const fixed = dur.toFixed(0)
    const val = fixed.split(".")[0]
    const mins = val > 60 ? (val / 60).toFixed(0) : 0
    const scnd = val > 60 ? val % 60 : val
    return `0${mins}:${scnd.toString().length === 2 ? scnd : "0" + scnd}`
  }, [])

  const [isHover, setIsHover] = useState(false)
  const [size, setSize] = useState([0, 0])

  //  listning for width to switch filters fot mobile screen and laptop
  React.useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  

  const navigateToArtist = (artistID) => {
    const artist = users.filter((user) => {
      return user.user_id === artistID
    })

    if (auth) {
      history.push({
        pathname: "/artist",
        state: {
          data: artist[0]
        }
      })
    }
  }

  const getVocalsWidth = () => {
    if (size[0] > 1200) return "300px"
    else if (size[0] > 430) return "210px"
    else if (size[0] > 400) return '190px'
    else if (size[0] > 380) return '180px'
    else return '160px'
  }

  

  // WaveForm code is starts from here

  useEffect(() => {
    setLoader(true)
    const track = document.querySelector(`#temptrack${rowList.track_id}`);

    waveformRef.current = WaveSurfer.create({
      barWidth: 3,
      cursorWidth: 1,
      container: `#waveform`,
      backend: 'WebAudio',
      height: 80,
      progressColor: '#2D5BFF',
      responsive: true,
      waveColor: '#EFEFEF',
      cursorColor: 'transparent',
    });

    waveformRef.current.load(track);

    waveformRef.current.on("ready", function () {
      setLoader(false)
      const fixed = waveformRef.current.getDuration().toFixed(0)
      const val = fixed.split(".")[0]
      const mins = val > 60 ? (val / 60).toFixed(0) : 0
      const scnd = val > 60 ? val % 60 : val
      const duration = `0${mins}:${scnd.toString().length === 2 ? scnd : "0" + scnd}`
      setDuration(duration)
    })

    return () => {
      waveformRef.current.destroy();
    };
  }, [rowList.track_id]);

  const handlePlay = () => {

    if (container) {
      container.current.stop();
    }
    // setPlaying(!playing);
    waveformRef.current.play();
    setContainer(waveformRef)
    setCurrent(rowList.track_id)
    setPlay(true)
    if(auth){
      mysongcontext.setCurrentId(rowList.track_id)
      mysongcontext.setCurrentSong(waveformRef.current)
      mysongcontext.setListTrack(rowList)
      mysongcontext.setPlaying(true)
    }
      
    // waveformRef.current.playPause();
};
  const handleStop = () => {

    waveformRef.current.stop();
    mysongcontext.setPlaying(false)
    setPlay(false)
    
};

  

const url = rowList?.track_url;



useEffect(() => {
  if(rowList.track_id == mysongcontext.currentId){
    handlePlay();
  }

  
},[mysongcontext.currentId])


  const rowOnClick = () => { 
    if(play){
      handleStop();
    }else{
      handlePlay();
    }
   }







  // WaveForm code is ends at here

   
//  console.log('this is reduser user subscription',userSubscription.remaining_coins);





  return (

    <tr
      onClick={rowOnClick}
      style={{ 
        userSelect: "none", 
        position: 'relative',
      cursor:'pointer'}}
      className={`${row} table-row`}
      id={rowList.track_id === current && (auth?(mysongcontext.playing):(play))?('table-play-row'):('')}
      // onClick={(e) => playVocalOnRow(e, rowList)}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      onMouseLeave={() => {
        setIsHover(false)
      }}
    >

      <td style={{ display: size[0] > 1280 ? "flex" : "none", alignItems:'center',justifyContent:'center', width: '30px', height: '100%' ,marginLeft:'10px' }}>
        <span style={{ visibility: isHover && 'hidden', color: '#9D9D9D' }}>{index + 1}</span>

        {size[0] > 1280 && (
          <>
            {/* {isHover && ( */}
              <>
                {/* {heartFlag ? (
                  <ImHeart
                    className={heartStyle}
                    fontSize="16px"
                    onClick={() => {
                      handleUnlike(rowList?.track_id)
                      rowList.liked = 0
                      setheartFlag(!!!heartFlag)
                    }}
                  />

                  // <></>
                ) : (
                  <FaRegHeart
                    className={heartStyle}
                    fontSize="16px"
                    onClick={() => {
                      handleLike(rowList?.track_id)
                      rowList.liked = 1
                      setheartFlag(!!!heartFlag)
                    }}
                  />

                  // <></>
                  
                )} */}

                {/* {loader ? (
                        <CircularProgress size={20} color="inherit" className={heartStyle} />
                        ) : ( */}
                        <>
                          {/* {rowList.track_id === current && mysongcontext.playing ? ( */}
                          {
                          rowList.track_id === current && (auth?(mysongcontext.playing):(play)) ? (
                            
                              <StopRoundedIcon
                                onClick={handleStop}
                                className={heartStyle} 
                                style={{width:'50px',height:'50px',color:'#57A4FF'}}
                                />
                            
                          ) : (
                            isHover && (

                              
                              <PlayArrowRoundedIcon                              
                                onClick={handlePlay}  
                                style={{width:'50px',height:'50px',color:'#B1B1B1'}}
                                className={heartStyle}
                                />
                            
                                )
                          )}
                        </>
                       {/* )} */}

              </>
            {/* )} */}
          </>
        )}
      </td>


      
      <td>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "400px",
            marginLeft: "10px"
          }}
        >
          {rowList?.image_url ? (
            <div onClick={rowOnClick} style={{cursor:'pointer', position: "relative" }}>
              {loader && (<div style={{display:'flex',justifyContent:'center',alignItems:'center', width:'100%',height:'100%',position:'absolute'}}>
                <CircularProgress size={30} color="#000"/>
              </div>)}
              <img  src={rowList?.image_url} className={mucisTrack}  alt="music track img" />
              <div className={tracksControl}>
                {/* {size[0] < 1280 && (
                  <WaveFramer
                    generateDuration={generateDuration}
                    song={rowList?.track_url}
                    setContainer={setContainer}
                    setCurrent={setCurrent}
                    container={container}
                    current={current}
                    setDuration={setDuration}
                    id={rowList.track_id}
                    listTrack={rowList}
                    setLoad={setLoader}
                    setWaveform={setWaveform}

                    size={size[0]}

                  />
                )} */}
              </div>
            </div>
          ) : (
            <div onClick={rowOnClick} style={{ position: "relative", border: "1px solid white" }}>
              <img src={toAbsoluteUrl("/media/music-track.png")} className={mucisTrack} alt="music track img" />
            </div>
          )}
          <div
            onClick={rowOnClick}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginLeft: "10px",
              width: getVocalsWidth(),
              paddingRight: "0px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          > 
            <Typography variant="p" style={{color:(size[0] < 1000 && rowList.track_id === current && play ) && '#57A4FF'}} >
            {rowList?.track_name.substring(0, 40)}
            {rowList?.track_name.length > 40 ? "..." : ""}
            </Typography>
            <small className={helperText} style={{ cursor: auth && "pointer" }} onClick={() => navigateToArtist(rowList?.user_details?.user_id)}>
              {rowList?.user_details?.full_name || ""}
            </small>
          </div>
        </div>
      </td>
      {/* {!media && size[0] > 1280 && ( */}
      { size[0] > 1000 && (
        <td
        style={{
          marginLeft: "0px",
          color: "#EAEAEA"
        }}
      >
        <Typography variant="body1" style={{ color: "#EAEAEA" }}>
          {rowList?.key}
        </Typography>
      </td>
      )}
      
      { size[0] > 1000 && (

      <td>
        <Typography
          variant="body1"
          style={{
            paddingLeft: window.location.href.split("/").pop() === "artist" ? "40px" : "0px",
            color: "#EAEAEA"
          }}
        >
          {rowList?.bpm}
        </Typography>
      </td>
      )}
      {/* )} */}
      {/* {!media && size[0] > 1280 && ( */}
      {/* )} */}
      {/* {size[0] > 1280 && ( */}

      {size[0] > 1000 && (
      <td
        style={{
          whiteSpace: "nowrap",
          margin: window.location.href.split("/").pop() === "artist" ? "20px 40px" : "20px",
          // paddingLeft: window.location.href.split("/").pop() === "artist" ? "40px" : "0px",
          // display: "inline-block"
        }}
      >
        <span>{duration}</span>
      </td>

      )}
      {/* )} */}
      {/* {size[0] < 1280 && ( */}
      <td
        style={{
          width: "300px",
          display: size[0] < 1280 && "none",
          userSelect: "none"
        }}
      >
        {rowList?.track_url && (
          <div
          style={{
              display: 'flex',
              // flexDirection: 'row',
              // alignItems: 'center',
              // justifyContent: 'center',
              // height: '100px',
              width: '100%',
              background: 'transparent',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '80px',
              }}
              id="waveform"
            />
            <audio id={`temptrack${rowList.track_id}`} src={url} preload="auto" />
          </div>



          // <TempWave
          // track_url={rowList?.track_url}
          // trackid={rowList.track_id}
          // play={play}
          // setDuration={setDuration}
          // setContainer={setContainer}
          // container={container}
          // setCurrent={setCurrent} 
          // listTrack={rowList} 
          // setLoader={setLoader}             
          // />

          // <WaveFramer
          //   generateDuration={generateDuration}
          //   song={rowList?.track_url}
          //   setContainer={setContainer}
          //   setCurrent={setCurrent}
          //   container={container}
          //   current={current}
          //   setDuration={setDuration}
          //   id={rowList.track_id}
          //   listTrack={rowList}
          //   setLoad={setLoader}
          //   setWaveform={setWaveform}

          //   size={size[0]}
          //   ref={childRef}
          // />

          
        )}
      </td>
      <td style={{ width: "70px", padding: size[0] > 1280 ? "0px" : "0 0px 0 30px" }}>
        {
          <>
          {heartFlag ? (
            
            <ImHeart
              className={heartStyle}
              fontSize="16px"
              onClick={() => {
                handleUnlike(rowList?.track_id)
                rowList.liked = 0
                setheartFlag(!!!heartFlag)
              }}
            />
           
          ) : (
            
            (size[0] > 1000 ? isHover : true) && (
            <div style={{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center"
            }}>
              
              {copyVocal?(
                  <FileCopyIcon
                  className={"."}
                  style={{
                   display:size[0] > 1000 ? '': 'none',
                   zIndex:20,
                   cursor:"pointer",
                   fontSize:"16px"
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); 
                   navigator.clipboard.writeText(`https://www.warbls.com/sharevocal/${rowList.track_id}`)
                  setCopyVocal(true);
                  
                }}
                />
              ):(
                <FileCopyOutlinedIcon
                className={"."}
                style={{
                  display:size[0] > 1000 ? '': 'none',
                  zIndex:20,
                  cursor:"pointer",
                  fontSize:"16px"
                }}
                onClick={(e) => { 
                  e.stopPropagation();
                  navigator.clipboard.writeText(`https://www.warbls.com/sharevocal/${rowList.track_id}`)
                  setCopyVocal(true);
                 
                  
              }}
              />
              )}
              

              <FaRegHeart
                className={"."}
                style={{
                  zIndex:20,
                  cursor:"pointer"
                }}
                fontSize="16px"
                onClick={(e) => {
                  e.stopPropagation();
                handleLike(rowList?.track_id)
                rowList.liked = 1
                setheartFlag(!!!heartFlag)
                  }}
              />

              

            </div>


            )
          )}
        </>
          
        }
        
      </td>
      {/* )} */}
        
      <td className={download ? downloadCell : btnCell}>
        {rowList.action == 'download' ? (
          <>
          <Button
            className={btn_download}
            onClick={(e) =>{
              e.stopPropagation();
              handleDownload(rowList?.track_url, rowList?.track_name)}
            }
            style={{display: size[0] > 1000 ? '':'none', backgroundColor: size[0] > 1280 ? "#C957FF" : "transparent" }}
            >
            <span className={downIcon}><MdDownloadForOffline className={downIconSvg}/></span>

            {size[0] > 1280 && <Typography className={desc}>DOWNLOAD </Typography>}
          </Button>
            
          <Box style={{display: size[0] >= 1000 ? 'none':''}}>
              <MdDownloadForOffline style={{width:'30px', height:'30px'}} onClick={() => handleDownload(rowList?.track_url, rowList?.track_name)} />
          </Box>
            
            
            </>
          // <Button
          //   className={btn_download}
          //   onClick={() => handleDownload(rowList?.track_url, rowList?.track_name)}
          //   style={{ backgroundColor: size[0] > 1280 ? "#FF00C7" : "transparent" }}
          // >
          //   <span> <img src="/media/download-light.png" width="22" height="20" /></span>

          //   {size[0] > 1280 && <Typography className={desc}>DOWNLOAD </Typography>}
          // </Button>
        ) : (
          // <Button className={clsx(btn , responsive_layout_vocals)} onClick={() => handleDownloadTrackWithCoins(rowList?.track_id)} style={{ backgroundColor: size[0] > 1280 ? "#169C00" : "transparent" }}>
          //   <img src="/media/cart.png" width="22" height="18" style={{ marginBottom: "2px" }} />
          //    &nbsp;&nbsp;
          //   {size[0] > 1280 && (
          //     <h2
          //       style={{
          //         padding: ".3rem 2rem",
          //         margin: "0",
          //         fontWeight: "bolder",
          //         color: "white",
          //         fontSize: "15px"
          //       }}
          //       ref={priceRef}
          //     >
          //       ${rowList?.price}
          //     </h2>
          //   )}
          // </Button>
          isPerchased?(
          <>
            <Button
              className={btn_download}
              onClick={(e) => {
                e.stopPropagation();
                handleDownload(rowList?.track_url, rowList?.track_name)}}
              style={{display: size[0] > 1000 ? '':'none', backgroundColor: size[0] > 1280 ? "#C957FF" : "transparent" }}
              >
              <span className={downIcon}><MdDownloadForOffline className={downIconSvg}/></span>
  
              {size[0] > 1280 && <Typography className={desc}>DOWNLOAD </Typography>}
            </Button>
              
            <Box style={{display: size[0] >= 1000 ? 'none':''}}>
                <MdDownloadForOffline style={{width:'30px', height:'30px'}} 
                onClick={(e) =>{
                  e.stopPropagation();
                  handleDownload(rowList?.track_url, rowList?.track_name)
                } 
                } 
                />
            </Box>
              
              
              </>):(
          <>
          <Button className={btn_download} style={{ display: size[0] > 1000 ? '':'none', backgroundColor: '#57A4FF',minWidth:"9rem"}} variant="contained" 
          startIcon={
            userSubscription?.remaining_coins >= (!!rowList.is_oneshot ? 5: 10)?
            <MdDownloadForOffline className={downIconSvg}/> 
            :
            <FaCoins />
               } onClick={(e) => {
                  e.stopPropagation();
                if(auth){
                  
                  if(userSubscription?.remaining_coins >= (!!rowList.is_oneshot ? 5: 10)){
                    handleDownloadTrackWithCoins(rowList?.track_id)
                    setisPerchased(true)
                  }else{
                    handleDialogue()
                  }
                }
                else{
                  handleLogin()
                }
                }} >
            <Button style={{padding: '6px 11px'}}>
              {!!rowList.is_oneshot ? 5: 10} coins
            </Button>

          </Button>

          <Box style={{display: size[0] >= 1000 ? 'none':''}}>
              <AddIcon onClick={(e) => 
                {
                  e.stopPropagation();
                  if(auth){

                    if(userSubscription?.remaining_coins >= (!!rowList.is_oneshot ? 5: 10)){
                      handleDownloadTrackWithCoins(rowList?.track_id)
                      setisPerchased(true)
                    }else{
                      handleDialogue()
                    }

                  }
                  else{
                    handleLogin()
                  }
                }
                }/>
          </Box>
          
          </>

          )
          

        )}

      </td>
    </tr>
  )
}

const useStyles = makeStyles((theme) => ({
  btn: {
    width: "auto",
    color: theme.palette.primary.contrastText,
    padding: "2px 40px",
    margin: 0,
    "@media (min-width:1280px)": {
      "&:hover": {
        backgroundColor: "#0A4600 !important",
        border: '1px solid #2AFF00',
      }
    },
    "&:hover $cartIcon": {
      display: "block",
    }
  },
  btn_download: {
    width: "auto",
    color: theme.palette.primary.contrastText,
    padding: "5px 15px",
    margin: 0,
    transition:'transform 0.2s ease-in',
    "&:hover $cartIcon": {
      display: "block"
    },
    "&:hover": {
      
      // border:"2px solid #fff",
      transform: 'scale(1.15)',
      
      
      
    }
  },
  cartIcon: {
    display: "none"
  },
  downIcon:{
    paddingTop: '4px',
    "& svg": {
      fontSize: '20px' 
    }
  },
  downIconSvg:{
    fontSize: '20px' 
  },
  desc: {
    //fontWeight: "bold",
    color: theme.palette.primary.contrastText,
    marginLeft: "8px",

  },
  helperText: {
    color: "#9F9F9F",
    "&:hover": {
      fontWeight:'bolder',
      textDecoration: 'underline',
      textDecorationColor:'#57A4FF',
      color:'#57A4FF'
    }
  },
  btnCell: {
    width: 260,

  },
  downloadCell: {
    width: 50,
  },
  row: {
    "& td": {
      textAlign: "center",
    },
    "& td:first-child": {
      // padding: '20px 10px 0 10px',
      position: 'absolute',
      inset: '0 0 0 0',

      textAlign: 'left',

    },
    "& td:last-child": {
      // textAlign: "right",
      padding: '0 10px',

    }
  },
  title: {
    display: "block",
    marginTop: "5px !important",
    fontSize: 17,
    border: "1px solid white",
    [theme.breakpoints.down("xl")]: {
      width: 300,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    "@media screen and (max-width:700px)": {
      width: "100px !important"
    },

    [theme.breakpoints.down("xs")]: {
      width: 120
    }
  },
  mucisTrack: {
    height: 37,
    marginTop: "5px",
    width: 42,
    borderRadius: '3px',
    "@media (max-width:1280px)": {
      height: 42,
      marginTop: "5px",
      width: 47,
    },
  },
  tracksControl: {
    position: "absolute",
    top: "0px",
    left: "0",
    height: 40,
    width: 40,
    marginLeft: 20,
    "@media (max-width:1280px)": {
      marginLeft: 25,
      top: "5px",
    },

  },
  heartStyle: {
    cursor: "pointer",
    zIndex: 20,
    "@media (min-width:1280px)": {
      position: 'absolute',
      // inset: '20px 15px 0 10px',

    }
  },
  add_to_cart: {
    padding: ".4rem 1rem .4rem 0rem",
    fontWeight: "bold",
    fontSize: 12,
    textTransform: "capitalize"
  },
  responsive_layout_vocals: {

    "@media (max-width: 420px)": {
      right: '25%'
    }
  }
}))


