import { useState, useCallback, useMemo } from 'react'
import { Typography, Button, makeStyles, Box, useTheme } from "@material-ui/core"
import { useFormik } from "formik"
import * as React from "react"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { StyledGrid } from "../../../_warbls/components/Container/StyledGrid"
import { Row } from "../../../_warbls/components/Flex/Row"
import { Column } from "../../../_warbls/components/Flex/Column"
import { InputField } from "../../../_warbls/components/Form/Input"
import { formScript, initValues, schema } from "./script"
import { ThanksVocalRow } from "../../../_warbls/components/VocalRow/ThanksVocalRow"
import { useSelector } from "react-redux"
import { POST } from "../../../_helpers/fetcher"
import { APIs } from "../../../_helpers/apis"
import cogoToast from "cogo-toast"
import axios from 'axios'
import { useHistory } from 'react-router'
import { useEffect } from 'react'
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import CardData from "./CardData"
// import * as Yup from "yup"
// import PayPalButton from "./PayPalButton";

export const Checkout = (props) => {
    const { handleNext } = props
    const { labelClass, inputClass, labelCardClass, labelEmailClass, head, submitBtn, InputRoot, vocalRowsWrapper, priceWrapper, paymentForm } = useStyles()
    // const { cart } = useSelector((state) => state.track)
    const { userInfo } = useSelector((state) => state.auth);
    const reduxSubscription = useSelector((state) => state.subscriptions)
    
    const history = useHistory();

    
    
    

    const [IsSubmitting, setIsSubmitting] = useState(false)
    // const [radioCard, setRadioCard] = useState(prevCardInfo !== undefined ? "Existing" : "New");
    // const cart_sum = useMemo(() => {
    //     return cart.reduce((r, a) => {
    //         return r + parseFloat(a["price"])
    //     }, 0)
    //         .toFixed(2)
    // }, [cart])

    const submitPayment = useCallback(async (values) => {
        let body = {
            ...values,
            // useExisitingCard: prevCardInfo?.id !== undefined && radioCard === "Existing",
            month: parseInt(values?.year.split("/")[0]),
            year: parseInt(values?.year.split("/")[1]),
            name: values?.name,
            // amount: cart_sum * 100,
            user_id: userInfo.user_id,
            subscriptionPlanId: reduxSubscription?.userSubscription?.id
            // track_ids: cart.map((obj) => obj.track_id)
        }
        setIsSubmitting(true)
        let res = await POST(APIs.subscriptionCheckout, body)

        if (res?.status === 200) {
            handleNext()
        } else {
            console.log(res?.error?.message)
            cogoToast.error("Card expiration date is invalid")
        }
        setIsSubmitting(false)
    }, [ handleNext, userInfo.user_id])



    const formik = useFormik({
        initialValues: initValues,
        validationSchema: schema,
        onSubmit: async (values, { resetForm }) => {
            if (IsSubmitting) return

            const updatedValues = {
                ...values,
                number: values.creditCard,
            };

            delete updatedValues.creditCard;

            submitPayment(updatedValues);


        }
    })

    const renderCheckoutField = () => {
        return formScript.slice(0, 7).map((field, index) => (
            <StyledGrid item xs={field.xs} md={field.md} key={index} className={InputRoot} style={{ padding: '0px' }}>
                <InputField
                    placeholder={field.placeholder}
                    name={field.name}
                    type={field.type}
                    formik={formik}
                    label={field.label}
                    style={{ ...field.style }}
                    classes={inputClass}
                    labelClass={field.label === "Card information" ? labelCardClass : labelClass}
                    isLightTheme={true}
                    inputImage={field.inputImage}
                    inputCvcImage={field.inputCvcImage}
                    value={field.type === "email" && userInfo.email}
                    autoComplete={field.name === "creditCard" && "cc-number"}
                />
            </StyledGrid>
        ))
    }


    return (
        <>
            <StyledContainer p={0} height="70vh" style={{ overflowX: "hidden !important", maxWidth: "100vw" }} id="doc-element">
                <StyledGrid container spacing={3}>
                    <StyledGrid item xs={12} md={6} className={paymentForm}>

                        <Box display="flex" width="100%" justifyContent="space-between" flexWrap="wrap">
                            <Typography variant="h5" className={head}>
                                Billing Information
                            </Typography>
                            <div style={{ width: '135px' }}><img src="media/poweredStripe.svg" height="35px" width="160px" alt="stripe" /></div>
                        </Box>


                        <StyledGrid container width="100%">

                            {renderCheckoutField()}

                            <StyledGrid container mx={0} my={1}>
                                <Button className={submitBtn} onClick={formik.handleSubmit} >
                                    {!IsSubmitting ? "Subscribe" : "Please wait ..."}
                                </Button>
                            </StyledGrid>
                        </StyledGrid>
                    </StyledGrid>

                    <StyledGrid item xs={12} md={6} className={priceWrapper}>
                        <div style={{display:"flex",justifyContent:"space-between"}} >
                            <div style={{display:"flex",gap:'10px'}}>
                                <div style={{ width:'80px',height:'80px', backgroundColor:'#FFFFFF',borderRadius:'10px',display:'flex',justifyContent:'center',alignItems:'center',}}>
                                <img src="media/logo-sm.png" alt="" />
                                </div>
                                <Typography variant="h6">
                                    <strong>{reduxSubscription?.userSubscription?.plan}</strong> 
                                    <br/> 
                                    <p>${reduxSubscription?.userSubscription?.price} /mo</p>
                                </Typography>                       
                            </div>
                            <Typography variant='p' 
                                        style={{color:'#57A4FF',cursor:'pointer'}}
                                        onClick={() => { (reduxSubscription?.userSubscription?.id)? history.push('/manage-subscription'):history.push('/plans') }}
                                        >Change plane</Typography>
                        </div>
                        <br/>
                        <br/>
                        <div style={{display:"flex",justifyContent: "space-between",alignItems: "center"}}>
                        <Typography variant="h6" style={{fontWeight:'bolder'}}>Total Price</Typography>
                        <Typography variant="h6" style={{fontWeight:'bolder'}}>${reduxSubscription?.userSubscription?.price}/mo</Typography>
                        </div>
                        {/* <Row jc="center">
                            <Typography variant="h6">Total Price</Typography>
                            <Typography variant="h6">$8.99/mon</Typography>


                        </Row> */}
                    </StyledGrid>
                </StyledGrid>
            </StyledContainer>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    cardStyle: {
        width: 180,
        height: 100,
        margin: "0px 2px",
        borderRadius: 3
    },
    paymentForm: {
        padding: "12px 98px!important",
        "@media (max-width:800px)": {
            padding: '12px!important'
        }
    },
    inputClass: {
        backgroundColor: "#F1F1F1",
        border: "1px solid lightgray",
        // borderRadius: 5,
        fontWeight:'bold',
        color:'black',
        width: "100%",
        "@media(max-width:500px)": {}
    },
    labelClass: {
        fontWeight: "bold",
        marginLeft: 0,
    },
    labelCardClass: {
        fontWeight: "bold",
        marginLeft: 0,
        marginTop: 20,
        marginBottom: 5,
    },
    labelEmailClass: {
        position: 'relative',
        top: 31,
        color: 'rgb(0, 0, 0)',
        zIndex: 1,
        paddingLeft: 10,
    },
    head: {
        fontWeight: "bold",
        // marginBottom: "1.5rem"
    },
    subHead: {
        fontWeight: "bold",
        margin: "15px 0px 10px 0px"
    },
    submitBtn: {
        backgroundColor: '#57A4FF',
        color: theme.palette.primary.contrastText,
        padding: "10px 25px",
        borderRadius: 5,
        marginTop: 20,
        width: "100%",
        textAlign: "center",
        fontWeight: "bold",
        textTransform: 'Capitalize',
        fontSize: '20px',
        "&:hover": {
            backgroundColor: '#0075ff',
            color: theme.palette.primary.contrastText
        },
    },
    total: {
        textDecoration: "underline"
    },
    mul: {
        fontSize: 16
    },
    InputRoot: {
        "& .MuiBox-root": {
            margin: "0px 0px"
        }
    },
    vocalRowsWrapper: {
        minWidth: "450px",
        "@media (max-width:450px)": {
            minWidth: "350px"
        }
    },
    priceWrapper: {
        marginTop: 14,
        "@media (max-width:800px)": {
            minWidth: 6
        }
    },
    headerWrapper: {
        margin: '25px 0 25px 0'
    }
}))
