/** OAUTH TOKEN TYPES */
export const GET_OAUTH_REQUEST = "GET_OAUTH_REQUEST"
export const GET_OAUTH_SUCCESS = "GET_OAUTH_SUCCESS"
export const GET_OAUTH_FAILED = "GET_OAUTH_FAILED"

/** stop loader */
export const STOP_LOADERS_REQUEST = "STOP_LOADERS_REQUEST"
export const STOP_LOADERS_SUCCESS = "STOP_LOADERS_SUCCESS"

export const OPEN_LOGIN_REQUEST = "OPEN_LOGIN_REQUEST"
export const OPEN_LOGIN_SUCCESS = "OPEN_LOGIN_SUCCESS"

export const CLOSE_LOGIN_REQUEST = "CLOSE_LOGIN_REQUEST"
export const CLOSE_LOGIN_SUCCESS = "CLOSE_LOGIN_SUCCESS"

export const OPEN_SIGNUP_REQUEST = "OPEN_SIGNUP_REQUEST"
export const OPEN_SIGNUP_SUCCESS = "OPEN_SIGNUP_SUCCESS"

export const CLOSE_SIGNUP_REQUEST = "CLOSE_SIGNUP_REQUEST"
export const CLOSE_SIGNUP_SUCCESS = "CLOSE_SIGNUP_SUCCESS"

export const SIDEBAR_REQUEST = "SIDEBAR_REQUEST"
