import * as React from "react"
import { List, ListItem, ListItemIcon } from "@material-ui/core"
import { SvgInline } from "../Svg"
import { Link, useLocation } from "react-router-dom"
import clsx from "clsx"
import { useStyles } from "./script"

export const DrawerList = ({ list, active, hover, open }) => {
  const location = useLocation()
  const { listText, listItem, icon, closeIcon, activeListText } = useStyles()

  

  return (
    <List style={{backgroundColor: '#333333', borderRadius:'5px' }}>
      {list.map((l, index) => (
        <ListItem
          component={Link}
          to={l.to}
          className={clsx(listItem, hover, {
            [active]: (l.to === '/profile'?(location.pathname === l.to || location.pathname === '/affiliate' || location.pathname === '/my-vocals'|| location.pathname === '/refer-friend'  ):(location.pathname === l.to))
          })}
          key={index}
          style={{paddingLeft: '12px', paddingRight: '12px'}}
        >
          <>
            <ListItemIcon
              className={clsx(icon, { [closeIcon]: !open })}
              style={{ maxWidth: "53px !important", minWidth: "53px !important", paddingLeft: "5px" }}
            >
              <span
                style={{
                  margin: "8px 5px 0px 0",
                  marginLeft: l.width && "-2px",
                  marginBottom: l.width && "5px"
                }}
              >
                <SvgInline src={(l.to === '/profile'?(location.pathname === l.to || location.pathname === '/affiliate' || location.pathname === '/my-vocals'|| location.pathname === '/refer-friend'  ):(location.pathname === l.to)) ? l.activeIcon : l.icon} w={l.height || "20px"} h={l.width || "20px"} />
              </span>
            </ListItemIcon>
            <span className={clsx(listText, { [activeListText]: location.pathname === l.to })}>{l.label}</span>
          </>
        </ListItem>
      ))}
    </List>
  )
}
