import { FaRegArrowAltCircleDown } from "react-icons/fa"
import { ImHeart } from "react-icons/im"

export const heading = ["#",  "Name", "Key", "BPM", "Time", "Waveform", "",""]
export const smHeading = ["Play", "", ""]

export const list = [
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound1.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound2.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound3.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound6.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound1.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound2.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound3.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound4.m4a"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound1.mp3"
  },
  {
    play: "",
    time: "",
    name: "",
    key: "",
    bpm: "",
    heart: ImHeart,
    download: FaRegArrowAltCircleDown,
    src: "/tracks/sound2.mp3"
  }
]
