import React from 'react'
import { ProfileContainer } from '../Profile/ProfileContainer'
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ReferalStep from './ReferalStep';
import './CSS/ReferFriend.css'
import { steps_detail } from './script';
import ReferalForm from './ReferalForm';


export const ReferFriend = () => {
    const { userInfo } = useSelector((state) => state.auth);
    let step_num=0;

  return (
    <ProfileContainer
        activeTab='tab3'
    >
        <Box className='fr-container'>
            <Typography style={{fontWeight:'bolder'}} variant='h4'>
                <Typography variant='h4' style={{display:'inline',color:'#57A4FF',fontWeight:'bolder'}}>
                    {userInfo.full_name}
                </Typography>
                , earn coins by referring friends to Warbls
            </Typography>
            <Typography style={{fontWeight:'bold'}} variant='h5'>Get <img src={`${process.env.PUBLIC_URL}/images/CoinsRefer.png`} style={{marginLeft:'5px'}}/>  25 coins for each referral. </Typography>
            <Box className='rf-steps-container'>
                {   
                    steps_detail.map((step) => { 
                            step_num++;
                        return(
                            <ReferalStep
                                num={step_num}
                                heading={step.heading}
                                detail={step.detail}
                            />
                        )
                     })
                }
            </Box>
            <Box className='rf-form-container'>
                <ReferalForm/>
            </Box>
            <Box className='rf-earned-container'>
                <Typography className='total-earning' variant='h6'>
                Total coins earned 
                    <Box
                        style={{
                            display:'flex',
                            alignItems:'center',
                            gap:'5px'
                        }}
                    > 
                        <img src={`${process.env.PUBLIC_URL}/images/CoinsRefer.png`} className='image'/> 50 </Box>
                </Typography>
            </Box>
            <Box className='rf-referal'>
                <StarRoundedIcon/>
                <Typography variant='h6'>Referrals</Typography>
            </Box>
            <Box className='rf-email-status'>
                <Typography variant='h6'>Email</Typography>
                <Typography variant='h6'>Status</Typography>
            </Box>
        </Box>
    </ProfileContainer>
  )
}

