import { Box,Typography } from '@material-ui/core'
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import React from 'react'

const Contactus = () => {
  return (
    <Box style={{
        display:"flex",
        flexDirection:'column',
        gap:'10px',
        // marginBottom:'13%'
        
    }}>
        <Typography component="h1" variant="h3" style={{fontWeight:800,color:'#57A4FF'}}>
            Contact Us
        </Typography>
        <Typography variant='p' style={{fontWeight:800}}>
        We would love to hear from you!<br/> Send us any questions or suggestions <br/> and we write you back the same day.
        </Typography>
        <Box style={{
            display:'flex',
            gap:"10px"
        }}>
            <EmailOutlinedIcon/>
            <Typography variant='p'>
                support@warbls.com
            </Typography>
        </Box>
        <Box style={{
            display:'flex',
            gap:'10px'
        }}>
            <InstagramIcon/>
            <FacebookIcon/>
            <YouTubeIcon/>
        </Box>
    </Box>
  )
}

export default Contactus