import * as React from "react"
import { Box, makeStyles, InputBase, Typography } from "@material-ui/core"
import { useState, useRef, useContext } from "react"
import { FaSearch } from "react-icons/fa"
import clsx from "clsx"
import { key, genres, popular } from "./script"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import RangeSlider from "../RangeSlider/index"
import { AppContext } from "../../providers/AppProvider"

export const Filter = ({ filters, setFilters, refreshFilters, updateFilters, setUpdateFilters, appliedFilters, clearFilterData, removeFilter, filteredGenres }) => {
  const { root, search, searchIcon, inputRoot, inputInput, langs, phraseBox, typo, phraseBox1, phrase, active, refreshBox, filterDesc } =
    useStyles()

  const history = useHistory()
  const { auth } = useSelector((state) => state.auth)
  const cart = useSelector((state) => state.track.cart || [])
  const [bpmValue, setBpmValue] = useState([50, 200])
  const [showBpmTooltip, setShowBpmTooltip] = useState(false)

  const bpmref = useRef(null)
  const { handleSignup } = useContext(AppContext)

  const handleBPMFilters = (e) => {
    if (bpmValue[0] > 50) {
      setFilters({ ...filters, bpm: bpmValue })
    }
    setShowBpmTooltip(false)
  }

  const viewCart = () => {
    if (auth) {
      history.push(`/cart`)
    } else {
      handleSignup()
    }
  }

  return (
    <Box>
      <Box className={root}>

      <div className={search}>
          <InputBase
            onChange={({ target }) => {
              setFilters({
                 ...filters,
                searchKey: target.value
              })
            }}
            placeholder="Search for vocals"
            value={filters?.searchKey}
            classes={{
              root: inputRoot,
              input: inputInput
            }}
            inputProps={{ "aria-label": "search" }}
          />
          <div className={searchIcon}>
            <FaSearch />
          </div>
        </div>


        

          {/* Phrases */}
          
        {/* <Box display="flex" flexDirection="column" style={{ rowGap: "5px", margin: "0 0 0 3px" }}> */}
          <Box
            className={clsx(phraseBox1, phrase, "pointer", {
              [active]: filters?.isPhrases === "1" && filters?.isOneshot === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isPhrases: filters?.isPhrases === "1" && filters?.isOneshot === "0" ? "" : "1",
                isOneshot: filters?.isPhrases === "1" && filters?.isOneshot === "0" ? "" : "0"
              })
            }}
          >
            <Box className="" width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Phrases</Typography>
            </Box>
          </Box>

          {/* One shote */}
          <Box
            className={clsx(phraseBox1, phrase, "pointer", {
              [active]: filters?.isOneshot === "1" && filters?.isPhrases === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isPhrases: filters?.isOneshot === "1" && filters?.isPhrases === "0" ? "" : "0",
                isOneshot: filters?.isOneshot === "1" && filters?.isPhrases === "0" ? "" : "1"
              })
            }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>One-Shots</Typography>
            </Box>
          </Box>
        {/* </Box> */}

        {/* <Box display="flex" flexDirection="column" style={{ rowGap: "5px", margin: "0 0 0 3px" }}> */}
          <Box
            className={clsx(phraseBox1,phrase, "pointer", {
              [active]: filters?.isVocalchops === "1" && filters?.isStutter === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isVocalchops: filters?.isVocalchops === "1" ? "0" : "1",
                isStutter: filters?.isVocalchops === "1" ? "0" : "0"
              })
            }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Vocal Chops</Typography>
            </Box>
          </Box> 
          <Box
            className={clsx(phrase, "pointer", {
              [active]: filters?.isStutter === "1" && filters?.isVocalchops === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isStutter: filters?.isStutter === "1" ? "0" : "1",
                isVocalchops: filters?.isStutter === "1" ? "0" : "0"
              })
            }}
            
            style={{width:'5rem'}}

          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Stutter</Typography>
            </Box>
          </Box>
          {/* <Box
            className={clsx(phrase, "pointer", {
              [active]: false//filters?.isWet === "1"
            })}
            // onClick={() => {
            //   setFilters({
            //     ...filters,
            //     isWet: filters?.isWet === "1" ? "0" : "1"
            //   })
            // }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Ad-libs</Typography>
            </Box>
          </Box> */}
            </Box>
          <Box className={root}> 
        {/* </Box> */}
        <Select
          options={popular}
          classes={langs}
          value={filters?.trending}
          setHandle={(value) => {
            setFilters({
               ...filters,
              trending: value
            })
          }}
        />

        
          
          {/* Dry */}
       

        {/* <Box display="flex" flexDirection="column" style={{ rowGap: "5px", margin: "0 0 0 3px" }}> */}
          <Box
            className={clsx(phrase, "pointer", {
              [active]: filters?.isDry === "1" && filters?.isWet === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isDry: filters?.isDry === "1" ? "0" : "1",
                isWet: filters?.isDry === "1" ? "0" : "0"
              })
            }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Dry</Typography>
            </Box>
          </Box>

          {/* Wet */}
          <Box
            className={clsx(phrase, "pointer", {
              [active]: filters?.isWet === "1" && filters?.isDry === "0"
            })}
            onClick={() => {
              setFilters({
                ...filters,
                isWet: filters?.isWet === "1" ? "0" : "1",
                isDry: filters?.isWet === "1" ? "0" : "0"
              })
            }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Wet</Typography>
            </Box>
          </Box>
        {/* </Box> */}
        
        {/* <Box
            className={clsx(phrase, "pointer", {
              [active]: false//filters?.isWet === "1"
            })}
            // onClick={() => {
            //   setFilters({
            //     ...filters,
            //     isWet: filters?.isWet === "1" ? "0" : "1"
            //   })
            // }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Male</Typography>
            </Box>
          </Box> */}

          {/* Felmale */}
          {/* <Box
            className={clsx(phrase, "pointer", {
              [active]: false//filters?.isWet === "1"
            })}
            // onClick={() => {
            //   setFilters({
            //     ...filters,
            //     isWet: filters?.isWet === "1" ? "0" : "1"
            //   })
            // }}
          >
            <Box width="100%" justifyContent="center" display="flex">
              <Typography className={typo}>Female</Typography>
            </Box>
          </Box> */}


       

        


            {/* Generes filter */}
        <Select
          options={filteredGenres.sort((a, b) => a.label.localeCompare(b.label))}
          classes={langs}
          value={filters?.genre}
          setHandle={(value) => {
            setFilters({
              ...filters,
              genre: value
            })
          }}
        />

        <Select
          options={key.sort((a, b) => a.label.localeCompare(b.label))}
          classes={langs}
          value={filters?.key}
          setHandle={(value) => {
            setFilters({
              ...filters,
              key: value
            })
          }}
        />

        <div className="bpm-wrapper">
          <button className="bpm-button" onClick={() => setShowBpmTooltip(true)} ref={bpmref}>
            BPM
          </button>
          <div className={`bpm-tooltip ${showBpmTooltip && "active"}`}>
            <div>
              <h6>
                {bpmValue[0]}-{bpmValue[1]}
              </h6>
              <RangeSlider value={bpmValue} setValue={setBpmValue} />
              <button className="apply-button" onClick={handleBPMFilters}>
                Apply
              </button>
            </div>
          </div>
        </div>

       

{/*        
        <Box className={clsx(phraseBox, "pointer")} onClick={viewCart} mx={0.5}>
        

       

        {/* <Box className={clsx(phraseBox, "pointer")} onClick={viewCart} mx={0.5}>
          <Box width="100%" justifyContent="center" display="flex" px={1}>
            <Typography
              style={{
                borderRight: "1px solid white",
                margin: "-5px 0"
              }}
            >
              <p
                style={{
                  padding: "15px 10px 15px 3px",
                  alignSelf: "center",
                  color: "white"
                }}
              >
                {cart.length}
              </p>
            </Typography>

            <Typography className={typo} style={{ padding: "0 0 0 20px", alignSelf: "center" }}>
              View Cart
            </Typography>
          </Box>
        </Box> */}

      </Box>

      <Box display="flex" justifyContent="flex-start" width="100%" style={{ columnGap: "15px", flexWrap: "wrap" }}>
        {appliedFilters?.map((filter) => (

          <Box key={filter}>
            <Typography className={filterDesc}>
              <span style={{ maxWidth: "180px", overflow: "hidden", whiteSpace: "nowrap" }}>{filter} </span>&nbsp;
              <img src="/media/close_2.png" alt="close img" onClick={() =>removeFilter(filter)} />
            </Typography>
          </Box>
          ))}

        {appliedFilters?.length > 0 && (
          <Box onClick={clearFilterData}>
            <Typography className={refreshBox}>
              <img src="/media/close.png" style={{ backgroundColor: "#D9D9D9", padding: "3px 2px", borderRadius: "5px " }} alt="delete img" />
              &nbsp; &nbsp;Clear filters
            </Typography>
          </Box>
        )}
      </Box>


    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  

  langs: {
    width: "13.5%",
    padding: "0 3rem",
    "@media screen and (max-width: 1500px)": {
      width: "12%",
      minWidth: "12%"
    }
  },
  root: {
    display: "flex",
    flexDirection: "row",
    gap:"10px",
    // justifyContent: "space-between",
    width: "60%",
    
    flexWrap:"wrap",
    "@media screen and (max-width: 1250px)": {
      flexWrap: "wrap"
    },
    height: "34px",
    gap: '7px',
    marginBottom: '11px'
    // flexWrap: "wrap"
  },
  filterBox: {
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    borderRadius: 5,
    width: "100%",
    display: "flex",
    // justifyContent: "space-between",
    padding: 0,
    margin: 0,
    marginLeft: "3px",
    "@media screen and (max-width: 1250px)": {
      width: "100%",
      border: "1px solid white",
      columnGap: "1rem"
    }
  },
  search: {
    borderRadius: 5,
    // margin: " 0 4px",
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    border: "1px solid " + theme.palette.primary.contrastText,
    display: "flex",
    justifyContent: "space-between",

    width: "5rem%",
    height:"2rem",
    [theme.breakpoints.up("sm")]: {
      width: "auto"
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,

    [theme.breakpoints.down("md")]: {
      height: "40.63px"
    }
  },
  inputRoot: {
    color: "inherit",
    width: "100%"
  },
  inputInput: {
    padding: "8px 8px",
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "12px 8px"
    }
  },
  phrase: {
    borderRadius: 5,
    display: "flex",
    padding: 2,
    border: "1px solid white",
    alignItems: "center",
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    // border: "1px solid " + theme.palette.primary.contrastText,
    boxSizing: "border-box",
    width: "4rem",
    height:"2rem"
    // margin: "0 25px"
  },
  phraseBox: {
    borderRadius: 5,
    display: "flex",
    padding: 2,
    border: "1px solid white",
    alignItems: "center",
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    // border: "1px solid " + theme.palette.primary.contrastText,
    boxSizing: "border-box",
    width: "150px"
  },
  phraseBox1: {
    width: "6rem",
    margin: "0"
  },
  typo: {
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    // border: "1px solid " + theme.palette.primary.contrastText,
    "&.MuiTypography-body1": {
      fontSize: 12,
      fontWeight: 500,

      backgroundColor: "transparent",
      color: theme.palette.primary.contrastText,
      // border: "1px solid " + theme.palette.primary.contrastText,
      wordSpacing: 5,
      "@media screen and (max-width: 1250px)": {}
    }
  },
  active: {
    boxSizing: "border-box",
    border: "2px solid #86DB78"
  },
  selectStyle: {
    width: "24%",
    border: "0px",
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 5
  },
  refreshBox: {
    backgroundColor: "transparent",
    color: "white",
    padding: "2px 3px",
    textAlign: "center",
    borderRadius: "5px",
    margin: "18px 0 0 0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    columnGap: "4px"
  },
  filterDesc: {
    backgroundColor: "transparent",
    color: "white",
    padding: "1px 10px",
    textAlign: "center",
    borderRadius: "5px",
    border: "1px solid white",
    margin: "18px 5px 0 2px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    columnGap: "4px"
  },
  select: {
    backgroundColor: "transparent",
    color: theme.palette.primary.contrastText,
    border: "1px solid " + theme.palette.primary.contrastText,
    borderRadius: 5,
    margin: "0 1px 0 1px",
    // border: `1px solid ${theme.palette.secondary.main}`,
    padding: "6px 7px",
    width: "6rem",
    height:"2rem",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      margin: "5px 0px",
      padding: "12px 7px"
    }
  }
}))

export const Select = ({ options, value, haddleQueryParams, setHandle, type }) => {
  const { select, selectStyle } = useStyles()
  const [bpmValue, setBpmValue] = useState([0, 100])

  return (
    <>
      {type === "bpm" ? (
        <div style={{ width: "100px" }}>
          <RangeSlider value={bpmValue} setValue={setBpmValue} />
        </div>
      ) : (
        <select value={value} onChange={({ target }) => setHandle(target.value)} className={clsx(select, selectStyle)}>
          {options.map((o, index) => (
            <option key={index} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      )}
    </>
  )
}