import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MoreCoinRow from './MoreCoinRow';
import { moreCoinsInfo } from './script';
import { POST } from "../../../_helpers/fetcher"
import { APIs } from "../../../_helpers/apis"
import { useSelector } from 'react-redux'

const MoreCoins = (props) => {
  const {handleClose} = props
  const [selectRow, setSelectRow] = useState({coin:'0',price:'0'})
  const [confirmDialogue, setConfirmDialogue] = useState(false)
  const { userInfo } = useSelector((state) => state.auth)

  const handleContinue = () => { setConfirmDialogue(!confirmDialogue) }
  const purchaseCoins = async (data) => {
    const body = {
      "productName": `(${data.coin} coins)`,
      // "productImg": "https://www.warbls.com/media/warbls-logo.png",
      "coins": data.coin,
      "user_id": userInfo.user_id,
      "price": data.price,
      "paymentMode": "payment", //"payment",
      "successUrl": "https://www.warbls.com/vocals?status=succeed",
      "cancelUrl": "https://www.warbls.com/vocals?status=failed"
    }
    let res = await POST(APIs.stripeCheckoutSession, body);
    window.location.href = res.data
  }
  
  return (
    <div style={{backgroundColor:'#202B38',borderRadius:'5px',padding:'20px',display:'flex',flexDirection:'column',gap:'10px'}}>
      
      <div>
        <CloseRoundedIcon style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={handleClose}/>
      </div>
      <div >
          <Typography style={{fontWeight:'bolder'}} variant='h5'>Buy More Coins</Typography>
      </div>
      {
        confirmDialogue?(
          <>
           <div>
              <Typography style={{fontWeight:'bolder',color:'#57A4FF'}} variant='h6'>Confirm your purchase</Typography>
          </div>
           <div>
              <Typography style={{color:'#fff'}} variant='p'>Your saved payment method will be charged instantly upon confirmation.
              </Typography>
          </div>
           <div>
              <Typography style={{color:'#929292'}} variant='p'>Note: you can only use your coins with an active subscription.</Typography>
          </div>

          <div>         
                                      <MoreCoinRow
                                          coins={selectRow.coin}
                                          price={selectRow.price}
                                          setSelectRow={() => {  }}
                                          selectRow={false}
                                          isHover={false}
                                                 />         
          </div>
          <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',gap:'20px'}}>
        <div onClick={handleContinue}
           style={{display:'flex',cursor:'pointer'}}>
          <Typography variant='p'>Back</Typography>
          
        </div>
        <div onClick={() => {  }}
           style={{cursor:'pointer',backgroundColor:'#57A4FF',borderRadius:'5px',padding:'10px 20px'}}>

          <Typography variant='p' style={{fontWeight:'bolder'}} onClick={() => {purchaseCoins(selectRow)}}>Purchase</Typography>
        </div>

      </div>
          


          </>
        ):(
          <>
      <div>

          <Typography style={{fontWeight:'bolder',color:'#57A4FF'}} variant='h6'>Select amount of coins</Typography>

      </div>
      <div style={{display:'flex',flexDirection:'column',gap:'10px'}}>
        {
          moreCoinsInfo.map((info,index) => 
                                      <MoreCoinRow
                                          coins={info.coins}
                                          price={info.price}
                                          setSelectRow={setSelectRow}
                                          selectRow={selectRow}
                                          isHover={true}
                                                 /> )
        }
        
      </div>
      <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',gap:'20px'}}>
        <div onClick={handleClose}
           style={{display:'flex',cursor:'pointer'}}>
          <Typography variant='p'>Back</Typography>
          
        </div>
        <div onClick={() => { 
          if(selectRow.coin != '0') 
              handleContinue()
        }}
           style={{cursor:'pointer',backgroundColor:'#57A4FF',borderRadius:'5px',padding:'10px 20px'}}>

          <Typography variant='p' style={{fontWeight:'bolder'}}>Continue</Typography>
        </div>

      </div>
      </>
        )
      }
      


    </div>
  )
}

export default MoreCoins