import "./styles.css"

import React, { Component } from "react"
import Pagination from "react-js-pagination"

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activePage: 1
    }
  }

  handlePageChange(pageNumber) {
    if (this.props.auth) {
      this.setState({ activePage: pageNumber })
      this.props.setPageNo(pageNumber)
      this.props.setPaginationRange([(pageNumber - 1) * 20, pageNumber * 20])
    } else this.props.handleSignUp()
  }

  render() {
    return (
      this.props.total >= 19 && (
        <div className="pagination-wrapper">
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={20}
            innerClass="pagination-list"
            totalItemsCount={this.props.total}
            activeClass="active-page"
            nextPageText="Next  | > "
            prevPageText="Previous  | <"
            itemClassFirst="first-class"
            itemClassLast="last-class"
            itemClassNext="next-class"
            itemClassPrev="prev-class"
            lastPageText="Last"
            firstPageText="First"
            pageRangeDisplayed={3}
            onChange={this.handlePageChange.bind(this)}
          />
        </div>
      )
    )
  }
}
