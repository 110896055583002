import { Container } from '@material-ui/core'
import { Typography } from '@mui/material'
import './CSS/subsInfo.css'
import React from 'react'

const PlanCard = (props) => {
    const {setCardID,setPrompt,ID,coins,price} = props
    const handleClick = event => {
       setCardID(event.currentTarget.id);
       setPrompt(true);
      };
    



  return (
    <div className='plan-card-continer' id={ID}
        onClick={handleClick}
    >
        <div className='coins-container'>
            <img src="media/coins.png" alt="" />

        </div>
        <div className='plan-card-heading'>
            <Typography style={{fontSize:'1rem',fontWeight:'bold'}} variant='p'>
                {coins} coins
            </Typography>
        </div>
        <div className='plan-card-price'>
            <Typography color={'#B4B4B4'} variant='p'>
                $ {price}
            </Typography>
        </div>
    </div>
  )
}

export default PlanCard