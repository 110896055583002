import { ContentContainer } from "../../../_warbls/components/Container"
import * as React from "react"
import { Box, makeStyles } from "@material-ui/core"
import ProfileMenu from "./ProfileMenu"
import './CSS/index.css'

export const ProfileContainer = ({ activeTab,children }) => {
  const { bg, success, danger, profileRoot, info, saveBtnContainer, saveBtn } = useStyles()

  return (
    <ContentContainer
      titleLeft="25px"
      type="profile"
      titleBackground="linear-gradient(to right,#3A5B5D , #89BABD, #5E8A8D )"
      title={"Profile"}
      profile={true}
    >
      <Box style={{ padding: "0 25px", display: "flex", justifyContent:'start' }} pt={0} className={`${profileRoot} profile-main-container`}>
        <Box className="profile-Menu-main-container"  style={{}}>
          <ProfileMenu  
            activeTabID={activeTab}
            />
        </Box>
          {children}
        </Box>
    </ContentContainer>
  )
}

const useStyles = makeStyles((theme) => ({
  bg: {
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  success: {
    color: "#31E921",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center"
  },
  info: {
    color: "#31DAFF",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center"
  },
  danger: {
    color: "#FF0000",
    fontSize: 17,
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "center"
  },
  profileRoot: {
    // width: "100%",
    minHeight: "90vh",
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      marginTop: "0rem"
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "4rem",
      marginBottom: 50
    }
  },
  saveBtnContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "start"
  },
  saveBtn: {
    background: "#EFEFEF",
    border: "1px solid #FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    color: "black"
  }
}))
