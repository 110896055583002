import { useState, useEffect } from "react"
import { makeStyles, Stepper, Step, StepLabel, Box,Typography } from "@material-ui/core"
import clsx from "clsx"
import axios from "axios"
// import { Cart } from "./ViewCart"
import { Checkout } from "./Checkout"
import { Confirmation } from "./Confirmation"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { ContentContainer } from "../../../_warbls/components/Container"
import { useSelector } from "react-redux"
import { APIs } from "../../../_helpers/apis"
import { Popup } from "../../../_warbls/components/Popup"
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { useHistory } from "react-router"


export const CheckoutPage = (props) => {
  const classes = useStyles()
  const { auth } = useSelector((state) => state.auth)
  const [ prevCardInfo, setPrevCardInfo ] = useState()
  const [activeStep, setActiveStep] = useState(1)
  const steps = getSteps()
  const [openDialogue, setopenDialogue] = useState(false)
  const history = useHistory()


//   const cart = useSelector((state) => state.track.cart || [])
  const handleNext = () => {
    // if (cart.length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    // }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  useEffect(() => {
    activeStep == 2? setopenDialogue(true):setopenDialogue(false)  
  }, [activeStep])
  
 const handleDialogue = () => { 
    setopenDialogue(!openDialogue)
  }

//   useEffect(()=>{
//     if(!auth) return;

//     axios.get(APIs.getCardInfo, {
//       headers: {
//         "Auhtorization" : auth
//       }
//     }).then(resp => {
//       if ( !resp.data || !resp.data?.data || resp.data === '' || Object.keys(resp.data).length === 0 || resp.data === undefined)
//         return
//       else
//         setPrevCardInfo(resp.data.data)
//     })
//   }, [auth])

  return (
    
    <Box p={0} width="100%">
      {/* {activeStep === 2 ? (
        <ContentContainer titleLeft="38%" titleBackground={"#48E12F"} title="Thank You!" pageType="payment" thankYou={true}>
          <Confirmation handleReset={handleReset} handleBack={handleBack} />
        </ContentContainer>
      ) : ( */}
        <ContentContainer
          titleBackground={activeStep === 1 ? "linear-gradient(89.25deg, #203947 0.65%, #57A4FF 159.11%, #48E12F 176.51%, #7ED1FF 176.51%)" : "linear-gradient(89.25deg, #203947 0.65%, #57A4FF 159.11%, #48E12F 176.51%, #7ED1FF 176.51%)"}
          title={activeStep === 1 ? "Checkout" : "Confirmation"}
          pageType="payment"
        >
          <StyledContainer>
            <div className={classes.root} style={{ width: "80%" }}>
              <Box className={classes.stepperConatainer}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel style={{ cursor: "pointer", fontWeight: "bold" }} StepIconComponent={() => StepIcon({ step: index, activeStep })}>
                        {label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
              <div className={clsx(activeStep === 0 && classes.hideOverflow)}>
                <Checkout handleNext={handleNext} handleBack={handleBack} prevCardInfo={prevCardInfo} />
                <Popup open={openDialogue} handleClose={handleDialogue}>
                
                  <div>
                    <div style={{display:'flex',cursor:'pointer',justifyContent:'flex-end'}}>
                      <CloseRoundedIcon style={{position:'absolute',top:'5px',right:'5px'}} onClick={() => { history.push("/") }}/>
                    </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'15px'}}>
                    <div style={{backgroundColor:'#fff', borderRadius:'5px',padding:'10px'}}>
                      <img src={`${process.env.PUBLIC_URL}/images/coin-stack.png`} style={{width:'25px', height:'25px'}} />
                    </div>
                    <Typography veriant='p' style={{fontWeight:'bolder',color:'#fff'}}>
                      Enjoy your coins
                    </Typography>
                  </div>
                </div>
        
                </Popup>
                {/* // <Cart handleNext={handleNext} handleBack={handleBack} /> */}
                {/* {activeStep === 1 ? (
                  <>
                  </>
                ) : activeStep === 2 && (
                    <Confirmation handleReset={handleReset} handleBack={handleBack} />
                ) } */}
              </div>
            </div>
          </StyledContainer>
        </ContentContainer>
      {/* )} */}
    </Box>
  )
}

const StepIcon = (props) => {
  const { step, activeStep } = props
  return (
    <div>
      <p
        style={{
          padding: "1px 7px",
          color: "black",
          backgroundColor: step === activeStep ? "white" : "gray",
          borderRadius: "2px"
        }}
      >
        {step + 1}
      </p>
    </div>
  )
}

function getSteps() {
  return ["View plans","Checkout", "Confirmation"]
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "0 auto",

    "& .MuiPaper-root": {
      backgroundColor: "#fff0",
      padding: "3rem 0",
      position: "relative",
      left: "0px"
    },
    "@media(max-width:1280px)": {
      marginTop: 50
    },
    "@media(max-width:550px)": {
      width: "100% !important",
      maxWidth: "100vw"
    }
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  stepperConatainer: {
    width: "35%",
    marginLeft: "5%",

    "@media (max-width:1000px) ": {
      width: "100%"
    },
    "@media (max-width:420px) ": {}
  },
  hideOverflow: {
    "@media (max-width:500px)": {
      maxWidth: "450px !important",
      display: "flex",
      justifyContent: "center",
      margin: "0 auto"
    },
    "@media (max-width:400px)": {
      maxWidth: "330px !important"
    }
  }
}))
