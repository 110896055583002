import { Box } from '@material-ui/core'
import React from 'react'
import './CSS/Affiliate.css'

const AffiliateStep = (props) => {
   const {image,heading,detail} = props

  return (
    <Box className='affiliate-step-container'>
        <Box className='affiliate-image'>
            <img src={`${process.env.PUBLIC_URL}/images/Affliate_images/${image}`} className='image'/>
        </Box>
        <Box className='step-heading'>
            {heading}
        </Box>
        <Box className="step-detail">
            {detail}
        </Box>
    </Box>

  )
}

export default AffiliateStep