import React from 'react'
import { ProfileContainer } from '../Profile/ProfileContainer'
import { Box, Typography } from '@material-ui/core'
import AffiliateStep from './AffiliateStep'
import './CSS/Affiliate.css'
import { affiliate_steps } from './script'

export const Affiliate = () => {
    let num=0;

  return (
    <ProfileContainer
        activeTab='tab4'
    >
        <Box className='affiliate-container'>
            <Box className='affiliate-heading'>
                <Typography style={{
                    fontWeight:'bolder'
                }} variant='h3'>
                    Get <span style={{color:'#38BC22'}}>cash</span> by driving traffic to Warbls
                </Typography>
            </Box>
            <Box className='affiliate-sub-heading'>
                <Typography variant='h5'>
                Promote the #1 website for vocals for music producers. Get paid to drive traffic. It’s that easy!
                </Typography>
            </Box>
            <Box className='affiliate-btn'>
                <button className='start-btn'>
                    <Typography style={{
                        fontSize:'1rem',
                        fontWeight:'bold',
                        color:'#fff'
                    }}>
                        Start earning now
                    </Typography>
                </button>
            </Box>
            <Box className='affiliate-para'>
                <Typography variant='p'>We've forged a strategic partnership with Rewardful, seamlessly integrated with the Stripe and PayPal payment systems.</Typography>

            </Box>
            <Box className="affiliate-steps">
                {
                    affiliate_steps.map((step) => { 
                        num++;
                        return(
                           <>
                            <AffiliateStep
                                image={step.image}
                                heading={step.heading}
                                detail={step.detail}
                                />
                            {num!=3 && <Box className='affiliate-arrow'>
                                    <img src={`${process.env.PUBLIC_URL}/images/Affliate_images/RightArrow.png`}/>
                                </Box>
                            }
                            </> 
                        )
                     })
                }
            </Box>
        </Box>
    </ProfileContainer>
  )
}

