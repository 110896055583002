export const formScript = [
  // { name: "username", type: "text", placeholder: "4+ characters", label: "Username" },
  { name: "full_name", type: "text", placeholder: "John Smith", label: "Full name or artist name" },
  { name: "email", type: "text", placeholder: "j.smith@example.com", label: "Email" },
  { name: "password", type: "password", placeholder: "7+ characters", label: "Password" }
]

export const initValues = {
  password: "",
  email: "",
  username: "",
  full_name: "",
  social_login: false,
  social_id: "",
  profile_img: "",
  social_platform: ""
}
