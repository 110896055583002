export const QAs = [
  {
    qa: [{
      q: "Are all vocals on Warbls royalty-free?",
      a: "Yes. You have a royalty-free license for every vocal you purchase on Warbls.com. This means that you dont have to pay royalties to ether ous at Warbls or the artist. So you dont have to worry about paying the creator, other than the initial price of the vocal. ",
    }, {
      q: "Are all vocals on Warbls non-exclusive?",
      a: "Yes. This means that other users can also use the same sound as you without infringing on any New recording or Creative Works you create using the vocal, and vice versa.",
    },
    {
      q: "Do I have 100% owernship of the composition I have made with vocals froms Warbls?",
      a: "You have ownership of your New Recording and Creative Work made with vocals from Warbls, but you dont own the exclusive rights to the vocals. Other users from Warbls can use the same vocals you have used without infringing on your track and vice versa.",
    },
    {
      q: "What/who do I put under “vocalist” or for the lyrics when registering a song containing a Warbls vocal with a PRO (publishing rights organization)?",
      a: "You are the owner of any New Recordings you've made using sounds from Warbls, however, you have a license to, not ownership of, those sounds (meaning other users can also use those sounds in their own New Recordings). While you are required to disclose that you are using audio samples in your New Recording, you do not need to credit Warbls or the sample creator as they are not a co-owner of your track.",
    },
     {
      q: "Can I feature the artist in the song title?",
      a: "No, you can not include the artist name in any form.",
    },
    {
      q: " Can I resell vocals?",
      a: "No, that is not allowed. If this happens, legal action will be one of the consequences.",
    }
  ]
  }
]

