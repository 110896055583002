import * as React from "react"
import {Typography} from "@material-ui/core"

const ManageCards = (props) => {
    const {heading,content,btnContant,coins,duration,date,btnOnClick}= props
  
  return (
    
      <div style={{display:'flex',flexDirection:'column',width:'325px',gap:'20px'}}>
        <Typography variant="h6" style={{fontWeight:'bolder'}} >
            {heading}
        </Typography>
        <div  style={{borderRadius:'5px',backgroundColor:'#212121',padding:'20px'}}>
           {
            (heading === "Your Pro Plan")?(
                <div style={{display:'flex',justifyContent:'center',gap:'10px'}} >
                <img src={`${process.env.PUBLIC_URL}/images/coin-stack.png`} style={{width:'20px',height:'20px',marginTop:'10px'}} />
                <div style={{display:'flex',columnGap:10,justifyContent:'center',alignItems:"baseline"}}>
                    {/* {content} */}
                    <Typography 
                    style={{
                        fontSize:'3rem',
                        fontWeight:'bolder'
                    }} variant="p">
                        {coins}
                    </Typography>
                    <div style={{fontWeight:'bolder',fontSize:'1.1rem',color:'#686868'}}>
                        /{duration}
                    </div>
                </div>
            </div>
            ):(
                
                    (heading === "Support")?(
                        <div>
                            {content}
                        </div>
                    ):(
                        <div>
                            Renewal Date: {date}<br/>
                            Billing Period: {duration}ly
                        </div>
                    )
                
                
            )
           }
            {
                (heading === "Support")?(
                    <div
                    style={{ color:"#57A4FF", padding:'15px 0',textAlign:'left',fontWeight:"bolder",fontSize:'1rem',borderRadius:'5px',marginTop:'20px'}}
                    >
                        {btnContant}
                       
                    </div>

                ):(
                    <div onClick={btnOnClick} 
                      style={{backgroundColor:heading === "Your Pro Plan"? "#57A4FF":"#333333",padding:'10px 0',textAlign:'center',fontWeight:"bolder",fontSize:'1.1rem',borderRadius:'5px',marginTop:heading !== "Your Pro Plan" &&'30px',cursor:'pointer',color:heading === "Your Pro Plan" && "#000"}}>  
                <Typography variant="p">
                    {btnContant}
                   
                </Typography>
            </div>
                )
            }
            
        </div>
        
      </div>
   
  )
}




export default ManageCards;