// the problematic area is the WaveForm

import { Button, makeStyles, Typography } from "@material-ui/core"
import { useState, useEffect, useContext, useCallback, useRef, useLayoutEffect } from "react"
import { Row } from "../../../_warbls/components/Flex/Row"
import { Column } from "../../../_warbls/components/Flex/Column"
import WaveFramer from "../../../_warbls/components/TrackFramer/index"
import { useSelector, useDispatch } from "react-redux"
import { actions } from "../../../redux/track/actions"
import { AppContext } from "../../../_warbls/providers/AppProvider"
import Pagination from "../../../_warbls/components/Pagination"


export const Table = ({
  tracks,
  setContainer,
  setCurrent,
  container,
  current,
  sideBarActivation,
  setSideBarActivation,
  currentVocal,
  setCurrentVocal,
  updateTrackStatus,
  handlePublish,
  handleDelete
}) => {
  const { table, deleteBtn, btn, typo, img, helperText, title, downloadStyle, tableContainer } =
    useStyles()

  const dispatch = useDispatch()
  const { auth, userInfo } = useSelector((state) => state.auth)
  const [load, setLoad] = useState(true)
  const childRef = useRef()
  const [size, setSize] = useState([0, 0])
  const [waveform, setWaveform] = useState()
    

  const [resortedTracks, setResortedTracks] = useState([])

  const [durationList, setDurationList] = useState([])
  const [pageNo, setPageNo] = useState(1)
  const [paginationRange, setPaginationRange] = useState([(pageNo - 1) * 20, pageNo * 20])
  const { handleSignup } = useContext(AppContext)


  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => window.removeEventListener("resize", updateSize)
  }, [])

  useEffect(() => {
    setResortedTracks(tracks)
  }, [tracks, resortedTracks])

  const generateDuration = useCallback((dur) => {
    const fixed = dur?.toFixed(0)
    const val = fixed?.split(".")[0]
    const mins = val > 60 ? (val / 60).toFixed(0) : 0
    const scnd = val > 60 ? val % 60 : val
    return `0${mins}: ${scnd.toString().length === 2 ? scnd : "0" + scnd}`
  }, [])

  const pushDurations = useCallback((dur) =>
    setDurationList((prevDurations) => [...prevDurations, dur])
  , [])

  let resultantTracks = []
  resultantTracks = resortedTracks.length > 19 ? resortedTracks.slice(paginationRange[0], paginationRange[1]) : resortedTracks

  return (
    <div>
      <div className={tableContainer}>
        <table className={table}>
          <thead>
            <tr>
              {heading.map((head, index) => (
                <th key={index}>{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {resultantTracks.map((row, index) => (
              <tr key={row.track_id}>
                <td>{row.track_id}</td>
                <td align="center">
                  <img src={row.image_url} alt="" className={img} />
                </td>
                <td align="center">
                  <span>{durationList[index]}</span>
                </td>

                <td align="center" style={{ width: 250 }}>

                  <WaveFramer

                    generateDuration={generateDuration}

                    song={row?.track_url}
                    setContainer={setContainer}
                    setCurrent={setCurrent}
                    container={container}
                    current={current}
                    setDuration={pushDurations}
                    id={row.track_id}
                    setLoad={setLoad}
                    listTrack={resultantTracks}
                    setWaveform={setWaveform}
                    size={size[0]}
                    ref={childRef}

                  />
                </td>
                <td align="left">
                  <Column>
                    <Typography variant="h6" className={`${title} truncate`}>
                      {row.track_name}
                    </Typography>
                    <small className={helperText}>{row.user_details?.full_name}</small>
                  </Column>
                </td>
                <td align="center">
                  <Typography className={typo}>{row.play}</Typography>
                </td>

                <td align="left">
                  <Typography className={typo}>{row.download}</Typography>
                </td>

                <td align="center">
                  <Row jc="center" width="100%">
                    {/* <Button className={btn}>View</Button> */}
                    <Button
                      className={row.download === 0 ? downloadStyle : btn}
                      onClick={(e) => handlePublish(row)}
                    >
                      {row.status === "pending" ? "Publish" : "Unpublish"}
                    </Button>
                    {/* <Button className={btn}>Edit</Button> */}
                  </Row>
                </td>
                <td align="center">
                  <Button
                    className={deleteBtn}
                    onClick={(e) => {
                      setCurrentVocal(row)
                      setSideBarActivation(!sideBarActivation)
                    }}
                  >
                    Edit
                  </Button>
                </td>
                <td align="center">
                  <Button
                    className={deleteBtn}
                    style={{ backgroundColor: "red" }}
                    onClick={(e) => handleDelete([row])}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        page={pageNo}
        setPageNo={setPageNo}
        total={resortedTracks?.length}
        paginationRange={paginationRange}
        setPaginationRange={setPaginationRange}
        auth={auth}
        handleSignUp={handleSignup}
      />
    </div>
  )
}

const heading = ["#", "Image", "Time", "Plays", "Name", "Download", "", "Action"]

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: "100%",
    maxWidth: "100%",
    overflow: "auto"
  },

  table: {
    width: "100%",
    overflow: "scroll",
    maxWidth: "100%",
    borderCollapse: "collapse",
    // "@media (max-width: 1030px)": {
    //   width: "70%",
    //   overflow: "hidden"
    // },
    // "@media (max-width: 750px)": {
    //   width: "70%"
    // },
    "& thead tr": {
      border: "1px solid white",
      scroll: "auto"
    },
    "& thead tr th": {
      minWidth: "50px",
      paddingBlock: "10px",
      scroll: "auto"
    },
    "& tbody tr td": {
      paddingTop: 5,
      paddingBottom: 5,
      scroll: "auto"
    },
    "& tbody tr:last-child td": {
      paddingTop: 5,
      paddingBottom: 5,
      border: 0
    }
  },

  deleteBtn: {
    backgroundColor: "#86DB78",
    color: "white",
    margin: "0px 3px",
    "&:hover": {
      color: "white"
    }
  },
  btn: {
    backgroundColor: "#86DB78",
    color: "white",
    margin: "0px 3px",
    textTransform: "none"
  },
  downloadStyle: {
    backgroundColor: "transparent",
    color: "white",
    margin: "0px 3px",
    border: "1px solid white"
  },

  helperText: {
    color: "#9F9F9F"
  },
  typo: {
    color: "white"
  },
  img: {
    width: 50
  },
  title: {
    fontSize: 17,
    width: 300
  }
}))
