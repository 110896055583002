import * as React from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import { Column } from "../Flex/Column"
import { StyledGrid } from "../Container/StyledGrid"
import { Row } from "../Flex/Row"
import fileDownload from "js-file-download"
import axios from "axios"

export const ThanksVocalRow = ({ tracks, showDownloadButton }) => {
  const {fontBold, desc, trackImg } = useStyles()

  const handleDownload = (url, track) => {
    axios
      .get(url, {
        responseType: "blob"
      })
      .then((res) => {
        fileDownload(res.data, track.track_name + ".wav")
      })
  }

  return (
    <>
      {tracks.map((cart, index) => (
        <StyledGrid container style={{ width: "100%", justifyContent: "center" }} key={cart.track_name}>
          <StyledGrid item xs={12} md={!!showDownloadButton ? 8 : 12} my={1}>
            <Row
              jc="space-between"
              p={1}
              borderRadius={5}
              style={{
                overflowWrap: "anywhere",
                backgroundColor: !showDownloadButton ? "#202020" : "transparent",
                border: showDownloadButton ? "1px solid white" : "none",
                alignItems: "center"
              }}
              mx={1}
            >
              <Column jc="space-between">
                <Box>
                  <Row style={{alignItems: "center"}}>
                    <img src={cart.image_url} alt="" className={trackImg} />
                    <Typography className={fontBold}>&nbsp;{cart.track_name}</Typography>
                  </Row>
                </Box>
              </Column>
            </Row>
          </StyledGrid>
          <StyledGrid
            item
            xs={12}
            mx={2}
            md={4}
            my={1}
            borderRadius={5}
            style={{
              display: !!showDownloadButton ? "" : "none",
              backgroundColor: "#FF00C7",
              color: "white",
              maxWidth: "200px",
              paddingTop: "10px"
            }}
          >
            <Row
              jc="space-around"
              p={1}
              px={2}
              style={{ overflowWrap: "anywhere", cursor: "pointer" }}
              mx={2}
              onClick={() => handleDownload(cart.track_url, cart)}
              //onClick={() => handleRemoveCart(cart.track_id)}
            >
              <Column>
                <img src={"media/dowload-vocal.png"} width="22" height="18" alt="downlaod vocal img" />
              </Column>

              <Column>
                <Typography className={desc} align="center">
                  Download
                </Typography>
              </Column>
            </Row>
          </StyledGrid>
        </StyledGrid>
      ))}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  btn: {
    marginLeft: 5,
    // backgroundColor: "#F3F4F5",
    border: `1px solid ${theme.palette.primary.main}`,

    height: 43,
    width: 260,
    borderRadius: 5,
    "&:hover": {
      //backgroundColor: theme.palette.primary.main,
      color: "#ffffff"
    }
  },
  helperText: {
    color: "#9F9F9F"
  },
  btnCell: {
    width: 260
  },
  row: {
    "& td": {
      textAlign: "left",
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
      // backgroundColor: "#ffffff",
      width: 50
    },
    "& td:last-child": {
      textAlign: "left",
      borderBottom: `0px solid ${theme.palette.secondary.main}`,
      backgroundColor: "transparent"
    }
  },
  title: {
    fontSize: 17
  },
  fontBold: {
    fontWeight: "bold",
    color: theme.palette.primary.contrastText,
    paddingLeft: '10px'
  },
  desc: {
    //fontWeight: "bold",
    color: theme.palette.primary.contrastText
  },
  trackImg: {
    width: '35px',
    borderRadius: '5px'
  }
}))
