import * as React from "react"
import { Box, makeStyles, Typography, Button } from "@material-ui/core"
import { useHistory } from "react-router"

export const TermsOfUse = () => {
  const { typo, btn, boxPadding } = useStyles()
  const history = useHistory()

  return (
    <>
      <Box className={boxPadding}>
        <Typography variant="h4" style={{ margin: "30px 0 10px 0" }}>
          Terms of Use
        </Typography>
        <Typography className={typo}>
          By visiting, logging on or using Warbls, you acknowledge that you have the right, authorization and capicity to enter into this Agreement,
          that you have read and understood this Agreement, and that you agree to the Terms of Use.
        </Typography>
      </Box>

      <Box className={boxPadding}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          1. Personal data
        </Typography>
        <Typography className={typo}>
          You agree to provide only the true, correct and complete information to us via the site or App. Any personal data will be treated by
          appropriate care and security.
        </Typography>
      </Box>

      <Box className={boxPadding}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          2. Vocal upload
        </Typography>
        <Typography className={typo}>
          By uploading any vocals and sounds, or any other content, you retain ownership and all rights to your uploads. You acknowlegde and agree
          that all vocalfiles uploaded to Warbls.com are 100% your own, and that you own the copyright to your work. You acknowledge and agree that
          all your uploads will be royalty-free for all users downloading your vocalfiles. Even if you remove your account you understand that your
          uploads can still be used by users and Warbls.com.
        </Typography>
      </Box>

      <Box className={boxPadding}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          3. Copyright{" "}
        </Typography>
        <Typography className={typo}>
          The vocals are licenced to custommers, not sold, to be used for your original music compositions and productions only. All copying, lending,
          duplicating, re- selling, sending, and trading of any vocal is stricly illegal and prohibited.
        </Typography>
      </Box>

      <Box className={boxPadding}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          4. Your use of the service
        </Typography>
        <Typography className={typo}>You agree that you will not;</Typography>
        <Typography className={typo}>
          <Box display="flex" style={{ columnGap: "20px" }}>
            <span>-</span>
            <span>
              Impersonate any other person, conduct yourself in an offensive or abusive manner, or use the service for any unlawful purposes.
            </span>
          </Box>
        </Typography>
        <Typography className={typo}>
          <Box display="flex" style={{ columnGap: "20px" }}>
            <span>-</span>
            <span>
              Upload, share or submit content as indecent, porngraphic, sexually explict, libelous, maliciously false, inaccurate, misleading,
              depicting violence, offensive, abusive, hateful, discriminatory or cause annoyance, incovienience or needless anxiety to any person.
            </span>
          </Box>
        </Typography>
        <Typography className={typo}>
          <Box display="flex" style={{ columnGap: "20px" }}>
            <span>-</span>
            <span>Contact the artists.</span>
          </Box>
        </Typography>
      </Box>

      <Box className={boxPadding}>
        <Typography variant="h6" style={{ marginBottom: "10px" }}>
          5. Contacting us
        </Typography>
        <Typography className={typo}>If you have any questions please contact us via email; support@warbls.com</Typography>
      </Box>

      <Box width="100%" textAlign="center">
        <Button variant="contained" color="primary" onClick={() => history.push("/")} className={btn} disableElevation>
          Go back
        </Button>
      </Box>
    </>
  )
}

// const List = ({ text }) => {
//   const { typo } = useStyles()
//   return (
//     <Typography component="li" className={typo}>
//       {text}
//     </Typography>
//   )
// }

const useStyles = makeStyles((theme) => ({
  typo: {
    color: theme.palette.primary.contrastText,
    "&.MuiTypography-body1": {
      color: theme.palette.primary.contrastText,
      fontSize: 17,
      fontWeight: 400,
      wordSpacing: 2,

      fontStyle: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: 14
      }
    }
  },
  listStyle: {
    listStyle: "disk"
  },
  btn: {
    width: 180,
    borderRadius: 10,
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
    marginBottom: 30,

    "&:hover": {
      backgroundColor: theme.palette.secondary.main
    },

    [theme.breakpoints.down("sm")]: {
      padding: "9px 0px",
      "&.MuiButton-label": {
        fontSize: 14
      }
    }
  },
  boxPadding: {
    padding: "12px 64px 32px 64px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 20px"
    }
  }
}))
