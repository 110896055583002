export const formScript = [
  // {
  //   name: "username",
  //   type: "text",
  //   placeholder: "",
  //   label: "Username"
  // },
  { name: "full_name", type: "text", placeholder: "", label: "Full name or artist name" },
  { name: "password", type: "text", placeholder: "", label: "Password" },
  { name: "bio", type: "textarea", placeholder: "Bio", label: "Bio" },
  // {
  //   name: "email",
  //   type: "text",
  //   placeholder: "",
  //   label: "Email"
  // },
  // {
  //   name: "user_id",
  //   type: "text",
  //   placeholder: "",
  //   label: "User ID",
  //   disabled: true
  // }
]

export const initValues = {
  username: "",
  full_name: "",
  email: "",
  password: "",
  user_id: "",
  bio: "",
  user_type: "",
  is_suspended: ""
}


export const tabInfo = [
  { 
    id:'tab1',
    tabName:'Personal Info',
    icon:'media/person.png',
    active_icon:'media/person.png',
    path:'/profile'
  },
  {
    id:'tab2',
    tabName:'My Vocals',
    icon:'media/myMusic.png',
    active_icon:'media/library.png',
    path:'/my-vocals',
  },
  {
    id:'tab3',
    tabName:'Refer Friends',
    icon:'media/friends.png',
    active_icon:'media/friendsActive.png',
    path:'/refer-friend',
  },
  {
    id:'tab4',
    tabName:'Affiliate',
    icon:'media/doller.png',
    active_icon:'media/dollerActive.png',
    path:'/affiliate',
  },
  
]


export const plan_card_info=[
  { id:'card1',
    coins:10,
    price:1.99,
  },
  {
    id:'card2',
    coins:30,
    price:3.99,
  },
  {
    id:'card3',
    coins:70,
    price:7.99,
  },
  {
    id:'card4',
    coins:100,
    price:9.99,
  },
]

 export const plans = [{
        "subscription_plan_id":1,
        "name":"Intro Plan",
        "description":null,
        "coins":50,
        "price":4.99,
        "duration":"720:00:00",
        "date_added":"2024-01-17T13:22:41.000Z"},
        {"subscription_plan_id":2,"name":"Standard Plan",
        "description":null,"coins":100,
        "price":8.99,
        "duration":"720:00:00",
        "date_added":"2024-01-17T13:22:42.000Z"},
        {"subscription_plan_id":3,
        "name":"Premium Plan",
        "description":null,"coins":300,
        "price":19.99,"duration":"720:00:00",
        "date_added":"2024-01-17T13:22:42.000Z"},
        {"subscription_plan_id":4,
        "name":"Intro Plan",
        "description":null,
        "coins":50,"price":49.9,
        "duration":"8760:00:00",
        "date_added":"2024-01-17T13:48:25.000Z"},
        {"subscription_plan_id":5,"name":"Standard Plan",
        "description":null,
        "coins":100,
        "price":89.9,"duration":"8760:00:00",
        "date_added":"2024-01-17T13:48:26.000Z"},
        {"subscription_plan_id":6,
        "name":"Premium Plan",
        "description":null,
        "coins":300,"price":199.9,
        "duration":"8760:00:00",
        "date_added":"2024-01-17T13:48:26.000Z"}]
