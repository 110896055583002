import * as React from "react"
import { Typography, Box, makeStyles } from "@material-ui/core"
import { StyledContainer } from "../../../_warbls/components/Container/StyledContainer"
import { Column } from "../../../_warbls/components/Flex/Column"
import { ThanksVocalRow } from "../../../_warbls/components/VocalRow/ThanksVocalRow"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { actions } from "../../../redux/track/actions"
export const Confirmation = (props) => {
  const { smallText, bigText, span, darkSpan, vocalRowContainer } = useStyles()
  const { cart } = useSelector((state) => state.track)
  const history = useHistory()
  const dispatch = useDispatch()
  React.useEffect(() => () => dispatch(actions.emptyCartRequest()), [dispatch])

  return (
    <>
      <StyledContainer my={5}>
        <Column ai="center">
          <Typography className={smallText}>Order Number: {Math.random().toString(36).substr(2, 9)}</Typography>
          <Typography className={bigText}>
            Go to your &nbsp;
            <span className={span}>
              <img src={"media/dowload-vocal.png"} width="22" height="18" alt="downlaod vocal img" style={{ margin: "0 .5rem 2px 0" }} />
            </span>
            &nbsp; page to access your vocal files or download them here.
          </Typography>
          <Box width="90%" style={{ backgroundColor: "white", height: "1px", margin: "40px auto 10px auto" }} />
          <Box py={4} className={vocalRowContainer}>
            <ThanksVocalRow tracks={cart} showDownloadButton={true} />
          </Box>
          <Typography variant="h5" className={bigText}>
            Browse more{" "}
            <span
              className={darkSpan}
              onClick={() => {
                history.push(`/vocals`)
              }}
            >
              Vocals
            </span>
          </Typography>
        </Column>
      </StyledContainer>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  smallText: {
    fontSize: 20,
    fontWeight: "normal",
    color: theme.palette.primary.contrastText,
    marginTop: 10
  },
  bigText: {
    fontSize: 30,
    fontWEight: "normal",
    color: theme.palette.primary.contrastText,
    marginTop: 10
  },
  span: {
    // fontSize: 35,
    color: theme.palette.primary.main,
    backgroundColor: "#212121",
    padding: "10px 5px 2px 12px",
    borderRadius: 5,
    cursor: "pointer"
  },
  darkSpan: {
    // fontSize: 35,
    color: theme.palette.primary.main,
    backgroundColor: "white",
    padding: "5px 5px 2px 6px",
    borderRadius: 5,
    cursor: "pointer"
  },

  vocalRowContainer: {
    width: "55%",
    "@media (max-width: 600px)": {
      width: "90%"
    }
  }
}))
