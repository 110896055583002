/** SIGNUP TYPES */
export const UPLOAD_TRACK_REQUEST = "UPLOAD_TRACK_REQUEST"
export const UPLOAD_TRACK_DEFAULT = "UPLOAD_TRACK_DEFAULT"
export const UPLOAD_TRACK_SUCCESS = "UPLOAD_TRACK_SUCCESS"
export const UPLOAD_TRACK_FAILED = "UPLOAD_TRACK_FAILED"

/** GET TRACKS TYPES */
export const ALL_TRACKS_REQUEST = "ALL_TRACKS_REQUEST"
export const ALL_TRACKS_SUCCESS = "ALL_TRACKS_SUCCESS"
export const ALL_TRACKS_FAILED = "ALL_TRACKS_FAILED"

/** UPDATE TRACK */
export const UPDATE_TRACKS_REQUEST = "UPDATE_TRACKS_REQUEST"
export const UPDATE_TRACKS_SUCCESS = "UPDATE_TRACKS_SUCCESS"
export const UPDATE_TRACKS_FAILED = "UPDATE_TRACKS_FAILED"

/** GET PENDING TRACKS TYPES */
export const ALL_PENDING_TRACKS_REQUEST = "ALL_PENDING_TRACKS_REQUEST"
export const ALL_PENDING_TRACKS_SUCCESS = "ALL_PENDING_TRACKS_SUCCESS"
export const ALL_PENDING_TRACKS_FAILED = "ALL_PENDING_TRACKS_FAILED"

/** GET LIKED TRACKS TYPES */
export const LIKED_TRACKS_REQUEST = "LIKED_TRACKS_REQUEST"
export const LIKED_TRACKS_SUCCESS = "LIKED_TRACKS_SUCCESS"
export const LIKED_TRACKS_FAILED = "LIKED_TRACKS_FAILED"

/** GET DOWNLOADED TRACKS TYPES */
export const DOWNLOADED_TRACKS_REQUEST = "DOWNLOADED_TRACKS_REQUEST"
export const DOWNLOADED_TRACKS_SUCCESS = "DOWNLOADED_TRACKS_SUCCESS"
export const DOWNLOADED_TRACKS_FAILED = "DOWNLOADED_TRACKS_FAILED"

/** INCREASE VOLUME */
export const INCREASE_VOLUME_REQUEST = "INCREASE_VOLUME_REQUEST"
export const INCREASE_VOLUME_SUCCESS = "INCREASE_VOLUME_SUCCESS"

export const DECREASE_VOLUME_REQUEST = "DECREASE_VOLUME_REQUEST"
export const DECREASE_VOLUME_SUCCESS = "DECREASE_VOLUME_SUCCESS"

export const HANDLE_VOLUME_REQUEST = "HANDLE_VOLUME_REQUEST"
export const HANDLE_VOLUME_SUCCESS = "HANDLE_VOLUME_SUCCESS"

/** STOP TRACK LAODERS */
export const STOP_TRACK_LOADER = "STOP_TRACK_LOADER"

/** TRACK WISE LIKE TYPES */
export const TRACK_WISE_LIKE_REQUEST = "TRACK_WISE_LIKE_REQUEST"
export const TRACK_WISE_LIKE_SUCCESS = "TRACK_WISE_LIKE_SUCCESS"
export const TRACK_WISE_LIKE_FAILED = "TRACK_WISE_LIKE_FAILED"

/** TRACK WISE UNLIKE TYPES */
export const TRACK_WISE_UNLIKE_REQUEST = "TRACK_WISE_UNLIKE_REQUEST"
export const TRACK_WISE_UNLIKE_SUCCESS = "TRACK_WISE_UNLIKE_SUCCESS"
export const TRACK_WISE_UNLIKE_FAILED = "TRACK_WISE_UNLIKE_FAILED"

/** TRACK WISE PLAY TYPES */
export const TRACK_WISE_PLAY_REQUEST = "TRACK_WISE_PLAY_REQUEST"
export const TRACK_WISE_PLAY_SUCCESS = "TRACK_WISE_PLAY_SUCCESS"
export const TRACK_WISE_PLAY_FAILED = "TRACK_WISE_PLAY_FAILED"

/** TRACK WISE DOWNLOAD TYPES */
export const TRACK_WISE_DOWNLOAD_REQUEST = "TRACK_WISE_DOWNLOAD_REQUEST"
export const TRACK_WISE_DOWNLOAD_SUCCESS = "TRACK_WISE_DOWNLOAD_SUCCESS"
export const TRACK_WISE_DOWNLOAD_FAILED = "TRACK_WISE_DOWNLOAD_FAILED"

/** ADD TO CART TYPES */
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST"
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS"
export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED"

/** REMOVE CART TYPES */
export const REMOVE_CART_REQUEST = "REMOVE_CART_REQUEST"
export const EMPTY_CART_REQUEST = "EMPTY_CART_REQUEST"
// export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS"
// export const ADD_TO_CART_FAILED = "ADD_TO_CART_FAILED"

/** GET PLAYING TRACK */
export const GET_PLAYING_VOCAL = "GET_PLAYING_VOCAL"
export const REMOVE_PLAYING_VOCAL = "REMOVE_PLAYING_VOCAL"
export const ACTIVE_TRACK = "ACTIVE_TRACK"

