import * as Types from "./types"

const initState = {
  userSubscription:[]
}

export const subscriptionReducer = (state = initState, action) => {
  switch (action.type) {
    case Types.USER_SUBSCRIPTION_PLAN:
      return {
        userSubscription: action.payload,
      }

    

    default:
      return state
  }
}
