export const affiliate_steps=[
    {
        image:'jointHands.png',
        heading:'Join as an Affiliate',
        detail:`It’s free to get started. Access marketing materials, tools, and more!
        `
    },
    {
        image:'promote.png',
        heading:'Promote Warbls',
        detail:`Share Warbls with your target audience. There's a match for every need.`
    },
    {
        image:'Earning.png',
        heading:'Start Earning',
        detail:`Earn right from the moment your traffic converts. 
        `
    },
]