export const visitFromSocial = [
  { label: "Facebook", total: "230", increase: true, percent: "5.8%" },
  { label: "Tik Tok", total: "201", increase: true, percent: "3.7%" },
  { label: "Instagram", total: "192", increase: true, percent: "3.4%" },
  { label: "Youtube", total: "113", increase: false, percent: "2.0%" },
  { label: "Twitter", total: "90", increase: true, percent: "1.9%" },
  { label: "Reddit", total: "55", increase: true, percent: "1.5%" }
]

export const visitFromSource = [
  { label: "Direct Source", total: "230", increase: true, percent: "5.8%" },
  { label: "Search", total: "201", increase: true, percent: "3.7%" },
  { label: "Social", total: "192", increase: true, percent: "3.4%" },
  { label: "Referral", total: "113", increase: false, percent: "2.0%" },
  { label: "Email", total: "90", increase: true, percent: "1.9%" },
  { label: "Other", total: "55", increase: true, percent: "1.5%" }
]

export const statsList = [
  {
    centerValue: "$2.37k",
    bTitle: "March 2021",
    bValue: "7.96k",
    uTitle: "Sales",
    uValue: "April 2021"
  },
  {
    centerValue: "131",
    bTitle: "March 2021",
    bValue: "521",
    uTitle: "Total Orders",
    uValue: "April 2021"
  },
  {
    centerValue: "2307",
    bTitle: "March 2021",
    bValue: "6703",
    uTitle: "Total Visitiors",
    uValue: "April 2021"
  }
]
