import React, { useState } from 'react'
import { ContentContainer } from '../../../_warbls/components/Container'
import ManageCards from './ManageCards'
import {Box,makeStyles,Typography} from "@material-ui/core"
import { toAbsoluteUrl } from "../../../_helpers/toAbsoluteUrl"
import Plans from '../Plan/Plans'
import { useSelector } from 'react-redux'
import { Popup } from '../../../_warbls/components/Popup'
import { DialoguePopup } from './Dialoagus/DialoguePopup'
import MoreCoins from './MoreCoins'
import UpgradePlan from './UpgradePlan'
import CancelPlan from './CancelPlan'



const ManageSubscription = (props) => {
  const { root } = useStyles()
  const {userSubscription} = useSelector( state => state.subscriptions)
  const activeID = userSubscription?.subscription_plan_id || 0
  const plan_duration= (userSubscription?.subscription_plan_id > 0 && userSubscription?.subscription_plan_id < 10 )? "Month":"Year";
  const [openDialogue, setOpenDialogue] = useState(false)
  const [upgradePlan, setUpgradePlan] = useState(false)
  const [cancelPlanDialoague, setCancelPlanDialoague] = useState(false)
  

  const renewalDate = userSubscription?.renewal_date || "No date"

  const handleDialogue = () => { setOpenDialogue(!openDialogue) }
  const handleUpgradePlan = () => { setUpgradePlan(!upgradePlan) }
  const handleCancelPlan = () => { setCancelPlanDialoague(!cancelPlanDialoague)}
 

  return (
    <ContentContainer
            titleBackground={"linear-gradient(91.97deg, #212121 9.8%, #57A4FF 133.46%)"}
            title={"Manage Subscription"}
            >
            <div className={root}>

                <div>
                    <div style={{display:'flex', gap:'30px',marginTop:'20px',justifyContent:'flex-start',flexWrap:"wrap"}}>
                
                <ManageCards
                    heading="Your Pro Plan"
                    coins={userSubscription?.total_coins || 0}
                    duration="month"
                    btnContant='Buy More Coins'
                    btnOnClick={handleDialogue}

                    />
              <div>

                <ManageCards
                    heading="Billing & Payment"
                    date={renewalDate.slice(0, 10)}
                    duration={plan_duration}
                    btnContant='Edit Billing Details'
                    btnOnClick={handleUpgradePlan}
                    />
                <div style={{textAlign:'center',margin:'10px 0'}}>
                        <Typography  variant='p' onClick={handleCancelPlan} style={{cursor:'pointer'}}>Cancel Plan</Typography>
                </div>
              </div>
                <ManageCards
                    heading="Support"
                    content='Need help? For support, issues with coins or billing problems, email us at:'
                    btnContant='support@warbls.com'
                    btnOnClick={() => {  }}
                    />

                    </div>
                    
                </div>

                <div>
                    <Typography variant='h6' style={{fontWeight:'bolder'}}>All Plans</Typography>
                    <Plans
                        activePlanId={activeID}
                    />
                </div>
            </div>
            <DialoguePopup open={openDialogue} handleClose={handleDialogue}>
              <MoreCoins
                handleClose={handleDialogue}
              />

            </DialoguePopup>
            <DialoguePopup open={upgradePlan} handleClose={handleUpgradePlan}>
              <UpgradePlan
                handleClose={handleUpgradePlan}
                date={renewalDate.slice(0, 10)}

              />

            </DialoguePopup>
            <DialoguePopup open={cancelPlanDialoague} handleClose={handleCancelPlan}>
              <CancelPlan
                handleClose={handleCancelPlan}
              />

            </DialoguePopup>
            
          

    </ContentContainer>
  )
}


const useStyles = makeStyles((theme) => ({
  root:{

    marginLeft:'80px',
    

  }
}))

export default ManageSubscription;