import React from 'react'
import { Typography } from '@material-ui/core'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';


const CoinSucceed = (props) => {
    const {handleClose} = props
  return (
                <div style={{backgroundColor:'#333333',borderRadius:'5px',padding:"30px 0"}}>
                    <div style={{display:'flex',cursor:'pointer',justifyContent:'flex-end'}}>
                      <CloseRoundedIcon style={{position:'absolute',top:'5px',right:'5px'}} onClick={handleClose}/>
                    </div>
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center',gap:'5px'}}>
                    <Typography veriant='p' style={{fontWeight:'bolder',color:'#fff'}}>
                      Enjoy your coins
                    </Typography>
                    
                      <img src={`${process.env.PUBLIC_URL}/images/coin-stack.png`} style={{width:'25px', height:'25px'}} />
                   
                  </div>
                </div>
        
  )
}

export default CoinSucceed