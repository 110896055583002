import React from 'react'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux'
import { POST } from "../../../_helpers/fetcher"
import { APIs } from "../../../_helpers/apis"





const CancelPlan = (props) => {
    const { userInfo } = useSelector((state) => state.auth)
    const {handleClose} = props
    const cancelSubscription = async () => {
      
      let res = await POST(APIs.stripeCancelSubscription, {userId: userInfo?.user_id})
      if (res?.status === 200) {
        window.location.href = '/plans';
      }
    }
  return (
    <div style={{backgroundColor:'#202B38',borderRadius:'5px',padding:'20px',display:'flex',flexDirection:'column',gap:'10px'}}>
        <div>
        <CloseRoundedIcon style={{position:'absolute',top:'10px',right:'10px',cursor:'pointer'}} onClick={handleClose}/>
      </div>
      <div >
          <Typography style={{fontWeight:'bolder'}} variant='h5'>Cancel your Subscription</Typography>
      </div>
      <div>
              <Typography style={{color:'#fff'}} variant='p'>Your subscription will be canceled at the end of the current billing period.</Typography>
          </div>
           <div>
              <Typography style={{color:'#929292'}} variant='p'>NB: All your remaining coins will be lost.</Typography>
        </div>
        <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',gap:'20px'}}>
        <div onClick={handleClose}
           style={{display:'flex',cursor:'pointer'}}>
          <Typography variant='p'>Back</Typography>
          
        </div>
        <div onClick={() => { 
         
        }}
           style={{cursor:'pointer',backgroundColor:'#FF5757',borderRadius:'5px',padding:'10px 20px'}}>

          <Typography variant='p' style={{fontWeight:'bolder'}} onClick={() => {cancelSubscription()}}>Confirm Cancellation</Typography>
        </div>

      </div>
    
    </div>
  )
}

export default CancelPlan