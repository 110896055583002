
import React, { useRef, useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TelegramIcon from '@material-ui/icons/Telegram';

const ReferalForm = () => {
    const { userInfo } = useSelector((state) => state.auth);

    const emailRef = useRef(null)
    const [copy, setcopy] = useState(false)
    

    const handelSend= () => {  }

  return (
    <Box className='referal-form-container'>
        <Box className='referal-form-label'>
            <Typography variant='p'>
                Share your personal referral link
            </Typography>
        </Box>
            <Box className='referal-link'>
                <Typography style={{color: copy ? '#000':''}} variant='h6'>
                    https://www.warbls.com/refferrals/{userInfo.user_id}
                </Typography>
                <button className='link-btn' 
                    onClick={() => { 
                        navigator.clipboard.writeText(`https://www.warbls.com/refferrals/${userInfo.user_id}`)
                        setcopy(true)
                        
                    }}
                ><FileCopyIcon style={{margin:'0 5px', color:'white'}}/>Copy</button>
            </Box>
        <Box className='referal-form-label'>
        <Typography variant='p'>
                Share your personal referral link
            </Typography>
        </Box>
        <Box className='refer-email'>
            <input 
                type="email" 
                ref={emailRef}
                placeholder='email@email.com'

                />
            <button 
                className='share-btn'
                onClick={handelSend}
                >
                    <TelegramIcon style={{color:'#fff'}}/>
                    Send
            </button>
        </Box>
        <button></button>
    </Box>
  )
}

export default ReferalForm