import * as React from "react"
import { Container, makeStyles, styled } from "@material-ui/core"
import { compose, spacing, flexbox, palette, borders, sizing } from "@material-ui/system"
import clsx from "clsx"
const CustomContainer = styled(Container)(compose(spacing, palette, flexbox, borders, sizing))

export const ContentContainer = ({ children, title, titleLeft, titleBackground, gradient, profile, pageType, adminGradient, thankYou }) => {
  const { titleContainer, container, smallGradient, profileTitleContainer, sidebar_controls, adminHeader, thankYouText, bigFont } =
    useStyles()

  const getHeadingMargin = () => {
    if (!!titleLeft) {
      return "2%"
    } else return "10%"
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div
        className={clsx(!profile && titleContainer, profile && profileTitleContainer, adminGradient && adminHeader)}
        style={{
          height: gradient ? "180px" : "110px",
          background: !!titleBackground ? titleBackground : "linear-gradient(#06a4ea, rgb(255 255 255 / 0%))"
        }}
      >
        <div className={sidebar_controls}>
          <h1
            className={clsx(profile && smallGradient, pageType && bigFont, thankYou && thankYouText)}
            style={{
              display: "block",
              fontSize:"50px",
              bottom: gradient ? "65%" : "10%",
              left: getHeadingMargin()
              // padding: "0 50px",
            }}
          >
            {title}
          </h1>
        </div>
      </div>
      <CustomContainer className={container} style={{ margin: gradient ? "-90px 0 0 0" : "200 0 0 0" }}>
        {children}
      </CustomContainer>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  titleContainer: {
    width: "100%",
    background: "#8A31B4",
    position: "relative",

    "& > h1": {
      display: "block",
      position: "absolute",
      margin: "0",
      fontSize: "xxx-large",

      [theme.breakpoints.down("md")]: {
        bottom: "80%",
        fontSize: "40px"
      },
      [theme.breakpoints.down("sm")]: {
        bottom: "84% !important",
        fontSize: "20px"
      }
      // transform:"translate(0,-50%)"
    }
  },
  sidebar_controls: {
    display: "flex",
    columnGap: "10px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "start",
    margin: "20px 20px 50px 80px",
    // marginLeft: "15%",

    "@media (max-width: 1000px)" :{
      margin: '30px 20px 0px 20px',
    }
  },
  control_image: {
    cursor: "pointer",
    backgroundColor: "rgb(102,102,102)",
    padding: "5px"
  },

  adminHeader: {
    "& > h1": {
      [theme.breakpoints.down("sm")]: {
        bottom: "25% !important",
        fontSize: "25px"
      }
    }
  },
  bigFont: {
    fontSize: "70px",
    margin: "-10px 0 0 0px",
    "@media(max-width: 1100px)": {
      fontSize: "45px",
      // width: "100%",
      margin: "10px 0 0px 0",
      // minWidth: "190px",
      textAlign: "left"
    }
  },
  thankYouText: {
    width: "80%",
    textAlign: "center",
    "@media(max-width: 1100px)": {
      fontSize: "45px",
      width: "100%",
      margin: "10px 0 60px 0",
      // minWidth: "190px",
      textAlign: "left"
    }
  },
  profileTitleContainer: {
    width: "100%",
    background: "#8A31B4",
    height: "100px",

    [theme.breakpoints.down("sm")]: {
      height: "50px !important",
      marginBottom: "0 ",
      background: "none !important"
    }
  },
  container: {
    display: "block",
    border: `1px solid transparent`,
    padding: "0",

    [theme.breakpoints.down("md")]: {
      marginTop: "0",
      // marginTop: "-230px",

      marginBottom: 10
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px !important",
      marginBottom: 10
    },

    "@media (max-width : 1300px)": {
      marginTop: "0px !important"
    }
  },
  smallGradient: {
    paddingLeft: "30px",
    fontSize: "40px",
    bottom: "10%",
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      bottom: "30% !important"
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "0% !important"
    }

    // transform:"translate(0,-50%)"
  }
}))
