import * as Yup from "yup"

export const schema = Yup.object().shape({
  email: Yup.string().email("must be a valid string").required("email is required"),
  password: Yup.string()
    .when('social_login', {
      is: false,
      then: Yup.string().required("password is required").min(6, "password must be greater than 7 characters"),
      otherwise: Yup.string().notRequired()
    }),
  social_login: Yup.boolean()
    .notRequired(),
  social_id: Yup.string()
  .when('social_login', {
    is: true,
    then: Yup.string().required("social ID is required"),
    otherwise: Yup.string().notRequired()
  }),
  username: Yup.string()
    .notRequired()
    .min(4, "user name must be greater than 4 characters"),
  profile_img: Yup.string()
  .notRequired(),
  full_name: Yup.string().required("password is required")
})
